import icon1 from './arrow_back.svg';
import icon2 from './arrow_forward.svg';

const Autodesk = window.Autodesk;

function AdvanceSheetsExtension(viewer, options) {
    Autodesk.Viewing.Extension.call(this, viewer, options);
}

AdvanceSheetsExtension.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
AdvanceSheetsExtension.prototype.constructor = AdvanceSheetsExtension;

AdvanceSheetsExtension.prototype.load = function () {
    return true;
};

AdvanceSheetsExtension.prototype.unload = function () {
    if (this.subToolbar) {
        this.viewer.toolbar.removeControl(this.subToolbar);
        this.subToolbar = null;
    }
};

AdvanceSheetsExtension.prototype.onToolbarCreated = function (toolbar) {
    var viewer = this.viewer;

    // Left
    var button1 = new Autodesk.Viewing.UI.Button('AdvanceSheetsExtension-left-button');
    button1.onMouseOver = (e) => {
        button1.icon.style.backgroundColor = '#00bfff';
    }

    button1.onMouseOut = (e) => {
        button1.icon.style.backgroundColor = '#ffffff';
    }

    button1.onClick = function (e) {
        document.dispatchEvent(new CustomEvent('advanceSheets', { detail: { n: -1 } }));
    };
    button1.addClass('AdvanceSheetsExtension-left-button');
    button1.setToolTip('Previous Sheet');

    // https://icons8.com/icons/set/arrow-transparent--white
    button1.icon.style.backgroundColor = '#ffffff';
    button1.icon.style.maskImage = `url('${icon1}')`;
    button1.icon.style.webkitMaskImage = `url('${icon1}')`;
    button1.icon.style.webkitMaskRepeat = 'no-repeat';
    button1.icon.style.webkitMaskPosition = 'center';

    // Right
    var button2 = new Autodesk.Viewing.UI.Button('AdvanceSheetsExtension-right-button');
    button2.onClick = function (e) {
        document.dispatchEvent(new CustomEvent('advanceSheets', { detail: { n: 1 } }));
    };
    button2.onMouseOver = (e) => {
        button2.icon.style.backgroundColor = '#00bfff';
    }

    button2.onMouseOut = (e) => {
        button2.icon.style.backgroundColor = '#ffffff';
    }

    button2.addClass('AdvanceSheetsExtension-right-button');
    button2.setToolTip('Next Sheet');
    // https://icons8.com/icons/set/arrow-transparent--white
    button2.icon.style.backgroundColor = '#ffffff';
    button2.icon.style.maskImage = `url('${icon2}')`;
    button2.icon.style.webkitMaskImage = `url('${icon2}')`;
    button2.icon.style.webkitMaskRepeat = 'no-repeat';
    button2.icon.style.webkitMaskPosition = 'center';

    // SubToolbar
    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('AdvanceSheetsExtension-subToolbar');
    this.subToolbar.addControl(button1);
    this.subToolbar.addControl(button2);

    toolbar.addControl(this.subToolbar);
};

Autodesk.Viewing.theExtensionManager.registerExtension('AdvanceSheetsExtension', AdvanceSheetsExtension);
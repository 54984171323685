// react
import React, { Component } from 'react';
// sub components
import Spinner from 'components/Spinner/Spinner';
import InfoPanelElevation from './InfoPanelElevation';
import DesignOptions from './DesignOptions';
import { loadModels3D } from '../../Viewer/ViewerHelpers';
// external libraries
import { Grid } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
// styling
import './InfoPanel.scss';


class InfoPanel extends Component {
    constructor(props) {
        super(props);
        /* ---------------------------------------------------------------------
        props & states
        --------------------------------------------------------------------- */
        this.state = { checked: [] };
        this.handleSelect = this.handleSelect.bind(this);
    }

    /* -------------------------------------------------------------------------
    lifecycle methods
    ------------------------------------------------------------------------- */    
    componentDidUpdate(prevProps) {

        if (this.props.viewables3D !== prevProps.viewables3D) {
            if (this.props.type === 'PRE') {
                // If no view with /M/ in name, there are no design options
                let designOptions = false;

                for (const view of this.props.viewables3D) {
                    if (view.data.name.includes('/M/')) {
                        designOptions = true;
                        break;
                    }
                }
                this.props.setHasDesignOptions(designOptions);
            }
        }
    }

    handleSelect(viewable) {
        loadModels3D(this.props, [viewable]);
    }

    render() {
        var className = 'info-panel' + (this.props.show ? '' : ' invisible');
        var elevations = <Spinner />;
        if (this.props.hasDesignOptions === true) {
            elevations = <DesignOptions {...this.props} />;
        }
        if (this.props.hasDesignOptions === false) {
            elevations = <InfoPanelElevation 
                handleSelect={this.handleSelect} 
                viewables3D={this.props.viewables3D}
                currViewSet={this.props.currElevation}
                setViewSets={this.props.setElevations}
                setCurrViewSet={this.props.setCurrElevation}
                />;
        }

        return (
            <div className={className}>
                <h2 className="panel-title">Information</h2>
                <Grid item={true} container xs={12} spacing={1}>
                    <Grid item container xs={12} key={uuidv4()}>
                        <Grid item xs={3}>Project</Grid>
                        <Grid item xs={9}>{this.props.data.projectDisplayName}</Grid>
                    </Grid>
                    <Grid item container xs={12} key={uuidv4()}>
                        <Grid item xs={3}>Phase</Grid>
                        <Grid item xs={9}>{this.props.data.phase}</Grid>
                    </Grid>
                    <Grid item container xs={12} key={uuidv4()}>
                        <Grid item xs={3}>Model</Grid>
                        <Grid item xs={9}>{this.props.data.modelDisplayName}</Grid>
                    </Grid>
                    {this.props.type === "CON" ? 
                        (
                        <Grid item container xs={12} key={uuidv4()}>
                            <Grid item xs={3}>Lot</Grid>
                            <Grid item xs={9}>{this.props.data.lot}</Grid>
                            <Grid item xs={3}>Elevation</Grid>
                            <Grid item xs={9}>{this.props.data.elevation ? this.props.data.elevation : 'Unknown'}</Grid>
                        </Grid>
                        ) : (
                        <Grid item container xs={12} key={uuidv4()}>
                            <Grid item xs={3}>Name</Grid>
                            <Grid item xs={9}>{this.props.data.name}</Grid>
                        </Grid>
                        )
                    }
                </Grid>
                {/* elevation only available for Design, not Construction */}
                {this.props.type === "CON" ? null : elevations}
            </div>
        );
    }
}

export default InfoPanel;
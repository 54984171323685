// react
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// internal libraries
import { updateAuth } from 'store/slices/cacheSlice';
import handleLogout from 'utils/handleLogout';
// sub components
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
// external libraries
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// styling
import 'components/Navbar/Navbar.scss';
import dashicon from 'assets/icons/dashboard.png';

const useStyles = makeStyles(() => ({
    divider: {
        flexGrow: 1
    }
}));

/* -----------------------------------------------------------------------------
app logic
----------------------------------------------------------------------------- */
const Navbar = ({ crumbs, dashboardLink }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    return (
        <AppBar color='inherit' position='static' className={classes.navBar}>
            <Toolbar>
                <BreadCrumb crumbs={crumbs}/>
                <div className={classes.divider}></div>
                <div className='right-side'>
                    {dashboardLink ? 
                        <Link to={dashboardLink}>
                            <img 
                                className='right-side__icon'
                                src={dashicon} 
                                alt='dashboard' 
                            />
                        </Link>
                        : ''
                    }
                    <IconButton
                        edge='end'
                        color='default'
                        aria-label='menu'
                        onClick={() => {
                            dispatch(updateAuth(false));
                            handleLogout();
                            // history.push('/');
                        }}
                    >
                        <ExitToAppIcon />
                    </IconButton>
                </div>
                
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;

// react
import React from 'react';
// sub components
import BrandButton from 'components/common/BrandButton/BrandButton';
// styling
import 'components/errorHandlers/MasterHandler/MasterHandler.scss';
import broken from 'assets/images/broken.svg';
// import previous from 'assets/icons/back.png';

export default function MasterHandler({
    error, resetErrorBoundary, currElevation, modelKey}) {
    return (
        <div className='error-handler'>
            {/* <pre>{error.message}</pre> */}
            <img src={broken} alt='Could not load a view' className='error-handler__img' />
                <p className='error-handler__text'>We encountered an error while reading viewset <span className='error-handler__text--bold'>{currElevation}</span> of model <span className='error-handler__text--bold'>{modelKey}</span></p>
                <p className='error-handler__text'>Please report to the development team at bimviewer@bimstudio.ca</p>
            {/* <button onClick={resetErrorBoundary}>Try again</button> */}
            <div className='error-handler__button-container'>
                <BrandButton
                    text='< GO BACK'
                    // iconBefore={previous}
                    actionFunction={() => {
                        window.location.href = '/view'
                        // return <Redirect to={'/view'} />;
                    }}
                />
            </div>
        </div>
    )
}

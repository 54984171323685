import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Sidebar.scss';
import houseIcon from 'assets/icons/home.svg';
import { v4 as uuidv4 } from 'uuid';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    onSelection(iconT) {
        let panelOpen = (this.props.activeIcon.iconName === iconT.iconName ? !this.props.panelOpen : true);
        this.props.handleSelection(iconT, panelOpen)
    }

    determineColor(iconPurpose) {
        try {
            let activeIconPurpose = this.props.activeIcon.iconPurpose;
            let panelOpen = this.props.panelOpen;
            if (activeIconPurpose === iconPurpose) {
                if (panelOpen) {
                    return "#ed6724";
                } else {
                    return null;
                }
            } else {
                return null;
            }

        } catch (err) {
            return null;
        }
    }

    generateIconList() {
        let icons = this.props.icons;
        let iconList = icons.map(icon => {
            return <Fragment key={uuidv4()}>
                <div 
                    key={uuidv4()} 
                    className="sidebar-icon fas-icon-wrapper noselect" 
                    onClick={() => this.onSelection(icon)} 
                    style={{ background: this.determineColor(icon.iconPurpose) }}>
                    <FontAwesomeIcon icon={icon.iconName} title={icon.iconTitle}></FontAwesomeIcon>
                </div>
            </Fragment>
        });
        return iconList;
    }

    render() {
        return (
            <div className="sidebar">
                <Link className='sidebar__link' to={`/view`}>
                    <img className='sidebar__home' src={houseIcon} alt='Home' />
                </Link>
                <div className='sidebar__icons'>
                    {this.generateIconList()}
                </div>
            </div>
        )
    }
}

export default Sidebar
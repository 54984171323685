// react
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
// sub components
import HomePage from 'pages/HomePage/HomePage';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import Screen403 from 'components/ResponseHandling/Screen403';
import isLoggedIn from 'utils/isLoggedIn';
import SandBox from 'pages/SandBox/SandBox';
import LoggedOutPage from 'pages/LoggedOutPage/LoggedOutPage';
// external libraries
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { SnackbarProvider } from 'notistack';
// styling
import 'styles/common.scss';

/* -----------------------------------------------------------------------------
styling
----------------------------------------------------------------------------- */
library.add(fas);

const darkTheme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#ed6724',
            contrastText: common.white
        },
        secondary: {
            main: '#545454',
            contrastText: common.white
        },
        background: {
            default: '#2f2e2e'
        },
    },
});

/* -----------------------------------------------------------------------------
app logic
----------------------------------------------------------------------------- */

const App = () => {

    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }
    const [loggedIn, setLoggedIn] = useState(null);


    useEffect(() => {

        isLoggedIn().then(result => {
            setLoggedIn(result);
            if (result === false) {
                const { href } = window.location;
                /* -------------------------------------------------------------
                if user not logged in and pastes in custom link, we need to 
                temporarily store that link, redirect them to login, and 
                afterward bring them to the link they pasted prior to login
                ------------------------------------------------------------- */
                // href is http(s)://domain/...
                const arr = href.split('/');
                // we are interested in what comes after domain
                const url = '/' + arr.slice(3, arr.length).join('/');
                
                console.log(`user requested link is ${url}`);
                
                // only auto-direct to autodesk login page if user didn't 
                // request homepage. homepage should be visible without login
                const noLoginRequired = ['/', '/403', '/loggedout', '/sandbox'];
                if (!noLoginRequired.includes(url)) {
                    
                    // window.location.href = '/';

                    // always redirect to /view for now because if we redirect 
                    // user to their desired page after autodesk, when they 
                    // click 'back', it will go to the expired autodesk page
                    
                    window.localStorage.setItem('bimhubRedirectUrl', '/view');
                    console.log(`not logged in, will redir to '/view' after login`);
                    
                    // redirect to Autodesk for login
                    
                    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, {
                    })
                    .then((res) => res.text())
                    .then((loginURL) => {
                        console.log(`constructed URL is ${loginURL}`);
                        window.location.href = loginURL;
                    });
                }
            }
        })
    }, [])

    return (
        <ThemeProvider theme={darkTheme}>
            <SnackbarProvider
                ref={notistackRef}
                action={(key) => (
                    <Button style={{ color: 'white' }} onClick={onClickDismiss(key)}>
                        Dismiss
                    </Button>
                )}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                maxSnack={10}
                preventDuplicate>
                
                <Switch>
                    {/* 403 (note: not currently used) */}
                    <Route path='/403'>
                        <Screen403 />
                    </Route>
                    
                    {/* logged out page */}
                    <Route path='/loggedout'>
                        <LoggedOutPage />
                    </Route>

                    {/* selection: selecting Design or Construction. this is the only 
                    route that has to go through additional checks via PrivateRoute 
                    before loading */}
                    <Route path='/view'>
                        <PrivateRoute loggedIn={loggedIn} redirUrl='/' />
                    </Route>                    

                    {/* test page */}
                    <Route path='/sandbox'>
                        <SandBox />
                    </Route>

                    {/* home page: leave this at the end for others to 
                    get parsed first */}
                    <Route path='/'>
                        <HomePage loggedIn={loggedIn ? true : false} />
                    </Route>
                    
                </Switch>

            </SnackbarProvider>
        </ThemeProvider>)
};

export default App;

import CanvasDraw from "react-canvas-draw";
import { Button, Grid } from "@material-ui/core";
import mergeImages from 'merge-images-v2';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Component } from "react";
// external libraries
import { v4 as uuidv4 } from 'uuid';
// styling
import './Markup.css';

class Markup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            canvasOverlayToolInstances: [],
            canvasOverlays: [],
            canvasRefs: {},
            brushRadius: {}, // Provisioned, not implemented yet.
            brushColour: "#ff0000"
        };
    }

    changeBrushRadius(viewerId, radius) {
        //TODO: implement this by setting correct state.
    }

    downloadMarkupData(viewerId) {
        let ref = this.state.canvasRefs[viewerId]
        try {
            let canvas = ref.canvas;

            let currentViewer = () => {
                switch (viewerId) {
                    case "viewer2D":
                        return this.props.viewer[viewerId];
                    case "viewer3D":
                        return this.props.viewer[viewerId].viewer;
                    default:
                        throw new Error("Unknown viewerId encountered while attempting to download markup data.");
                }
            }

            let viewer = currentViewer();
            let viewerScreenshot = new Image();
            let markup = new Image();
            let metadataOverlay = new Image();

            let metadataCanvas = document.createElement('canvas');
            metadataCanvas.width = viewer.container.clientWidth;
            metadataCanvas.height = viewer.container.clientHeight;
            let metadata_ctx = metadataCanvas.getContext('2d');
            metadata_ctx.font = '20px Rubik';
            metadata_ctx.fillText(`PROJECT : ${this.props.data.project || "UNKNOWN"}`, 20, 60)
            metadata_ctx.fillText(`PHASE : ${this.props.data.phase || "UNKNOWN"}`, 20, 80)
            if (this.props.data.lot !== "") {
                metadata_ctx.fillText(`LOT : ${this.props.data.lot || "UNKNOWN"}`, 20, 100)
            } else {
                metadata_ctx.fillText(`ELEVATION : ${this.props.currElevation ? this.props.currElevation.substr(1, 2) : "UNKNOWN"}`, 20, 100)
                metadata_ctx.fillText(`NAME : ${this.props.data.name || "UNKNOWN"}`, 20, 140)
            }
            metadata_ctx.fillText(`MODEL : ${this.props.data.model || "UNKNOWN"}`, 20, 120)



            viewerScreenshot.onload = () => {
                markup.onload = () => {
                    metadataOverlay.onload = () => {
                        mergeImages([{
                            src: viewerScreenshot.src
                        }, {
                            src: markup.src
                        }, {
                            src: metadataOverlay.src
                        }, {
                            src: '/BIMstudio.png',
                            x: viewer.container.clientWidth - 160,
                            y: 10,
                            opacity: 0.9
                        }]).then(base64data => {
                            let a = document.createElement('a');
                            a.download = "bimhubmarkup.png";
                            a.href = base64data.replace("image/png", "image/octet-stream");
                            a.click();
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                    metadataOverlay.src = metadataCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
                }
                markup.src = canvas.drawing.toDataURL("image/png").replace("image/png", "image/octet-stream");
            };

            viewer.getScreenShot(viewer.container.clientWidth, viewer.container.clientHeight, function (blobURL) {
                viewerScreenshot.src = blobURL;
            });
        } catch (err) {
            console.log(err)
        }

    }

    createColourPickerOption(hexcode) {
        return <Grid item xs={3} justifyContent="center">
            <FormControlLabel value={hexcode} control={<Radio color="primary" style={{ background: hexcode }} />} labelPlacement="bottom" />
        </Grid>
    }

    exitMarkups() {
        this.props.onExit();
        this.props.collapsePanel();
    }

    canvasOverlayToolsInstance(viewerId) {
        let viewerRect = document.getElementById(viewerId).getBoundingClientRect();
        try {
            return <div className="markup-tools" style={{ left: viewerRect.left - 125 + viewerRect.width / 2 }}>
                < Grid key={uuidv4()} container spacing={1} alignContent="center" direction="row" >
                    <Grid key={uuidv4()} item xs={6}>
                        <Button key={uuidv4()} variant="contained" color="secondary" onClick={() => this.exitMarkups()} fullWidth>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button key={uuidv4()} variant="contained" color="primary" fullWidth onClick={() => this.downloadMarkupData(viewerId)}>
                            Save
                        </Button>
                    </Grid>
                </Grid >
            </div >
        } catch (err) {
            console.log(err)
            return <div></div>
        }
    }

    createOverlays() {
        //create the overlays
        let _canvasOverlays = []
        let _canvasOverlayToolsInstances = []
        // apply logic for each viewer (3D and PDF)
        for (let viewerWrapper of document.getElementsByClassName("viewer-wrapper")) {
            let viewerRect = viewerWrapper.getBoundingClientRect();
            // let viewerId = viewerWrapper.children[0].children[0].id;
            let viewerId = 'viewer3D';

            // skip the PDF viewer, because it only works with Forge's native
            // 2D sheet viewer
            if (viewerId === 'viewerPDF') continue;

            let canvasOverlay = <CanvasDraw 
                key={uuidv4()}
                ref={
                    canvasDraw => {
                    let newCanvasRefs = this.state.canvasRefs;
                    newCanvasRefs[viewerId] = canvasDraw;
                    this.setState({
                        canvasRefs: newCanvasRefs
                    });
                }}
                className="canvas-overlay"
                canvasWidth={viewerRect.width}
                canvasHeight={viewerRect.height}
                brushRadius={2} //TODO: let the user edit this
                hideGrid={true}
                brushColor={this.state.brushColour} //todo: fix this
                lazyRadius={5}
                style={{
                    left: viewerRect.left,
                    top: viewerRect.top,
                    background: "none"
                }}
            />
            _canvasOverlays.push(canvasOverlay)
            _canvasOverlayToolsInstances.push(this.canvasOverlayToolsInstance(viewerId))
        }

        this.setState({
            canvasOverlays: _canvasOverlays,
            canvasOverlayToolInstances: _canvasOverlayToolsInstances
        });
    }

    componentDidMount() {
        this.createOverlays();
    }

    render() {
        return (
            <div id="markups-wrapper">
                {this.state.canvasOverlays}
                {this.state.canvasOverlayToolInstances}
            </div>
        )
    }
}

export default Markup;
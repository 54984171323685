// react
import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
// external libraries
import { v4 as uuidv4 } from 'uuid';
// styling
import 'components/BreadCrumb/BreadCrumb.scss';
import chevron from 'assets/icons/chevron.svg';


export default function BreadCrumb({ crumbs }) {

    const genCrumbElement = (crumb) => {
        if (crumb['link']) {
            return (
                <div key={uuidv4()} className='breadcrumb__item'>
                    <Link className='breadcrumb__link' to={crumb['link']}>
                        {crumb['text'].toUpperCase()}
                    </Link>
                </div>
            )
        } else {
            return (
                <div key={uuidv4()} className='breadcrumb__item breadcrumb__item--no-link'>
                    {crumb['text'].toUpperCase()}
                </div>
            )
        }
    }

    /* -------------------------------------------------------------------------
    rendering
    see map() last one logic: https://stackoverflow.com/a/46514721/1593017
    ------------------------------------------------------------------------- */
    return (
        <div className='breadcrumb'>
            <a href='https://bimstudio.ca'>
                <img className='logo' src='/BIMstudio.png' alt='BIM studio logo' /> 
            </a>
            {crumbs.map((crumb, i, { length }) => {
                // last one
                if (length - 1 === i) {
                    return (
                        <Fragment key={uuidv4()}>
                            {genCrumbElement(crumb)}
                        </Fragment>
                    )
                } 
                // not last one
                else {
                    return (
                        <Fragment key={uuidv4()}>
                            {genCrumbElement(crumb)}
                            <img key={uuidv4()} className='breadcrumb__icon' src={chevron}/>
                        </Fragment>
                    )
                }
            })}
        </div>
    )
}

// external libraries
import Alert from '@material-ui/lab/Alert';
// sub components
import FileExplorer from './FileExplorer';
import 'dotenv/config';

const DownloadPanel = ({ show, setHasPDF, PDFsList, currElevation, modelKey, category }) => {

    /* -------------------------------------------------------------------------
    props, states and hooks
    ------------------------------------------------------------------------- */
    const className = 'download-panel' + (show ? '' : ' invisible');

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    return (
        <div className={className}>
            <h2 className="panel-title">Documents</h2>
            <p className="panel-desc">Click on documents to preview. Select to download.</p>
            {!PDFsList ?
                <Alert severity="error">No PDF documents available for this project</Alert> :
                <FileExplorer
                    setHasPDF={setHasPDF}
                    PDFsList={PDFsList}
                    currElevation={currElevation}
                    modelKey={modelKey}
                    category={category}
                />
            }
        </div>
    );
};

export default DownloadPanel;
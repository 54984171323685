// sub components
import LoginButton from 'components/LoginButton/LoginButton';
// external libraries
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// styling
import 'pages/HomePage/HomeNavbar.scss';

/* -----------------------------------------------------------------------------
styling
----------------------------------------------------------------------------- */
const useStyles = makeStyles(() => ({
    divider: {
        flexGrow: 1
    },
    tab: {
        color: "#ffffff"
    },
    navBar: {
        position: "fixed"
    }
}));

/* -----------------------------------------------------------------------------
app logic
----------------------------------------------------------------------------- */

const HomeNavbar = ({ loggedIn, mobileView }) => {
    const classes = useStyles();

    let desktopMenu = () => {
        return (
            <Toolbar>
                <div className={classes.divider}></div>
                <a href="https://bimstudio.ca" style={{ height: 60 }}>
                    <img src="/BIMstudio.png" style={{ height: 60 }} alt='BIM studio logo' />
                </a>
                <div className={classes.divider}></div>
                <div className='navtext'>Creating BIM Tools since 2019</div>
                <div className={classes.divider}></div>
                    <LoginButton loggedIn={loggedIn} mobileView={mobileView} />
                <div className={classes.divider}></div>
            </Toolbar>
        )
    }

    return (
        <AppBar color="inherit" position="static" className={classes.navBar}>
            {desktopMenu()}
        </AppBar>
    );
};

export default HomeNavbar;

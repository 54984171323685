import * as XLSX from 'xlsx';

/**
 * convert a data object to Excel workbook and download it
 * @param {object} dataObject object with <sheetName>: <sheetRowArray> pairs
 * @param {string} title title of workbook
 */
export function downloadWorkbook(dataObject, title, workbookName) {
    let workbook = XLSX.utils.book_new();
    workbook.Props = {
        Title: `${title ? title : 'BIMhub Quantities Export'}`,
        Subject: 'BIMhub Quantities Export',
        Author: 'BIM Studio',
        // CreatedDate: Date.now()
    };

    // loop through each dataObject and create worksheet
    for (let key of Object.keys(dataObject)) {
        workbook.SheetNames.push(key);
        const worksheet = XLSX.utils.aoa_to_sheet(dataObject[key]);
        workbook.Sheets[key] = worksheet;
    }

    // export the workbook
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const s2ab = (s) => {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }

    // generate download link from blob
    const blob = new Blob([s2ab(excelFile)], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = workbookName.replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 200) + '.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
};
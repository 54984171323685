/***
 * File contains React hook to cache API requests.
 */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateCache, selectCache } from '../store/slices/cacheSlice';

// Modified from https://www.smashingmagazine.com/2020/07/custom-react-hook-fetch-cache-data/

/**
 * React hook to cache API requests.
 * Expects return type to be JSON.
 * @param {string} url
 * @returns {object} JSON object returned by fetch().
 */
const useFetch = (url) => {
    const [data, setData] = useState(null);
    const dispatch = useDispatch();
    const cache = useSelector(selectCache);

    const fetchData = async () => {
        if (cache[url]) setData(cache[url])
        else {
            try {
                // fetch the data from URL
                const newData = await fetch(url, {
                    credentials: 'include'
                })
                .then((res) => res.json())
                .catch((error) => {
                    console.log(`fetchData encountered an issue:`);
                    console.log(error);
                    return null;
                });

                // if data is not null, set cache
                if (newData.status !== 403) {
                    dispatch(updateCache({ url, data: newData }));
                    setData(newData);
                } else {
                    console.log('status is 403. redirecting...');
                    window.location.href = '/'
                }

            } catch (err) {
                console.log('could not useFetch that URL. error:');
                console.error(err)
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [url]);

    return data;
};

export default useFetch;

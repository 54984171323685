import { useDispatch } from 'react-redux';
import { updateAuth } from '../../store/slices/cacheSlice';
import { useHistory } from 'react-router-dom';
import handleLogout from '../../utils/handleLogout';
import { HashLink as Link } from 'react-router-hash-link';
import { Button, Grid } from "@material-ui/core";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import "./Screen403.css";

const Screen403 = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let columnWidth = props.mobileView ? 12 : 6;
    let columnHeight = props.mobileView ? "200px" : "";
    return <Grid container className="screen-403" alignItems="center" spacing={1}>
        <Grid item xs={columnWidth} className="screen-403-text" style={{ height: columnHeight }}>
            <h1>Hi. Are you new here?</h1>
            <p>BIMhub access is limited to emails affiliated with BIMstudio's BIM360 projects. If you have access and see this page, please let somebody at BIMstudio know.</p>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Button fullWidth color="secondary" variant="contained" className="screen-403-button" onClick={() => {
                        dispatch(updateAuth(false));
                        handleLogout();
                        history.push('/');
                    }}><ExitToAppIcon /><span>&nbsp;</span>Switch Accounts</Button>
                </Grid>
                <Grid item xs={6}>
                    <Link style={{ textDecoration: "none" }} to={{ pathname: "/", hash: "#contact" }}><Button fullWidth color="primary" variant="contained" className="screen-403-button"><LockOpenIcon /><span>&nbsp;</span>Request Access</Button></Link>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={columnWidth} className="screen-403-visual" style={{ backgroundImage: "url(/welcome.svg)", height: columnHeight }}>
        </Grid>
    </Grid >
}

export default Screen403;
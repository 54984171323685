// react
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePDFDocURN } from 'store/slices/selectSlice';
// internal library
import { prettyPrintMinutes } from 'utils/generalJS';
// external libraries
import 'dotenv/config';
// sub components
import DataDump from 'components/DataDump/DataDump';
import Dashboard from 'components/Dashboard/Dashboard';
import LoadingStatus from 'components/LoadingStatus/LoadingStatus';
import SelectType from 'components/Selection/SelectType';
import SelectProject from 'components/Selection/SelectProject';
import SelectModel from 'components/Selection/SelectModel';
import SelectLoading from 'components/Selection/SelectLoading';
// styling
import './Select.scss';


/* -----------------------------------------------------------------------------
helper functions
----------------------------------------------------------------------------- */
const extractDashboardLinks = (designObject) => {
    const dashboardLinks = {};
    const projectNames = Object.keys(designObject);

    projectNames.map(name => {
        const project = designObject[name];
        // grab first phase
        const firstPhase = Object.keys(project)[0];
        // grab first model
        const firstModel = Object.keys(project[firstPhase])[0];
        // extract dashboard link
        dashboardLinks[name] = project[firstPhase][firstModel]['powerBIURL'];
    })
    return dashboardLinks;
}

/**
 * add location details to the projectsData object (clone and return new 
 * modified one).
 * @param {*} raw 
 * @param {*} category 
 * @param {*} forgeData 
 * @param {*} projectsList 
 * @returns 
 */
const getLocationDetails = (raw, category, forgeData, projectsList, sharePointData) => {
        
    // if raw is empty or not valid, return it
    if(!raw) return raw;

    // clone the original projectsData
    const _projectsData = Object.assign({}, raw);

    Object.keys(forgeData).forEach(key => {
        // if project is not in Sharepoint Excel file, skip
        if (projectsList.indexOf(key) < 0) {
            return;
        };
        const project = forgeData[key];

        const locationDetails = project['locationDetails'];
        let projectDisplayName = project['attributes']['name'];
        // grab all projects from DESIGN category from sharePointData
        const projectDetails = sharePointData[0][projectDisplayName];
        let name = projectDisplayName;

        // overwrite projectDisplayName if available
        if (projectDetails && Object.keys(projectDetails).length > 0) {
            // grab the first phase
            let firstPhase = projectDetails[Object.keys(projectDetails)[0]];
            // grab the first key
            let firstKey = firstPhase[Object.keys(firstPhase)[0]];
            // finally, grab the projectDisplayName
            projectDisplayName = firstKey['projectDisplayName'];
        }

        // push this project info to our array
        _projectsData[category].push({
            ...locationDetails,
            name,
            projectDisplayName
        })
        // return the new projectsData
    });
    return _projectsData;
}

/* -----------------------------------------------------------------------------
main component
----------------------------------------------------------------------------- */

// fetches data from both SharePoint and Bim360 and passes to child components
const Select = () => {    

    /* -------------------------------------------------------------------------
    states
    ------------------------------------------------------------------------- */
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const [sharePointData, setSharePointData] = useState(null);
    const [powerBIURLs, setPowerBIURLs] = useState({});
    const [forgeData, setForgeData] = useState(null);
    const [projectsData, setProjectsData] = useState(null);
    // for loading animation
    const timeOutSeconds = 20; // will display error if unable to load in this time
    const [loadingMessage, setLoadingMessage] = useState(
        'Loading and caching data...');
    const [loadingError, setLoadingError] = useState(false);

    /* -------------------------------------------------------------------------
    hooks
    ------------------------------------------------------------------------- */
    useEffect(async () => {

        dispatch(updatePDFDocURN(null));

        const fetchOneResource = async (dataToFetch) => {
            const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/${dataToFetch === 'forgeData' ? 'fetchForgeProjects' : 'sharepoint'}`
            const data = await fetch(url, {
                credentials: 'include'
            })
            .then((res) => res.json())
            .catch((error) => {
                console.log(error);
                return new Error(`Error with fetchOneResource()`);
            });
            return data;
        }

        const fetchData = async () => {
            const _sharePointData = await fetchOneResource('sharePointData');
            const _forgeData = await fetchOneResource('forgeData');
            
            // only cache if data available
            if (_sharePointData && _sharePointData.length > 0 && Object.keys(_forgeData).length > 0) {

                setForgeData(_forgeData);
                setSharePointData(_sharePointData);
                const bimHubData = {
                    forgeData: _forgeData, 
                    sharePointData: _sharePointData,
                    timestamp: new Date()
                };
                // disable local storage caching for now
                localStorage.setItem('bimHubData', JSON.stringify(bimHubData));
                console.log(`set new value on ${bimHubData['timestamp'].toLocaleDateString()}`);
            }
        }

        const locallyStored = JSON.parse(localStorage.getItem('bimHubData'));
        // data was previously cached
        if (locallyStored) {
            const storedTime = new Date(locallyStored['timestamp']);
            const now = new Date();
            const diff = Math.abs(now.getTime() - storedTime.getTime());
            const minutes = Math.floor((diff/1000)/60);
            // fetch new data if more than 60 mins
            if (minutes >= 60) {
                setForgeData(null);
                setSharePointData(null);
                console.log(`data is old (set ${prettyPrintMinutes(minutes)}). Fetching new data...`);
                await fetchData();
            } else {
                console.log(`returning forgeData & sharePointData set ${prettyPrintMinutes(minutes)}...`);
                setForgeData(locallyStored['forgeData']);
                setSharePointData(locallyStored['sharePointData']);
            }
        } else {
            await fetchData();
        }
    }, []);

    useEffect(() => {
        if (sharePointData && forgeData) {
            setPowerBIURLs(extractDashboardLinks(sharePointData[0]));

            // generate projects data
            let _projectsData = {
                'pre': [],
                'con': [],
            };

            // get list of design and construction projects
            const sharepointProjectDesign = Object.keys(sharePointData[0]);
            const sharepointProjectCon = Object.keys(sharePointData[1]);

            // populate design data
            _projectsData = getLocationDetails(
                _projectsData, 'pre', forgeData, sharepointProjectDesign, sharePointData);

            // populate construction data
            _projectsData = getLocationDetails(
                _projectsData, 'con', forgeData, sharepointProjectCon, sharePointData);

            setProjectsData(_projectsData);
        }
    }, [sharePointData, forgeData]);

    // timeout logic
    useEffect(() => {
        let timer = setTimeout(() => {
            setLoadingError(true);
            setLoadingMessage(`Unable to connect to one of the 3rd party services. Please try again later`);
            // console.log(`Unable to connect to one of the 3rd party services. Please try again later`);
        }, timeOutSeconds * 1000);
        // clear timeout when component unmounts
        return () => {
            clearTimeout(timer);
        };
    }, []);

    /* -------------------------------------------------------------------------
    returns sub-components, doesn't render anything
    ------------------------------------------------------------------------- */
    if (!sharePointData || !forgeData || !projectsData) {
        return (
        <div className='loading-info'>
            <LoadingStatus
                message={loadingMessage}
                isError={loadingError}
            />
            {/* <Spinner/>
            <div className='loading-info__text'>Loading and caching data...</div> */}
        </div>
    )} else {
        
        return (
            <Switch>
                
                <Route path={`${path}/:type/:project/:phase/:key`}>
                    <SelectLoading 
                        sharePointData={sharePointData} 
                        forgeData={forgeData}
                    />
                </Route>
                
                <Route path={`${path}/:type/:project/dashboard`}>
                    <Dashboard 
                        powerBIURLs={powerBIURLs}
                    />
                </Route>

                <Route path={`${path}/:type/:project`}>
                    <SelectModel 
                        sharePointData={sharePointData} 
                        powerBIURLs={powerBIURLs}
                    />
                </Route>
                
                <Route exact path={`${path}/granular`}>
                    <DataDump 
                        sharePointData={sharePointData} 
                        forgeData={forgeData} 
                    />
                </Route>

                <Route path={`${path}/:type`}>
                    <SelectProject projectsData={projectsData} />
                </Route>
                
                <Route path={`${path}`}>
                    <SelectType />
                </Route>

            </Switch>
        )
    }
};

export default Select;
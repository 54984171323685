import React from 'react';
import BrandButton from 'components/common/BrandButton/BrandButton';
import 'pages/LoggedOutPage/LoggedOutPage.scss';
export default function LoggedOutPage() {

    const returnHome = () => {
        window.location.href = '/';
    }

    return (
        <div className='container-central'>
            <p>You have successfully logged out</p>
            <BrandButton
                text='Return Home'
                actionFunction={returnHome}
            />
        </div>
    )
}

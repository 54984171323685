import icon from './find_in_page.svg';

const Autodesk = window.Autodesk;

function IsolateSelectedExtension(viewer, options) {
    Autodesk.Viewing.Extension.call(this, viewer, options);
}

IsolateSelectedExtension.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
IsolateSelectedExtension.prototype.constructor = IsolateSelectedExtension;

IsolateSelectedExtension.prototype.load = function () {
    return true;
};

IsolateSelectedExtension.prototype.unload = function () {
    if (this.subToolbar) {
        this.viewer.toolbar.removeControl(this.subToolbar);
        this.subToolbar = null;
    }
};

IsolateSelectedExtension.prototype.onToolbarCreated = function (toolbar) {
    var viewer = this.viewer;

    var button1 = new Autodesk.Viewing.UI.Button('IsolateSelectedExtension-button');
    button1.onClick = function (e) {
        const dbIds = viewer.getSelection();
        if (dbIds.length === 0) return;
        document.dispatchEvent(new CustomEvent('isolateFilter', {
            detail: {
                arr: dbIds,
            },
        }));
    };
    button1.onMouseOver = (e) => {
        button1.icon.style.backgroundColor = '#00bfff';
    }

    button1.onMouseOut = (e) => {
        button1.icon.style.backgroundColor = '#ffffff';
    }
    button1.setToolTip('Isolate on 3D Viewer');
    // https://icons8.com/icons/set/double-arrow-right-transparent--white
    button1.icon.style.backgroundColor = '#ffffff';
    button1.icon.style.maskImage = `url('${icon}')`;
    button1.icon.style.webkitMaskImage = `url('${icon}')`;
    button1.icon.style.webkitMaskRepeat = 'no-repeat';
    button1.icon.style.webkitMaskPosition = 'center';

    // SubToolbar
    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('IsolateSelectedExtension-subToolbar');
    this.subToolbar.addControl(button1);

    toolbar.addControl(this.subToolbar);
};

Autodesk.Viewing.theExtensionManager.registerExtension('IsolateSelectedExtension', IsolateSelectedExtension);
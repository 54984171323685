// viewer extensions
import './Extensions/AdvanceSheetsExtension';
import './Extensions/Full3DExtension';
import './Extensions/IsolateSelectedExtension';
// env vars
import 'dotenv/config';
// internal libraries
import ModelData, { propsCache } from './ModelData';
import { buildNestedObject, mergeDeep } from '../../../utils/generalJS';


const Autodesk = window.Autodesk;

// Disable analytics
try {
    Autodesk.Viewing.Private.analytics.optOut();
} catch (err) {
    console.warn(err + 
        '\nThis warning could be caused by an AdBlocker or something similar.' + 
        'If so, ignore this.');
}

// Function is required to load models
const getForgeToken = (callback) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/token`, {
        credentials: 'include'
    }).then(res => {
        if (res.status === 403) {
            console.log('403 found')
        } else {
            res.json().then(data => {
                callback(data.access_token, data.expires_in);
            });
        }
    }).catch(err => {
        console.log('DEBUG - getForgeToken() encountered an issue:')
        console.log(err);
    });
}

export const nodeIdToUrn = (nodeId) => {
    if (nodeId.indexOf('|') > -1) {
        var urn = nodeId.split('|')[1];
        var viewableId = nodeId.split('|')[2];
        return [urn, viewableId];
    } else {
        return [nodeId];
    }
}

const PROGRESSIVE_RENDERING_SETTING = true;
const VIEWER_BACKGROUND_COLOR = [248, 248, 248, 248, 248, 248];

const MODEL_LOAD_STATUS = {
    NOT_LOADED: 0,
    LOADING: 1,
    LOADED: 2,
};

/**
 * load 3D models (yes, plural)
 * 
 * viewer components usually check that modelData3D is not null before exposing 
 * any of its functionality
 * 
 * ensure that modelData3D is set last, and is set to null before 
 * loading / unloading models
 * 
 * @param {*} props 
 * @param {*} nodes 
 * @param {*} switchView 
 * @returns 
 */
export const loadModels3D = (props, nodes, switchView = false) => {

    return new Promise(async (res, rej) => {

        const {
            viewer,
            setModelData3D,
            setCurrentModels3D,
            setFilters
        } = props;

        // clear model data and filters
        setModelData3D(null);
        setFilters(null);

        try {
            let models;
            if (switchView) models = await viewer.viewer3D.switchView(nodes);
            else models = await viewer.viewer3D.setNodes(nodes);

            // debug
            // console.log(`debug - forge viewer switch view complete`);

            viewer.viewer3D.viewer.addEventListener(
                Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
                viewer.viewer3D.viewer.setBackgroundColor(...VIEWER_BACKGROUND_COLOR);
            });

            for (const model of models) {
                const guid = model.getDocumentNode().guid();

                viewer.models3DMap[guid] = { 
                    loaded: MODEL_LOAD_STATUS.LOADING, 
                    model 
                };

                if (guid in viewer.modelData3DMap) {
                    viewer.models3DMap[guid].loaded = MODEL_LOAD_STATUS.LOADED;
                    continue;
                }

                // viewer.viewer3D.viewer points to the latest loaded model, 
                // so we can use ModelData without modding
                const md = new ModelData(model, viewer);
                md.init(async () => {
                    viewer.models3DMap[guid].loaded = MODEL_LOAD_STATUS.LOADED;
                    viewer.modelData3DMap[guid] = md;
                });
            }
        } catch (err) {
            console.log(`error while switching models: `);
            console.log(err);
            rej(err);
        }

        // merely defining setInterval also triggers it?
        const checker = setInterval(async () => {
            try {
                // In case unmount
                if (viewer.viewer3D === null) {
                    clearInterval(checker);
                    rej();
                }

                const guids = [];
                for (const node of nodes) {
                    guids.push(node.data.guid);
                }
                const check = guids.map((guid) => {
                    if (guid in viewer.models3DMap) {
                        if (viewer.models3DMap[guid].loaded === MODEL_LOAD_STATUS.LOADED) return true;
                    }
                    return false;
                });

                if (check.every((el) => el === true)) {
                    clearInterval(checker);
                    const current = [];
                    for (const guid of Object.keys(viewer.models3DMap)) {
                        if (!guids.includes(guid)) viewer.models3DMap[guid].loaded = 0;
                        else current.push(viewer.models3DMap[guid]);
                    }
                    setCurrentModels3D(current);

                    // Show all dbIds
                    for (const obj of Object.values(viewer.models3DMap)) {
                        if (obj.loaded === MODEL_LOAD_STATUS.LOADED) {

                            const { model } = obj;
                            if (model) {
                                
                                // console.log(`debug - model ${model.id} is`);
                                // console.log(model);

                                // DEBUG
                                let elemIDs = Object.keys(model.getInstanceTree().nodeAccess.nameSuffixes);
                                elemIDs = elemIDs.map((el) => Number(el));

                                let dbIds = Object.keys(model.getInstanceTree().nodeAccess.dbIdToIndex);
                                dbIds = dbIds.map((el) => Number(el));

                                if (dbIds && dbIds.length > 0 && viewer.viewer3D.viewer) {
                                    // https://forge.autodesk.com/en/docs/viewer/v7/reference/Viewing/Viewer3D/#show-node-model
                                    // show(node, model)
                                    // Ensures the passed in nodes (dbIds) are shown   
                                    try {
                                        viewer.viewer3D.viewer.show(dbIds, model);
                                    } catch(error) {
                                        console.log(`Error encounter while trying to load a view. Rejecting promise...`)
                                        rej(error.message);
                                    }
                                }
                            }
                        }
                    }

                    // Note: it is important this happens AFTER showing all dbIds, since we turn off misc
                    // after modelData3D is set (no longer null)
                    await processModels3D(
                        viewer, setModelData3D, setFilters, current);
                    res();
                }
            } catch (err) {
                console.log(`error while switching models: `);
                console.log(err);
                // throw an error for the ErrorBoundary to handle
                // throw new Error(`Unable to load this view, it's probably empty`);
                rej(err);
            }
        }, 500);
    });
};

/**
 * 
 * @param {*} viewer 
 * @param {*} setModelData3D 
 * @param {*} setFilters 
 * @param {array} current list of guids currently loaded into the viewer
 * @returns 
 */
const processModels3D = (viewer, setModelData3D, setFilters, current) => {
    return new Promise(async (res, _rej) => {

        try {
            // Merge modelData
            const base = viewer.modelData3DMap[current[0].model.getDocumentNode().guid()];
            
            for (let i = 1; i < current.length; i++) {
                const merge = viewer.modelData3DMap[current[i].model.getDocumentNode().guid()];
                for (const displayName of Object.keys(merge._modelData)) {
                    
                    if (base._modelData[displayName] === undefined) {
                        // console.log(`debug - base._modelData[${displayName}] is undefined`);
                        base._modelData[displayName] = merge._modelData[displayName];
                        continue;
                    }
                    
                    // debug
                    // let total = Object.keys(merge._modelData[displayName]).length;
                    // let undef = 0;

                    for (const displayValue of Object.keys(merge._modelData[displayName])) {

                        if (base._modelData[displayName][displayValue] === undefined) {
                            
                            // console.log(`debug - base._modelData[${displayName}][${displayValue}] is undefined`);
                            // undef += 1;

                            base._modelData[displayName][displayValue] = merge._modelData[displayName][displayValue];
                            continue;
                        }
                        base._modelData[displayName][displayValue].push(...merge._modelData[displayName][displayValue]);
                    }

                    // console.log(`debug - ${undef} was undefined out of ${total}`);
                }
                // console.log(`debug - im running in processModels3D - 2B`);
            }

            // console.log(`debug - im running in processModels3D - 3`);
            const filters = await makeFilters(base, current);
            // console.log(`debug - im running in processModels3D - 4`);

            setFilters(filters);
            // Make sure setModelData3D is always last
            setModelData3D(base);
            // console.log(`debug - im running in processModels3D - 5`);

        } catch(error) {
            console.log(`debug - error in in processModels3D`);
            console.log(error);
        }
        
        // console.log(`debug - about to res in processModels3D - 6`);
        res();
    });
};

export const launchViewer3D = (props) => {

    return new Promise((_res, rej) => {
        
        const { type, data, urn, viewer, setDocument3D, setViewables3D } = props;
        const lot = data.lot;
        
        const options = {
            env: 'AutodeskProduction',
            getAccessToken: getForgeToken,
            logLevel: Autodesk.Viewing.Private.LogLevels.DEBUG,
            // Handle BIM 360 US and EU regions
            api: 'derivativeV2' + (atob(urn.replace('_', '/')).indexOf('emea') > -1 ? '_EU' : ''),
        };

        Autodesk.Viewing.Initializer(options, () => {
            const documentId = 'urn:' + urn;
            Autodesk.Viewing.Document.load(
                documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
        });

        const onDocumentLoadSuccess = async (doc) => {
            setDocument3D(doc);

            // Only put Autodesk.DocumentBrowser if in development
            const viewerConfigExtensions = [
                'Autodesk.AEC.LevelsExtension',
                'Autodesk.VisualClusters',
            ];
            if (process.env.NODE_ENV === 'development') {
                viewerConfigExtensions.push('Autodesk.DocumentBrowser');
            }

            const viewerConfig = {
                theme: 'dark-theme',
                // AEC.LevelsExtension
                //see https://stackoverflow.com/questions/67710138/mirrored-model-has-its-materials-flipped-inside-out-when-viewed-in-autodesk-forg
                extensions: viewerConfigExtensions,
                // Memory management for large models
                loaderExtensions: { svf: "Autodesk.MemoryLimited" },
                memory: {
                    limit: 4096,
                    // debug: {
                    //     force: true
                    // }
                },
                profileSettings: {
                    settings: {
                        lightPreset: 'Boardwalk',
                        grayscale: false,
                        ambientShadows: false,
                        groundShadow: false,
                        envMapBackground: false,
                        ghosting: false,
                        antiAlias: false,
                        reverseMouseZoomDir: true,
                    }
                }
            };

            viewer.viewer3D = new Autodesk.Viewing.AggregatedView();
            await viewer.viewer3D.init(document.getElementById('viewer3D'), { viewerConfig });
            // more viewer method
            // https://forge.autodesk.com/en/docs/viewer/v7/reference/Viewing/Viewer3D/
            viewer.viewer3D.viewer.setQualityLevel(false /*useSAO*/, false/*useFXAA*/);
            viewer.viewer3D.viewer.setOptimizeNavigation(true);
            viewer.viewer3D.viewer.start();
            viewer.viewer3D.viewer.setProgressiveRendering(PROGRESSIVE_RENDERING_SETTING);
            viewer.viewer3D.viewer.setContextMenu(null);
            viewer.viewer3D.viewer.loadExtension('Full3DExtension');
            // display edge option
            viewer.viewer3D.viewer.setDisplayEdges(true);

            // viewables
            const viewables = doc.getRoot().search({ type: 'geometry', role: '3d' }).filter((el) => {
                if (el.data && el.data.name) return true;
                return false;
            });
            const def = viewables[0];

            setViewables3D(viewables);

            // Handle CON loading here: there's no other way user can switch view
            // We will handle PRE in InfoPanelElevation
            if (type === 'CON') {
                // Check for lot
                let flag = false;
                for (const i in viewables) {
                    if (lot !== '' && viewables[i].data.name.includes(lot)) {
                        flag = true;
                        loadModels3D(props, [viewables[i]]);
                        break;
                    }
                }
                if (!flag) {
                    loadModels3D(props, [def]);
                }
            }
        }

        const onDocumentLoadFailure = (err) => {
            console.error('onDocumentLoadFailure() - errorCode:' + err);
            rej();
        }
    });
}

/**
 * 
 * @param {object} propsObject 
 * @returns 
 */
const buildTOIChain = (propsObject) => {    
    let toiLevel = 1;
    let toiChain = [];
    let continueWithToi = true;
    while (continueWithToi) {
        let curToi = `TOI${toiLevel}`;
        // TOI exists and not null or empty
        if (curToi in propsObject && propsObject[curToi] && propsObject[curToi] !== '') {
            toiChain.push(propsObject[curToi]);
            toiLevel += 1;
        } else {
            // stop processing further
            continueWithToi = false;
        }
    }
    return toiChain;
}

/**
 * extract an object's TOI information and add to the "filters" object
 * @param {integer} dbId an object's id
 * @param object} filterTreeArray the filters object TO BE MODIFIED directly
 * @param {array} current list of guids currently loaded into the viewer
 * @param {integer} minTOILevels min number of TOI levels required for elements 
 * to be registered on the filter tree. Else, put in misc
 * @returns none. the "filters" object is modified directly
 */
const getPropertiesPromise = (dbId, filterTreeArray, current, elementsWithChildren = [], minTOILevels = 3) => {
    return new Promise((res, rej) => {

        // for each model (which consists of dbIds) currently loaded in the viewer
        for (const el of current) {
            // get the guid of the model
            const guid = el.model.getDocumentNode().guid();

            // debug
            // if ([46028, 45990].includes(dbId)) {
            //     console.log(`is (dbId in propsCache[guid]? ${(dbId in propsCache[guid])}`);
            // }

            if ((guid in propsCache) && (dbId in propsCache[guid])) {

                try {
                    let properties = propsCache[guid][dbId];

                    // debug
                    // if ([46028, 45990].includes(dbId)) {
                    //     console.log(`all props of ${dbId} are`);
                    //     console.log(properties);
                    // }

                    // put all the props in a conventional object format
                    let propsObject = properties.reduce((prev, cur) => {
                        prev[cur.displayName] = cur.displayValue;
                        return prev
                    }, {});
                    let category = propsObject['Category'];
                    let typeName = propsObject['Type Name'];
                    const toiChain = buildTOIChain(propsObject);

                    // if the toi chain has at least <minTOILevels> TOIs
                    if (toiChain.length >= minTOILevels) {
                        const filterObject = buildNestedObject(toiChain, { dbIds: [dbId] });
                        filterTreeArray.push(filterObject);
                        // debug
                        // console.log(`dbId ${dbId} resolved`);
                        res();
                        return;
                    }
                    // not enough TOI levels, check its category and typeName
                    else if (category && typeName && category !== '' && typeName !== '') {
                        const miscObject = {
                            'Miscellaneous': {
                                [category]: {
                                    [typeName]: {
                                        dbIds: [dbId]
                                    }
                                }
                            }
                        };
                        filterTreeArray.push(miscObject);
                        // debug
                        // console.log(`dbId ${dbId} resolved`);
                        res();
                        return;
                    } 
                    else {
                        const alienObject = {
                            'Miscellaneous': {
                                'Aliens': {
                                    dbIds: [dbId]
                                }
                            }
                        }
                        filterTreeArray.push(alienObject);
                        // debug
                        // console.log(`dbId ${dbId} resolved`);
                        res();
                        return;
                    }
                } catch(error) {
                    // console.log(`error in getPropertiesPromise withd dbId ${dbId}`)
                    // console.log(error);
                    rej(error);
                }
                return;
            } else {
                // here is the problem
                // console.log(`warning: dbId ${dbId} not in propsCache[guid]. promise not resolved`)
                rej(`dbId ${dbId} not in propsCache[guid]. promise not resolved`);
            }
        }
    });
};

/**
 * 
 * @param {*} modelData 
 * @param {array} current list of guids currently loaded into the viewer
 * @returns 
 */
export const makeFilters = async (modelData, current) => {    
    try {
        const filterTreeArray = [];
        
        const elementsWithChildren = modelData.elementsWithChildren;
        // push elements with children to filter tree
        elementsWithChildren.forEach(elem => {
            // const elemProps = propsCache[elem];
            // console.log(`elemProps is `);
            // console.log(elemProps);
            // const category = elemProps['Category'];
            // const typeName = elemProps['Type Name'];
            // alert(`category is ${category} and typeName is ${typeName}`);
            // const alienObject = {
            //     '[Nested Elements]': {
            //         '<All>': {
            //                 dbIds: [elem]
            //             }
            //         }
            //     }
            // filterTreeArray.push(alienObject);
        });

        var dbIds = [];
        for (const arr of Object.values(modelData._modelData['Workset'])) {                    
            dbIds.push(...arr);
        }

        const promises = [];

        // debug
        // console.log(`all dbs are`);
        // console.log(dbIds);

        for (const dbId of dbIds) {
            promises.push(getPropertiesPromise(dbId, filterTreeArray, current, elementsWithChildren));
        }

        // TODO - revisit this and the propscache shit
        for(const pro of promises) {
            try {
                await pro;
            } catch(error) {
                // console.log(error);
                continue;
            }
        }

        // debug - remember to toggle back on
        // await Promise.allSettled(promises);

        // console.log(`debug - makeFilters all promises settled`);

        const filters = filterTreeArray.reduce(mergeDeep);

        const sorted = sortFilter(filters);
        for (const key of Object.keys(filters)) {
            delete filters[key];
        }
        for (const key of Object.keys(sorted)) {
            filters[key] = sorted[key];
        }

        return filters;
    } catch(error) {
        console.log(`debug - error in makeFilters:`);
        console.log(error);
    }
    
}

/**
 * sort the "filters" object to put Miscellaneous category last
 * @param {object} obj the unsorted "filters" object
 * @returns new object with Miscellaneous at the bottom
 */
const sortFilter = (obj) => {
    var newObj = {};
    var miscFlag = false;
    Object.keys(obj).sort().forEach((key) => {
        if (key === 'Miscellaneous') {
            miscFlag = true;
            return;
        }
        else if (key === 'dbIds') {
            newObj[key] = obj[key];
        }
        else newObj[key] = sortFilter(obj[key]);
    });
    if (miscFlag) newObj['Miscellaneous'] = sortFilter(obj['Miscellaneous']);
    return newObj;
};

// from http://adndevblog.typepad.com/cloud_and_mobile/2016/10/get-all-database-ids-in-the-model.html
export const getAllDbIds = (viewer) => {
    var instanceTree = viewer.model.getData().instanceTree;
    var allDbIdsStr = Object.keys(instanceTree.nodeAccess.dbIdToIndex);
    return allDbIdsStr.map(function (id) { return parseInt(id) });
}
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'cache',
    initialState: {
        cache: {},
        auth: {
            status: null,
        },
    },
    reducers: {
        updateCache: (state, action) => {
            var { url, data } = action.payload;
            state.cache[url] = data;
        },
        updateAuth: (state, action) => {
            state.auth.status = action.payload;
        }
    },
});

export const { updateCache, updateAuth } = slice.actions;

export const selectCache = (state) => state.cache.cache;
export const selectAuth = (state) => state.cache.auth;

export default slice.reducer;
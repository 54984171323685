// react
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
// sub components
import Sidebar from './Sidebar/Sidebar';
import FilterPanel from './FilterPanel/FilterPanel'
import InfoPanel from './Info/InfoPanel';
import QuantityPanel from './QuantityPanel/QuantityPanel';
import MarkupPanel from './MarkupPanel/MarkupPanel';
import ColourPackagesPanel from './ColourPackagesPanel/ColourPackagesPanel';
import DownloadPanel from './DownloadPanel/DownloadPanel';
import QuantityTooltip from './QuantityTooltip';
// styling
import './ControlPanel.scss';
// external libraries
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const SIDEBAR_ICONS = [
    {
        iconPurpose: 'info',
        iconName: 'info-circle',
        iconTitle: 'Information / Design Options'
    },
    {
        iconPurpose: 'colourpackages',
        iconName: 'paint-roller',
        iconTitle: 'Colour Packages'
    },
    {
        iconPurpose: 'filter',
        iconName: 'filter',
        iconTitle: 'Model Filtering'
    },
    {
        iconPurpose: 'markup',
        iconName: 'highlighter',
        iconTitle: 'Markups'
    },
    {
        iconPurpose: 'quantity',
        iconName: 'calculator',
        iconTitle: 'Quantities'
    },
    {
        iconPurpose: 'download',
        iconName: 'file-pdf',
        iconTitle: 'Download'
    },
]

const ControlPanel = (props) => {

    /* -------------------------------------------------------------------------
    props, states and hooks
    ------------------------------------------------------------------------- */
    const [activeIcon, setActiveIcon] = useState({});
    const [panelOpen, setPanelOpen] = useState(false);

    /* -------------------------------------------------------------------------
    supporting functions
    ------------------------------------------------------------------------- */
    const setPanelState = (iconPurpose, panelState) => {
        let activeIcon = SIDEBAR_ICONS.filter(x => x.iconPurpose === iconPurpose)[0]
        if (activeIcon) handleSelection(activeIcon, panelState);
    }

    const handleSelection = (activeIcon, panelOpen) => {
        setActiveIcon(activeIcon);
        setPanelOpen(panelOpen);
    }

    const isPanelShown = (iconPurpose) => {
        return activeIcon.iconPurpose === iconPurpose && panelOpen === true;
    }
    
    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    return (
        <div className='controlpanel'>
            {/* modal tooltip inside 3D model - not part of the flow */}
            <QuantityTooltip modelData3D={props.modelData3D} viewer={props.viewer}/>
            
            {/* left sidebar with icons */}
            <Sidebar
                activeIcon={activeIcon}
                panelOpen={panelOpen}
                handleSelection={handleSelection}
                icons={SIDEBAR_ICONS}
            ></Sidebar>
                
            {/* panels */}
            <div 
                className={panelOpen ? 'infopanel' : 'invisible'} 
                style={{visibility: activeIcon.iconPurpose === 'markup' ? 'hidden' : 'visible'}}
            >
                <IconButton style={{
                    position: 'absolute', top: 10, right: 10
                }} onClick={() => { setPanelState(activeIcon.iconPurpose, false) }}>
                    <CloseIcon />
                </IconButton>
                
                <InfoPanel
                    show={isPanelShown('info')}
                    {...props}
                />
                <FilterPanel
                    show={isPanelShown('filter')}
                    viewer={props.viewer}
                    filters={props.filters}
                    currentModels3D={props.currentModels3D}
                    modelData3D={props.modelData3D}
                    mobileView={props.mobileView}
                />
                <QuantityPanel
                    show={isPanelShown('quantity')}
                    toiAggStart={props.toiAggStart}
                    {...props}
                />
                <MarkupPanel
                    show={isPanelShown('markup')}
                    collapsePanel={() => { setPanelState('markup', false) }}
                    {...props}
                />
                <ColourPackagesPanel
                    show={isPanelShown('colourpackages')}
                    {...props} />
                <DownloadPanel
                    show={isPanelShown('download')}
                    currElevation={props.currElevation}
                    setHasPDF={props.setHasPDF}
                    PDFsList={props.PDFsList}
                    modelKey={props.modelKey}
                    category={props.type}
                />
            </div>
        </div>
    )
}

export default withRouter(ControlPanel);
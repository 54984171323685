// react
import React from 'react';
// sub components
import Spinner from 'components/Spinner/Spinner';
import QuantityTable from './QuantityTable';
// styling
import './QuantityPanel.scss';
// internal libraries
import { buildNestedObject, mergeDeep, 
    sumWithUnit, editArraysInNestedObject } from 'utils/generalJS';

/* -----------------------------------------------------------------------------
utility functions
----------------------------------------------------------------------------- */

/**
 * 
 * @param {array} sourceArray 
 * @returns 
 */
const padTOI = (sourceArray) => {
    // first, check if we need to insert a TOI label
    const good = sourceArray.every(item => item['toms'] !== undefined);
    if (good) return sourceArray;
    
    // not good, need to process
    const result = { 'Others': [] }
    for (const elem of sourceArray) {
        // for objects with toms, push them to Others list
        if (elem['toms'] !== undefined) {
            result['Others'].push(elem);
        } 
        // for everyone else, unpack them
        else {
            Object.keys(elem).forEach(key => {
                result[key] = padTOI(elem[key]);
            })
        };
    };
    return result;
}

/**
 * 
 * @param {*} sourceArray 
 * @returns 
 */
const insertSummaryObject = (sourceArray) => {
        
    if (!(sourceArray instanceof Array)) {
        console.warn(`insertSummaryObject: passed sourceArray is not actually an array, terminating...`);
    }
    
    const summaryObject = {}
    
    // for each item in source array
    sourceArray.map(item => {
        // process toms
        const toms = item['toms'];
        // if valid toms
        if (toms && Object.keys(toms).length > 0) {
            for (let toi of Object.keys(toms)) {
                if (toi in summaryObject) {
                    summaryObject[toi] = sumWithUnit(summaryObject[toi], toms[toi]);
                } else {
                    summaryObject[toi] = toms[toi];
                }
            }
        } else {
            console.log(`insertSummaryObject: no toms found in this item:`);
            console.log(item);
        }
        
        // process tods, if available
        const tods = item['tods'];
        // if valid tods
        if (tods && Object.keys(tods).length > 0) {
            for (let tod of Object.keys(tods)) {
                // if multiple values detected for same tod, put it as 'varies'
                if (tod in summaryObject) {
                    if (summaryObject[tod] === '<varies>') continue;
                    if (summaryObject[tod] !== tods[tod]) {
                        summaryObject[tod] = '<varies>'
                    };
                } else summaryObject[tod] = tods[tod]
            }
        }
    });

    // add TOM1 and TOM2 to summaryObject
    const allKeys = Object.keys(summaryObject);
    // remove TOD keys
    if (allKeys && Object.keys(summaryObject).length > 0) {
        const allTOMs = allKeys.filter(item => !item.includes('TOD'));
        if (allTOMs.length > 0) {
            if (allTOMs.length > 1) {
                summaryObject['TOM2'] = summaryObject[allTOMs[1]];
            };
            summaryObject['TOM1'] = summaryObject[allTOMs[0]]; 
        };
    
        const newArray = [summaryObject, ...sourceArray];
        return newArray;
    } else {
        console.log(`insertSummaryObject: summaryObject not constructed for some items. returning source array without modification:`);
        console.log(sourceArray);
        return sourceArray
    }
}

/**
 * given the model object, return its leaf components as a promise
 * @param {object} model 
 * @returns {promise} a promise, once resolved, returns a list of all leaf 
 * elements (i.e. those without child elements)
 */
const getAllLeafComponents = (model) => {
    return new Promise((resolve, _reject) => {
        // from https://learnforge.autodesk.io/#/viewer/extensions/panel?id=enumerate-leaf-nodes
        model.getObjectTree(function (tree) {
            var leaves = [];
            tree.enumNodeChildren(tree.getRootId(), function (dbId) {
                if (tree.getChildCount(dbId) === 0) {
                    leaves.push(dbId);
                } 
                // handle case for parent nodes that need to be displayed
                else {
                    model.getProperties(dbId, 
                        // on success callback
                        function (props) {
                        for (let i = 0; i < props.properties.length; i++) {
                            let prop = props.properties[i];
                            // this one has TOI1
                            if (prop.displayName.startsWith('TOI1') && prop.displayValue !== '') {
                                leaves.push(dbId);
                                break;
                            }
                        }
                    }, 
                    // on error callback
                    function (error) {
                        alert(`error trying to fetch properties of ${dbId}: ${error}`)
                    })
                };
            }, true);
            resolve(leaves);
        });
    });
}

/* -----------------------------------------------------------------------------
main component
----------------------------------------------------------------------------- */
class QuantityPanel extends React.Component {
    
    constructor(props) {
        super();
        this.state = {
            loadedCategoricalData: false,
            tooltipOpen: false,
            selectionData: []
        };

        this.categoricalData = {};
        this.allDbidObjects = [];
        // for data dump
        this.dataDumpHeader = ['Model', 'DBID', 'TOI1', 'TOI2', 'TOI3', 'TOI4', 'TOI5', 'TOI6', 'TOD1', 'TOD2', 'TOD3'];
        this.dataDumpRows = []; // array of arrays
        this.dataRowCount = 0;

        // debug
        this.allDBIDs = {};
    }

    /**
     * 
     */
    async populateCategoricalData(allowDup = true) {
        const { viewer } = this.props;
        const tracker = []; // track duplicates dbIds in different models
        const promises = [];
        
        // for each loaded model
        for (const el of Object.values(viewer.models3DMap)) {
            // status 2 is loaded, 0 is not loaded
            if (el.loaded !== 2) continue;
            // console.log(`debug - loading a model...`);
            const model = el.model;
            const dbIds = await getAllLeafComponents(model);
            // for each dbid of this model
            for (const dbId of dbIds) {
                // if dbId already processed, continued
                if (!allowDup && tracker.indexOf(parseInt(dbId)) > -1) {
                    continue;
                } else {
                    tracker.push(dbId);
                    promises.push(this.getPropertiesPromise(model, dbId));
                }
            };
            // console.log(`debug - total dbid processed is ${tracker.length}`);
        };

        // collect all dbidObjects
        await Promise.allSettled(promises);

        // merge all dbidObjects into 1 categorical data
        const catData = this.allDbidObjects.reduce(mergeDeep);

        // insert a TOI padding for cases where certain objects in same array
        // have deeper toi structures than others
        editArraysInNestedObject(catData, padTOI);

        // add summary object tocategorical data
        editArraysInNestedObject(catData, (source) => {
            return insertSummaryObject(source)
        });

        this.categoricalData = catData;
    }
        
    /**
     * loop through all properties of an element and extract useful properties
     * @param {*} model 
     * @param {string} dbid dbid of the element
     * @returns 
     */
    getPropertiesPromise(model, dbid) {
        return new Promise((resolve, reject) => {
            const modelName = model.getDocumentNode().name();
            
            // TODO: rethink TOD
            let TOIChain = [];
            const TOMObject = {
                'dbid': dbid,
                'toms': {},
                'tods': {}
            };
            let TOMValue = null;
            let dataDumpRow = [modelName, dbid];
            
            // go through all props of a dbid
            model.getProperties(dbid, (data) => {

                // WIP: sort props by displayName
                const rawProps = data.properties;

                const allProps = rawProps.sort((a, b) => {
                    if (a.displayName < b.displayName) {
                        return -1;
                    }
                    if (a.displayName > b.displayName) {
                        return 1;
                    }
                    return 0;
                });
                
                // loop through all the props
                allProps.map(item => {
                   
                    // if this prop has empty display value, stop processing
                    if (item.displayValue === '') {
                        reject(`Empty value for prop ${item.displayName} of dbId ${dbid}`);
                        return;
                    }

                    // extract all the TOI values - pattern TOI<number>
                    if (item.displayName.search(/TOI[1-9]/) > -1) {
                        TOIChain.push(item);
                        // for data dump
                        // if TOI label not exist in header, add it
                        if (!this.dataDumpHeader.includes(item.displayName)) {
                            this.dataDumpHeader.push(item.displayName);
                        };
                        // find index of header and use it to slot value
                        let columnPosition = this.dataDumpHeader.indexOf(item.displayName);
                        dataDumpRow[columnPosition] = item.displayValue;
                    };

                    // catch the TOM value
                    if (item.displayName === 'TOM') {
                        TOMValue = item.displayValue;
                    };
                    // extract TOD values
                    if (item.displayName.search(/TOD[1-9]/) > -1) {
                        TOMObject['tods'][item.displayName] = item.displayValue
                        // for data dump
                        // if TOI label not exist in header, add it
                        if (!this.dataDumpHeader.includes(item.displayName)) {
                            this.dataDumpHeader.push(item.displayName);
                        };
                        // find index of header and use it to slot value
                        let columnPosition = this.dataDumpHeader.indexOf(item.displayName);
                        dataDumpRow[columnPosition] = item.displayValue;

                    };
                })

                // if empty TOI chain, abort
                if (TOIChain.length < 1) {
                    reject(`No TOI for dbId ${dbid}`);
                    return;
                };

                // check the TOIChain for TOI1 (required)
                let TOI1Present = false;
                for (let item of TOIChain) {
                    if (item.displayName === 'TOI1' && item.displayName !== '') {
                        TOI1Present = true;
                        break;
                    }
                }

                // no TOI1, skip
                if (!TOI1Present) {
                    reject(`TOI1 missing for dbId ${dbid}`);
                    return;
                }

                try {
                    /* ---------------------------------------------------------
                    process TOI
                    --------------------------------------------------------- */
                    // first, sort by displayName TOI1, TOI2, TOI3...etc..
                    TOIChain.sort((a, b) => {
                        return a.displayName.localeCompare(b.displayName);
                    })

                    TOIChain = TOIChain.map(item => item.displayValue);
                    // add the model name as first item of TOI chain
                    TOIChain.unshift(modelName);

                    /* ---------------------------------------------------------
                    process TOM
                    --------------------------------------------------------- */
                    if (TOMValue && TOMValue !== '') {
                        let tomData = TOMValue.split(' - ');
                        // for each entry encoded in tomData
                        for (let tomEntry of tomData) {
                            // valid tom entry
                            if (tomEntry !== '' && !tomEntry[0].includes('null')) {
                                // part before ':' is item name (e.g. 'LENGTH:14.46_LF')
                                // part after ':' is unit of measurement and value
                                const tomEntryData = tomEntry.split(':');
                                if (tomEntryData[0] !== 'null') {
                                    TOMObject['toms'][tomEntryData[0]] = tomEntryData[1];
                                }
                                // for data dump
                                // get the unit of measurement and add it to header
                                let TOMHeader = null;
                                let TOMValue = null;
                                try {
                                    // e.g. 14.46_LF --> 14.46 and LF
                                    const splitProperty = tomEntryData[1].split('_');
                                    TOMHeader = `${tomEntryData[0]} (${splitProperty[1]})`;
                                    TOMValue = parseFloat(splitProperty[0]);
                                } catch(error) {
                                    console.log(`Unable to parse TOM string, will not process`);
                                    console.warn(error);
                                    TOMHeader = tomEntryData[0];
                                    TOMValue = tomEntryData[1];
                                }
                                // if TOI label not exist in header, add it
                                if (!this.dataDumpHeader.includes(TOMHeader)) {
                                    this.dataDumpHeader.push(TOMHeader);
                                };
                                // find index of header and use it to slot value
                                let columnPosition = this.dataDumpHeader.indexOf(TOMHeader);
                                dataDumpRow[columnPosition] = TOMValue;
                            }
                        }
                    }

                    /* ---------------------------------------------------------
                    construct complete categorical object for this dbid
                    --------------------------------------------------------- */
                    const dbidObject = buildNestedObject(TOIChain, [TOMObject]);
                    
                    this.allDbidObjects.push(dbidObject);

                    /* ---------------------------------------------------------
                    populate data dump too
                    --------------------------------------------------------- */
                    this.dataDumpRows.push(dataDumpRow);
                    // up the count for debug
                    this.dataRowCount += 1;

                    // finally, call resolve() to end 
                    resolve();
                } catch (err) {
                    console.log(err);
                    reject(err);
                    return;
                }
                
            });
        });
    }
    
    getCategoricalData() {
        if (!this.state.loadedCategoricalData) {
            let checker = setInterval(() => {
                if (this.state.loadedCategoricalData) {
                    clearInterval(checker);
                    return this.categoricalData;
                }
            }, 100);
        } else {
            return this.categoricalData;
        };
    }

    async createQuantityPanel() {
        this.setState({ loadedCategoricalData: false })
        try {
            await this.populateCategoricalData();
            this.setState({ loadedCategoricalData: true });
        } catch (err) {
            console.error('Error while parsing quantities data, aborting operation : ' + err.message);
        }
    }

    /**
     * 
     * @param {*} categoricalData 
     * @returns 
     */
    processCategoricalData(categoricalData) {
        // final output    
        const tableColumns = ['MODEL'];
        const tableData = [];

        // will get reset after each complete data write
        let curTOI = 0;
        let curRow = [];    
        let curTOIChain = [];

        const findArray = (nestedObject) => {

            // for each key-value pair
            const allKeys = Object.keys(nestedObject);
            
            for (let i = 0; i < allKeys.length; i++) {
                const key = allKeys[i];
                // add to TOI chain
                curTOIChain.push(key);

                curTOI += 1;
                const TOILabel = `TOI${curTOI}`;
                // add new TOI header (if not already exists) as we go 1 level deeper
                if (tableColumns.indexOf(TOILabel) === -1) tableColumns.push(TOILabel);

                const curChild = nestedObject[key];
                
                if (curChild instanceof Array) {
                    
                    // reached the end, add TOI chain memebers to curRow
                    curTOIChain.map(value => {curRow.push(value)});

                    // reset TOI-related vars
                    curTOI = 0;
                    // remove current array key to make space for next array key
                    curTOIChain.pop();

                    // get summary data which is first element of array
                    const summaryData = curChild[0];
                    // loop through all measurements of summaryData
                    for (let measurement of Object.keys(summaryData)) {
                        try {
                            // skip if the unit of measurement is TOM1 or TOM2
                            // (only aggregate table needs TOM1 and TOM2)
                            if (measurement.includes('TOM')) { continue; }
                            // if a measurement is not yet in tableColumns, add it 
                            if (tableColumns.indexOf(measurement) === -1) {
                                tableColumns.push(measurement);
                            };
                            // retrieve the measurement's index to populate corresponding row data
                            const measurementColumnIndex = tableColumns.indexOf(measurement);
                            // if key is TOD, keep value as string
                            if (measurement.includes('TOD')) {
                                curRow[measurementColumnIndex] = summaryData[measurement];
                            } else {
                                const splitProperty = summaryData[measurement].split('_');
                                // convert the value from string to number
                                curRow[measurementColumnIndex] = +splitProperty[0];
                            };
                        } catch {
                            console.log(`Unable to process measurement ${measurement} for this summaryData object:`);
                            console.log(summaryData);
                            continue;
                        }
                    };
                    // add the single row to tableData and reset it
                    tableData.push(curRow);
                    curRow = [];
                } else {
                    // recursively look for array
                    findArray(curChild)
                }
                // if current index is the last one, pop the current TOI
                if (i === allKeys.length - 1) {
                    curTOIChain.pop();
                }
            }
        }

        for (let modelName of Object.keys(categoricalData)) {
            curTOIChain.push(modelName);
            const curObject = categoricalData[modelName];
            findArray(curObject);
            // reset toi chain after each model
            curTOIChain = [];
        }
        
        return [tableColumns, tableData]
    }

    componentDidUpdate(prevProps) {
        if (prevProps.modelData3D === null && this.props.modelData3D !== null) {
            // reset existing model data
            this.categoricalData = {};
            this.allDbidObjects = [];
            this.dataDumpHeader = ['Model', 'DBID'];
            this.dataDumpRows = [];
            this.dataRowCount = 0;
            // re-create the panel
            this.createQuantityPanel();
        }
    }

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    render() {
        let className = 'quantity-panel' + (this.props.show ? '' : ' invisible');
        return (
            <div className={className}>
                <h2 className='panel-title'>Quantities</h2>
                <p className='panel-desc'>Inspect and export QTO data summaries.</p>
                {
                    (this.props.modelData3D === null)
                        ? 
                        <Spinner />
                        : 
                        <QuantityTable 
                            // dataForExport for Excel export
                            dataForExport={() => this.processCategoricalData(this.categoricalData)} 
                            // categoricalData for the aggregate table
                            categoricalData={() => this.getCategoricalData()} 
                            // detailed view of every single element
                            dataDump={{
                                dataDumpHeader: this.dataDumpHeader,
                                dataDumpRows: this.dataDumpRows,
                                dataRowCount: this.dataRowCount
                            }}
                            // starting TOI level for aggregation
                            toiAggStart={this.props.toiAggStart}
                            {...this.props} 
                        />
                }
            </div>
        );
    }
}

/* -----------------------------------------------------------------------------
exporting
----------------------------------------------------------------------------- */
export default QuantityPanel;
// react
import { useEffect, useState } from 'react';
// sub components
import Spinner from 'components/Spinner/Spinner';
import ModalDataDownload from 'components/ModalDataDownload/ModalDataDownload';
// internal libraries
import { loadModels3D } from 'components/Viewing/Viewer/ViewerHelpers';
import { getKeys } from 'utils/generalJS';
import { isNumeric, addArrayToHashmap } from 'utils/generalJS'
import { formatViewName, formatViewSetName } from 'utils/formatting';
// external libraries
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@material-ui/core';
import { Paper } from '@material-ui/core';
// styling
import 'components/Viewing/ControlPanel/Info/DesignOptions.scss';


const DesignOptions = (props) => {
    

    /* -------------------------------------------------------------------------
    states and props
    ------------------------------------------------------------------------- */
    const {
        type,
        viewables3D,
        currElevation, setCurrElevation,
        elevations, setElevations,
        canLoadModels3D, setCanLoadModels3D,
    } = props;

    // modal control
    const [showModal, setShowModal] = useState(false);
    // from a view's data.name property, retrieve full view object
    const [nameToFullView, setNameToFullView] = useState({});
    // from the elevation code (e.g. CR), retrieve set of corresponding views
    const [elevationToViewSet, setElevationToViewSet] = useState({});
    // list of views selected in checkboxes
    const [selected, setSelected] = useState([]);
    // all views
    const [allViews, setAllViews] = useState([]);
    // all elevation
    const [everything, setEverything] = useState([]);
    // default views
    const [defaultViews, setDefaultViews] = useState([]);
    // data for the views' checkboxes section
    const [viewGroupsData, setVviewGroupsData] = useState(null);    

    /* -------------------------------------------------------------------------
    helper functions
    ------------------------------------------------------------------------- */
    // from the radio button click event, set current elevation to the 
    // value of the radio button
    const handleElevationChange = (e) => setCurrElevation(e.target.value);

    const startModal = async () => {
        setShowModal(true);
        setCanLoadModels3D(false);
        await loadModels3D(props, everything);
        setCanLoadModels3D(true);
    }

    // assign views into group according to the token inside //...//
    // e.g: A30L (CR) - LCO 01 OPT - Lookout /1/ is group 1
    const organizeViews = () => {
        // retrieve array of views with current elevation code
        let relevant = elevationToViewSet[currElevation];
        if (!relevant || relevant.length === 0) {
            console.log('Error: no views found for current elevation');
            return;
        };

        const _allViews = [];

        let viewGroups = [];

        let viewGroupsHM = [];
        let viewGroupsUncategorized = [];
        let regex = /[\/]+([A-Z]|[a-z]|[0-9])+[\/]+/;
        let viewGroupsNumbers = [];
        let viewGroupsNumbersLookup = {};
        let viewGroupsLetters = [];
        let viewGroupsLettersLookup = {};

        for (const view of relevant) {
            const { name: viewName } = view.data;

            // update the all-views array
            _allViews.push(viewName);

            // console.log('DEBUG - view name is ' + viewName);
            if (regex.test(viewName)) {
                /* 
                extract the group. match() returns something like this:
                [
                    '/M/',
                    index: 24,
                    input: 'A30L (CR) - House Style /M/ ',
                    groups: undefined
                ]
                we want to get the first elem which is the matched part.
                */ 
                let groupName = viewName.match(regex)[0].replaceAll('/', '').trim();
                
                if (groupName === 'H' || groupName === 'M') {
                    viewGroupsHM.push(viewName);
                } else if (isNumeric(groupName)) {
                    addArrayToHashmap(groupName, viewName, viewGroupsNumbersLookup)
                } else {
                    addArrayToHashmap(groupName, viewName, viewGroupsLettersLookup)
                }
                continue;
            }
            else {
                // if nothing matches, add to uncategorized
                viewGroupsUncategorized.push(viewName);
            }
        }

        setAllViews(_allViews);

        // push all sorted groups into the main container for display
        for (let groupName in viewGroupsLettersLookup) {
            viewGroupsLetters.push(viewGroupsLettersLookup[groupName])
        }
        for (let groupName in viewGroupsNumbersLookup) {
            viewGroupsNumbers.push(viewGroupsNumbersLookup[groupName])
        }

        viewGroups = [viewGroupsHM, ...viewGroupsNumbers, 
            ...viewGroupsLetters, viewGroupsUncategorized];

        const nonEmptyViewGroups = viewGroups.filter(group => group.length > 0);

        setVviewGroupsData(nonEmptyViewGroups);
    }

    // add or remove selected value in checkbox to selected[] state
    const handleSelect = (e) => {
        // if the checkbox is checked, add the value to the selected list
        if (e.target.checked) setSelected([...selected, e.target.value]);
        // else, make a new copy, remove said value, then set selected to that copy
        else {
            let i = selected.indexOf(e.target.value);
            if (i === -1) return;
            let copy = Array.from(selected);
            copy.splice(i, 1);
            setSelected(copy);
        }
    };

    const switchViews = async () => {
        // Reset colour packages
        document.dispatchEvent(new CustomEvent('resetColourPackages'));
        // button will be temporarily disabled till models are fully loaded
        setCanLoadModels3D(false);
        try {
            await loadModels3D(props, selected.map((name) => nameToFullView[name]));
            setCanLoadModels3D(true);
        } catch(error) {
            throw new Error(`Unable to load a view: ${error.message}`)
        }
        
    };

    const loadElevation = (currElevation) => {
        if (currElevation === null) return;
        // Turn on /H/ and all STD views of elevation
        const views = elevationToViewSet[currElevation];
        const viewsToLoad = [];
        const _defaultViews = [];

        for (const view of views) {
            const { name: viewName } = view.data;
            if (viewName.includes('/H/') || viewName.includes('/M/') || viewName.includes(' STD ')) {
                viewsToLoad.push(viewName);
                _defaultViews.push(viewName);
            }
        }

        // if none of the views has H or M or STD, load the first view, 
        // whatever it is
        if (viewsToLoad.length < 1) {
            console.log('No view with H or M or STD found. Loading the 1st one...');
            viewsToLoad.push(views[0]);
            _defaultViews.push(views[0]);
        };
        setSelected(viewsToLoad);
        setDefaultViews(_defaultViews);

        (async () => {
            setCanLoadModels3D(false);
            await loadModels3D(props, viewsToLoad.map((name) => nameToFullView[name]));
            setCanLoadModels3D(true);
        })();

        // organize views
        organizeViews();
    }
    
    /* -------------------------------------------------------------------------
    hooks
    ------------------------------------------------------------------------- */
    useEffect(() => {
        // hashmap to lookup arrays of views associated with a certain 
        // elevation code
        const hashmapElevationToViewSet = {};
        const hashmapNameToFullView = {};

        const _everything = [];

        for (const view of viewables3D) {

            let { name: viewName, ViewSets: viewSetName } = view.data;

            // add this view of this elevation to list of everything
            if (!viewName.includes('New Construction')) _everything.push(view);


            // DEBUG - disable irrelevant views for now
            // if (!viewName.toLowerCase().includes('architecture')) continue;

            if (!viewSetName) continue;

            // if viewSetName is an array (for some reason ?!), pick the first elem
            if (viewSetName.constructor === Array) {
                viewSetName = viewSetName[0];
            };

            // skip the views without ViewSets property or ViewSets not have 'design'
            if (typeof viewSetName === 'string' && !viewSetName.toLowerCase().includes('design')) continue;
            
            // populate the hashmapElevationToViewSet
            addArrayToHashmap(viewSetName, view, hashmapElevationToViewSet)
            // populate the hashmapNameToFullView
            hashmapNameToFullView[viewName] = view;
        }

        setEverything(_everything);

        const allElevationCodes = getKeys(hashmapElevationToViewSet);
        
        // if no elevation code at all, return
        if (allElevationCodes.length < 1) {
            console.log('Error: none of the views has a ViewSets property');
            return;
        };

        // sort view sets alphabetically
        allElevationCodes.sort();
        
        // pass the list of elevation codes to parent
        setElevations(allElevationCodes);

        // pick an initial elevation on behalf of parent
        setCurrElevation(allElevationCodes[0]);

        // update internal states
        setElevationToViewSet(hashmapElevationToViewSet);
        setNameToFullView(hashmapNameToFullView);
    }, [viewables3D]);

    useEffect(() => {
        loadElevation(currElevation);
    }, [currElevation]);

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    return (
        <div className='page-loose'>
            {/* modal for download option */}
            <ModalDataDownload
                showModal={showModal}
                setShowModal={setShowModal}
                canLoadModels3D={canLoadModels3D}
                currElevation={currElevation}
                loadElevation={loadElevation}
            />

            <div className='page-loose'>
                <h1>Design Options</h1>
            </div>
            <div>
                <h3>View Sets</h3>
                
                {/* radio buttons for elevation selection */}
                {currElevation === null ? <Spinner/> : 
                (<div>
                    <div className='horizontal-link'>
                        <div className='horizontal-link__item' onClick={startModal}>
                            Download ALL model data
                        </div>
                    </div>
                {elevations.map((el) =>
                    <div key={uuidv4()}>
                        <input
                            type='radio'
                            key={uuidv4()}
                            id={`DesignOptions-radio-${el}`}
                            checked={currElevation === el}
                            onChange={handleElevationChange}
                            value={el}
                            disabled={!canLoadModels3D}
                        />
                        <label htmlFor={`DesignOptions-radio-${el}`}>{formatViewSetName(el)}</label>
                    </div>
                )}
                </div>)}
                
                <h3>Views</h3>
                
                <Button
                    variant='contained' color={`${canLoadModels3D ? 'primary' : 'secondary'}`} fullWidth
                    onClick={switchViews}
                    disabled={!canLoadModels3D || selected.length === 0}
                >
                    {type === 'CON' ? null : !canLoadModels3D ? 
                        'Pending'
                        // <Spinner size={'20px'} thickness={3} /> 
                        : 
                        'Load Selected Views'
                    }
                </Button>

                <div className='horizontal-link'>
                    <div className='horizontal-link__item' onClick={() => {setSelected(allViews)}}>Select all</div>
                    <div className='horizontal-link__item' onClick={() => {setSelected([])}}>Unselect all</div>
                    <div className='horizontal-link__item' onClick={() => {setSelected(defaultViews)}}>Reset</div>
                </div>
                
                {/* checkboxes for view groups */}
                {viewGroupsData !== null ? 
                    viewGroupsData.map((group) =>
                        <Paper key={uuidv4()} className='DesignOptions-viewGroup' style={{ backgroundColor: 'var(--theme-grey)' }}>
                            {group.map((viewName) =>
                                <div key={uuidv4()}>
                                    <div className='DesignOptions-viewGroup-checkbox'>
                                        <label htmlFor={`DesignOptions-viewGroupItem-${viewName}`}>
                                            <input
                                                type='checkbox'
                                                id={`DesignOptions-viewGroupItem-${viewName}`}
                                                checked={selected.includes(viewName)}
                                                onChange={handleSelect}
                                                value={viewName}
                                            />
                                            <span>{formatViewName(viewName)}</span>
                                        </label>
                                    </div>
                                </div>
                            )}
                        </Paper>
                    ) : 
                    <div>No views available for this elevation</div> }
            </div>
        </div>
    );
};

/* -----------------------------------------------------------------------------
exports
----------------------------------------------------------------------------- */
export default DesignOptions;
import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, useParams, useRouteMatch } from 'react-router-dom';
import { Button, TableCell, TableRow } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { v4 as uuidv4 } from 'uuid';
import Navbar from '../Navbar/Navbar';
import { formatSpace } from 'utils/formatting';

// fix for old mui-table version
const oldRender = TableCell.render
TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

/* -----------------------------------------------------------------------------
main component
----------------------------------------------------------------------------- */
const SelectModel = ({ sharePointData, powerBIURLs }) => {
    const { type, project } = useParams();
    const params = new URLSearchParams(window.location.search);
    const projectDisplayName = params.get('project_display_name');
    const category = type.toLowerCase();
    // check if dashboard link exists
    const potentialLink = powerBIURLs[project];
    const hasDashboardLink = potentialLink != '' && potentialLink != null && potentialLink != undefined;

    // Do not convert project to lower case
    var { url } = useRouteMatch();

    if (category !== 'pre' && category !== 'con') {
        let redirUrl = url.split('/').slice(0, -2).join('/');
        return <Redirect to={redirUrl} />;
    }

    if (category === 'pre') {
        sharePointData = sharePointData[0];
    }

    if (category === 'con') {
        sharePointData = sharePointData[1];
    }

    var projectNames = Object.keys(sharePointData);
    if (!projectNames.includes(project)) {
        let redirUrl = url.split('/').slice(0, -1).join('/');
        return <Redirect to={redirUrl} />;
    }

    var phaseNames = Object.keys(sharePointData[project])

    const [tableColumns, tableData, tableOptions] = configureTable(category, sharePointData, url, project, phaseNames, projectDisplayName);

    return (
        
        <div className='page centered-column'>
            <Navbar
                crumbs={[
                    {
                        link: '/view',
                        text: 'HOME'
                    },
                    {
                        link: `/view/${category}`,
                        text: `${category === 'pre' ? 'DESIGN' : 'CONSTRUCTION'}`
                    },
                    {
                        link: null,
                        text: `${projectDisplayName !== null ? formatSpace(projectDisplayName) : project}`
                    }
                ]}
                dashboardLink={hasDashboardLink ? `/view/pre/${project}/dashboard` : null}
            />
            <div className='page-loose overflow centered-row'>
                <div>
                    <MUIDataTable
                        title={
                            "Models"
                        }
                        data={tableData}
                        columns={tableColumns}
                        options={tableOptions}
                    />
                </div>
            </div>
        </div>
    );
};

/* -----------------------------------------------------------------------------
helper functions
----------------------------------------------------------------------------- */
const configureTable = (category, sharePointData, url, project, phaseNames, projectDisplayName) => {
    var tableColumns;

    if (category === 'pre') {
        tableColumns = [
            'Type',
            'Model',
            'Name',
            'Mirrored'
        ];
    }

    if (category === 'con') {
        tableColumns = [
            'Phase',
            'Model',
            'Lot',
            'Elevation',
            'Mirrored'
        ];
    }

    var tableData = [];

    for (let phase of phaseNames) {
        let obj = sharePointData[project][phase];
        for (let key in obj) {
            let row = [phase];

            if (category === 'pre') {
                // both display name and original project name, separated by @@@
                row.push(`${obj[key].modelDisplayName}@@@${key}`);
                row.push(obj[key].name);
                row.push(obj[key].mirrored === 'STD' ? 'No' : 'Yes');
            }

            if (category === 'con') {
                row.push(obj[key].model);
                row.push(key);
                row.push(obj[key].elevation);
                row.push(obj[key].mirrored === 'STD' ? 'No' : 'Yes');
            }

            tableData.push(row);
        }
    }

    const tableOptions = {
        download: false,
        print: false,
        selectableRows: false,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 50, 100],
        tableBodyHeight: '100%',
        tableBodyMaxHeight: '600px',
        customRowRender: (rowData) => {
            return makeCustomTableCell(rowData, url, category, projectDisplayName);
        }
    };

    return [tableColumns, tableData, tableOptions];
};

const makeCustomTableCell = (rowData, url, category, projectDisplayName) => {

    const tableCells = rowData.map((el, i, arr) => {
        if ((i === 1 && category === 'pre') || (i === 2 && category === 'con')) {

            let formattedURL;
            let modelDisplayName = el;
            let modelTechnicalName = el;

            if (category === 'pre') {
                let bothNames = el.split('@@@');
                modelDisplayName = bothNames[0];
                modelTechnicalName = bothNames[1];
            }
            // remove accidental double slash: when user paste in a URL with 
            // the ending forward slash, the slash we auto-add will not be needed
            formattedURL = `${url}/${arr[0]}/${modelTechnicalName}?project_display_name=${projectDisplayName}&model_display_name=${modelDisplayName}`.replace('//', '/')

            return (
                <TableCell key={uuidv4()}>
                    <Link to={formattedURL} style={{ textDecoration: "none" }}>
                        <Button color="primary" variant="contained" style={{ width: "100%" }}>
                            {modelDisplayName}
                        </Button>
                    </Link>
                </TableCell>
            );
        }
        return (
            <TableCell key={uuidv4()}>
                {el}
            </TableCell>
        );
    });
    return (
        <TableRow key={uuidv4()}>
            {tableCells}
        </TableRow>
    );
};

SelectModel.propTypes = {
    sharePointData: PropTypes.array
}

export default SelectModel;
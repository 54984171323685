// react
import React from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// sub components
import BrandButton from 'components/common/BrandButton/BrandButton';
// env vars
import config from 'config';
// styling
import chevron from 'assets/icons/chevron.svg';
import 'components/LoginButton/LoginButton.scss';

function LoginButton({ loggedIn }) {

    /**
     * ask the backend to construct the complete Forge login link for us
     * Forge will verify the backend app's ID before asking user to grant such 
     * app permissions to access their info
     */
    const handleLogin = () => {
        fetch(`${config.REACT_APP_BACKEND_URL}/api/auth/login`, {
            // credentials: 'include'
            credentials: 'same-origin'
        })
        .then(res => res.json())
        .then((res) => {
            // constructed link received, going there now to login
            window.location.href = res['loginURL'];
        });
    };

    const generateButton = () => {
        if (loggedIn) {
            return (
                <Link to={'/view'} className='button-link'>
                    <BrandButton 
                    text={'ENTER'}
                    iconAfter={chevron}
                    /> 
                </Link>
            ) 
        } else {
            return (
                <BrandButton 
                    text={'LOGIN'}
                    iconAfter={chevron}
                    actionFunction={handleLogin}
                />
            )
        }
    }

    return generateButton();
}

LoginButton.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
}

export default LoginButton;
// require('dotenv').config();

const config = {
    
    // normal workflow, for deployment
    REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    // REACT_APP_PDF_KEY: process.env.REACT_APP_PDF_KEY,
    
    // hardcoded value, for storybook, which cannot use 'fs' to read files
    // REACT_APP_BACKEND_URL: 'http://localhost:9000',
    REACT_APP_PDF_KEY: 'lqqo26QNiPJQqQWLM3xr',
}

export default config;
require('dotenv').config();

/**
 * return a boolean indicating whether user has signed in or not.
 * try to ask the backend for the public token; if it exists, user is logged in.
 * if not, isLoggedIn = false.
 * @returns {Promise<Boolean>}
 */
const isLoggedIn = async () => {
    console.log('checking if user is logged in...');
    let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/token`, {
        credentials: 'include'
    });
    if (response.status === 200) {
        
        // console.log(`debug - response is `);
        // console.log(response);

        console.log('user logged in!');
        // let data = await response.json();
        // console.log(`response from isLoggedIn is `)
        // console.log(data);
        return true;
    } else {
        console.log('user not logged in');
        return false;
    }
};

export default isLoggedIn;
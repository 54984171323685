import { useEffect, useState } from 'react';
// external libraries
import { v4 as uuidv4 } from 'uuid';
// sub component
import BrandButton from 'components/common/BrandButton/BrandButton';
// styling
import 'components/DataDump/DataDump.scss';

export default function DataDump({forgeData, sharePointData}) {

    const [dataRows, setDataRows] = useState([]);
    const [revitFilesList, setRevitFilesList] = useState({});

    // check revitFilesList from backend
    useEffect(() => {}, []);

    useEffect(() => {
        // design project list
        const designProjectsList = Object.keys(sharePointData[0]);

        const newData = [];
        Object.keys(forgeData).forEach(key => {
            if (designProjectsList.indexOf(key) >= 0) {
                const newRow = [key];
                newData.push(newRow);
            }
            
        });
        setDataRows(newData);
    }, [forgeData, sharePointData]);

    useEffect(async () => {
        
        // note: this also exists in selectLoading.js. to refactor
        const fetchData = async (projectURL, projectId) => {
            const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/fetchProjectFiles?` + new URLSearchParams({ projectURL, projectId }).toString();
            const singleProjectData = await fetch(url, {
                credentials: 'include'
            })
            .then(res => res.json())
            .catch((error) => {
                console.log(error);
                return new Error(`Error while fetching PDF files for the project`);
            });
            
            if (singleProjectData && Object.keys(singleProjectData).length > 0) {
                return singleProjectData
            } else return null;
        }


        // before modifying, clone it

        // test - if data valid - first row, first elem (project name)
        const project = dataRows[0][0];

        if (project) {
            
            // fetch data
            const projectURL = forgeData[project]['links']['self']['href'];
            const projectId = forgeData[project]['id'];
            const singleProjectData = await fetchData(projectURL, projectId);
            if (singleProjectData) {
                const curDate = Date.now();
                console.log(singleProjectData);
            }
        }
    }, [dataRows])

    return (
        <div className='datadump'>
            <div className='datadump__button-container'>
                <BrandButton
                    text='Fetch new data'
                />
            </div>
            <div className='datadump__table'>
                <div className='datadump__table-header'>
                    <div className='datadump__table-col-1'>Project</div>
                    <div className='datadump__table-col-2'>File Name</div>
                    <div className='datadump__table-col-3'>Last Fetched</div>
                </div>
                <div className='datadump__table-body'>
                    {dataRows.map(row => (
                        <div key={uuidv4()} className='datadump__table-row'>
                            <div className='datadump__table-col-1'>{row[0]}</div>
                            <div className='datadump__table-col-2'>{row[1]}</div>
                            <div className='datadump__table-col-3'>{row[2]}</div>
                        </div>
                    ))}    
                </div>
            </div>
        </div>
    )
    }

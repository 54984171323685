/***
 * React component to handle model loading.
 *
 * When user selects model to load, page is redirected here.
 * Presents a loading icon while model urn is being searched.
 * Redirects upon finish.
 */
// utils
import 'dotenv/config';
import PropTypes from 'prop-types';
// react
import { useEffect, useState } from 'react';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// store
import { updateViewingData } from 'store/slices/selectSlice';
// internal libraries
import useFetch from 'utils/useFetch';
import { gm, nodeIdToUrn } from 'utils/datamanagement';
import { prettyPrintMinutes } from 'utils/generalJS';
// sub components
import Spinner from '../Spinner/Spinner';
import LoadingStatus from 'components/LoadingStatus/LoadingStatus';
import Viewing from '../Viewing/Viewing';
// external libraries
import { useSnackbar } from 'notistack';


/**
 * receives list of models, fetch 1 of them, then pass to <Viewing> for display
 * @param {array} sharePointData an array of 2 items: 1st is list of DESIGN 
 * models and 2nd is list of CONSTRUCTION models
 * @returns {Component} the <Viewing> component
 */
const SelectLoading = ({ sharePointData, forgeData }) => {

    /* -------------------------------------------------------------------------
    states & props
    ------------------------------------------------------------------------- */
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [urn, setUrn] = useState(null);
    const [model, setModel] = useState(null);
    const [modelDisplayName, setModelDisplayName] = useState(null);
    const [displayName, setDisplayName] = useState(null);
    const [projectDisplayName, setProjectDisplayName] = useState(null);
    const [mirrored, setMirrored] = useState(null);
    const [colourPackage, setColourPackage] = useState(null);
    const [projectFiles, setProjectFiles] = useState(null);
    const [toiAggStart, setToiAggStart] = useState('TOI3');

    // for loading animation
    const timeOutSeconds = 20; // will display error if unable to load in this time
    const [loadingMessage, setLoadingMessage] = useState(
        'Loading and caching data...');
    const [loadingError, setLoadingError] = useState(false);

    const { url } = useRouteMatch();
    // URL for this component: `${path}/:type/:project/:phase/:key`
    // example URL (Design): /pre/Ottawa Detached Product/SINGLES/B30L
    var { type, project, phase, key } = useParams();
    type = type.toLowerCase();
    let selectData = null;

    /* -------------------------------------------------------------------------
    hooks
    ------------------------------------------------------------------------- */
    // fetch project's PDF files on load
    useEffect(async () => {

        const fetchData = async (projectURL, projectId) => {
            const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/fetchProjectFiles?` + new URLSearchParams({ projectURL, projectId }).toString();
            const singleProjectData = await fetch(url, {
                credentials: 'include'
            })
            .then(res => res.json())
            .catch((error) => {
                console.log(error);
                return new Error(`Error while fetching PDF files for the project`);
            });
            
            if (singleProjectData && Object.keys(singleProjectData).length > 0) {
                return singleProjectData
            } else return null;
        }

        const projectURL = forgeData[project]['links']['self']['href'];
        const projectId = forgeData[project]['id'];
        
        const locallyStored = JSON.parse(localStorage.getItem(`cachedProjectFilesData`));
        
        // cache object present
        if (locallyStored) {
            // projectId exists in cache
            if (projectId in locallyStored) {

                const storedTime = new Date(locallyStored[projectId]['timestamp']);
                const now = new Date();
                const diff = Math.abs(now.getTime() - storedTime.getTime());
                const minutes = Math.floor((diff/1000)/60);
                // fetch new data if more than 60 mins
                if (minutes >= 60) {
                    console.log(`data is old (set ${prettyPrintMinutes(minutes)}). Fetching new data...`);
                    const singleProjectData = await fetchData(projectURL, projectId);

                    // only proceed if data is successfully fetched
                    if (singleProjectData) {
                        setProjectFiles(singleProjectData);
                        // create a copy
                        const cachedProjectFilesData = Object.assign(locallyStored);
                        // overwrite
                        cachedProjectFilesData[projectId] = {
                            data: singleProjectData,
                            timestamp: new Date(),
                        };

                        // disable for now
                        localStorage.setItem('cachedProjectFilesData', JSON.stringify(cachedProjectFilesData));
                    } else {
                        // TODO
                        console.log(`data fetch interrupted. please refresh...`);
                    }
                    
                } else {
                    // check if data is not corrupted
                    if (locallyStored[projectId]['data']) {
                        console.log(`returning cachedProjectFilesData set ${prettyPrintMinutes(minutes)}...`);
                        setProjectFiles(locallyStored[projectId]['data']);
                    } else {
                        console.log(`cached data fetched ${prettyPrintMinutes(minutes)} corrupted. please refresh...`);
                        alert(`Cached data corrupted. Please reload this page to fetch fresh data`);
                        // remove corrupted project data and re-set localstorage
                        const cacheCopy = Object.assign(locallyStored);
                        delete cacheCopy[projectId];
                        localStorage.setItem('cachedProjectFilesData', JSON.stringify(cacheCopy));
                    }
                }
            } 
            // projectId not exist in cache
            else {
                console.log(`Fetching new project data...`);
                const singleProjectData = await fetchData(projectURL, projectId);

                // only proceed if data is successfully fetched
                if (singleProjectData) {
                    setProjectFiles(singleProjectData);
                    const cachedProjectFilesData = {
                        [projectId]: {
                            data: singleProjectData,
                            timestamp: new Date()
                        },
                        ...locallyStored
                    }
                    localStorage.setItem('cachedProjectFilesData', JSON.stringify(cachedProjectFilesData));
                } else {
                    // TODO
                    console.log(`data fetch interrupted. please refresh...`);
                }
                
            }
        }
        // cache object not present --> create new one
        else {
            const singleProjectData = await fetchData(projectURL, projectId);

            // only proceed if data is successfully fetched
            if (singleProjectData) {
                setProjectFiles(singleProjectData);
                const cachedProjectFilesData = {
                    [projectId]: {
                        data: singleProjectData,
                        timestamp: new Date()
                    }
                }
                localStorage.setItem('cachedProjectFilesData', JSON.stringify(cachedProjectFilesData));
            } else {
                // TODO
                console.log(`data fetch interrupted. please refresh...`);
            }

        }
    }, [project])


    useEffect(() => {

        try {
            if (type === 'pre') {
                selectData = sharePointData[0];
            }
            if (type === 'con') {
                selectData = sharePointData[1];
            }

            setDisplayName(getModelName(type, key, selectData[project][phase]));
            setMirrored(selectData[project][phase][key].mirrored);
            setProjectDisplayName(selectData[project][phase][key].projectDisplayName);
            setModelDisplayName(selectData[project][phase][key].modelDisplayName);
            setColourPackage(selectData[project][phase][key].colourPackage);
            setToiAggStart(selectData[project][phase][key].toiAggStart);
        } catch (err) {
            console.log(`SelectLoading: unable to process selectData`);
            setUrn('');
        }
    }, [selectData]);

    if (urn === null) {
        return (
            <div className='page centered-row centered-column'>
                <Spinner />
                <div className='loading-text'>Loading model information...</div>
                {
                    (displayName !== null && mirrored !== null) ?
                        <SelectLoadingFetch
                            project={project}
                            displayName={displayName}
                            mirrored={mirrored}
                            setUrn={setUrn}
                            setModel={setModel}
                        /> :
                        null
                }
            </div>
        );
    } else if (urn === '') {
        let message = 'Model not found.';
        if (model && model.detailed && model.detailed.message) message = model.detailed.message;
        if (model && model.detailed && model.detailed.err) console.error(model.detailed.err);
        enqueueSnackbar(message, {
            variant: 'error',
        });
        let redirUrl = url.split('/').slice(0, -2).join('/');
        return <Redirect to={redirUrl} />;
    } else if (projectFiles === null) {
        return (
            <div className='page centered-row centered-column'>
                <Spinner/>
                <div className='loading-text'>Loading project files...</div>
            </div>)
    }
    else {
        if (type === 'pre') {
            selectData = sharePointData[0];
        }
        if (type === 'con') {
            selectData = sharePointData[1];
        }

        let viewingData = formatViewingData(
            type, project, projectDisplayName, modelDisplayName, phase, 
            displayName, mirrored, urn, key, selectData[project][phase], url, 
            model, colourPackage, toiAggStart);

        dispatch(updateViewingData(viewingData));
        return (
            <Viewing
                {...viewingData}
                projectFiles={projectFiles}
            />
        )
    }
};

/* -----------------------------------------------------------------------------
helper components
----------------------------------------------------------------------------- */
/**
 * dummy component to be able to use useFetch conditionally
 * fetch a model using 3 properties: project, displayName, mirrored and call 
 * the state setter supplied to set model and URN in parent (SelectLoading)  
 * @param {string} project the project name associated with the model
 * @param {string} displayName the display name associated with the model
 * @param {string} mirrored the mirror flag associated with the model
 * @param {function} setUrn state setter from parent (SelectLoading)
 * @param {function} setModel state setter from parent (SelectLoading)
 * @returns none
 */
 const SelectLoadingFetch = ({ project, displayName, mirrored, setUrn, setModel }) => {

    const model = useFetch(`${process.env.REACT_APP_BACKEND_URL}/api/forge/project/${project}/model/${displayName}?mirrored=${mirrored}`);

    useEffect(() => {

        if (model === null) return;

        setModel(model);

        const { status, urn } = model;
        if (!status) {
            setUrn('');
        } else if (urn !== null) {
            setUrn(urn);
        } else {
            /***
             * Just in case urn is null (see comment black in datamanagement.js route)
             * we keep old data management system as a fallback.
             */
            const fallback = async () => {
                let nodeId = null;
                try {
                    nodeId = await gm('#', project, displayName);
                    setUrn(nodeIdToUrn(nodeId)[0]);
                } catch (err) {
                    // Model not found
                    setUrn('');
                    console.error(`unable to fetch model, error: ${err}`);
                }
            };
            fallback();
        }
    }, [model]);

    return <div></div>;
};


/* -----------------------------------------------------------------------------
helper functions
----------------------------------------------------------------------------- */
/**
 * Extract model name from obj (usually selectData[project][phase]) depending on type
 * @param {string} type Either 'pre' or 'con'
 * @param {string} key
 * @param {object} obj
 * @returns {string} Model displayName
 */
const getModelName = (type, key, obj) => {
    if (type === 'pre') return key;
    if (type === 'con') return obj[key].model;
    return null;
}

/**
 * Convert parameters to props object which Viewer.js takes in
 * @param {string} type
 * @param {string} project
 * @param {string} phase
 * @param {string} displayName
 * @param {string} urn
 * @param {string} key
 * @param {object} obj
 * @returns {object} formatted data
 */
const formatViewingData = (
    type, project, projectDisplayName, modelDisplayName, phase, 
    displayName, mirrored, urn, key, obj, url, model, 
    colourPackage, toiAggStart) => {
        
    let lot = '';
    let name = '';
    let elevation = '';

    if (type === 'pre') {
        name = obj[key].name;
    }
    if (type === 'con') {
        lot = key;
        elevation = obj[key].elevation;
    }

    return {
        urn,
        type: type.toUpperCase(),
        project,
        modelKey: key,
        data: {
            projectDisplayName,
            modelDisplayName,
            phase,
            colourPackage,
            model: displayName,
            lot,
            name,
            elevation,
            mirrored,
            url,
            toiAggStart,
            detailed: {
                model,
            }
        }
    };
};

/* -----------------------------------------------------------------------------
prop types and exports
----------------------------------------------------------------------------- */
SelectLoading.propTypes = {
    selectData: PropTypes.array
}

export default SelectLoading;

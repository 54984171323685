import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'select',
    initialState: {
        viewingData: {
            urn: null,
            type: null,
            data: null
        },
        PDFDocURN: null,
        PDFDocName: null,
        PDFLoading: false,
    },
    reducers: {
        updateViewingData: (state, action) => {
            state.viewingData = action.payload
        },
        // another way of writing the reducer, here for variety's sake
        updatePDFDocURN: (state, action) => {
            return {
                ...state,
                PDFDocURN: action.payload
            }
        },
        updatePDFDocName: (state, action) => {
            state.PDFDocName = action.payload
        },
        updatePDFLoading: (state, action) => {
            state.PDFLoading = action.payload
        },
    },
});

export const { 
    updateViewingData, updatePDFDocURN, updatePDFDocName, updatePDFLoading 
} = slice.actions;

export const selectViewingData = (state) => state.select.viewingData;
export const selectPDFDocURN = (state) => state.select.PDFDocURN;
export const selectPDFDocName = (state) => state.select.PDFDocName;
export const selectPDFLoading = (state) => state.select.PDFLoading;

export default slice.reducer;
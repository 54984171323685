import { useState, useEffect } from 'react';
import { launchViewer3D } from './ViewerHelpers';
import './Viewer.scss';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { formatSpace } from 'utils/formatting';

const Viewer3D = (props) => {
    
    /* -------------------------------------------------------------------------
    props, states and hooks
    ------------------------------------------------------------------------- */
    const [category, setCategory] = useState(null);
    const [project, setProject] = useState(null);
    const [model, setModel] = useState(null);
    const [phase, setPhase] = useState(null);
    const params = new URLSearchParams(window.location.search);
    const projectDisplayName = params.get('project_display_name');
    const modelDisplayName = params.get('model_display_name');

    useEffect(() => {

        const { href } = window.location;
        // href is http(s)://domain/...
        const arr = href.split('/');
        setCategory(arr[4]);
        setProject(arr[5]);
        setPhase(arr[6]);
        setModel(arr[arr.length - 1]);
        
        try {
            (async () => {
                await launchViewer3D(props);
            })();
        } catch (err) {
            console.error(err);
        }
    }, []);

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    return (
        <div className='viewer-wrapper'>
            <BreadCrumb
                crumbs={[
                    {
                        link: `/view/${category}`,
                        text: `${category === 'pre' ? 'DESIGN' : 'CONSTRUCTION'}`
                    },
                    {
                        link: `/view/${category}/${project}?project_display_name=${projectDisplayName}`,
                        text: `${formatSpace(projectDisplayName)}`
                    },
                    {
                        link: null,
                        text: `${formatSpace(phase)} - ${formatSpace(modelDisplayName)}`
                    },
                ]}
            />
            <div className='viewer'>
                <div id='viewer3D'></div>
            </div>
        </div>
    );
};

export default Viewer3D;
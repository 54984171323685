/***
 * React component to handle 'pre' vs 'con' type selection.
 *
 * Upon selection, redirect to project selection.
 */
import { Link, useRouteMatch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Navbar from '../Navbar/Navbar';

import './SelectType.css';

// TODO: handle images, the way we're doing it right now is ugly

const SelectType = () => {
    var { url } = useRouteMatch();
    return (
        <div className="selection page centered-column">
            <Navbar
                crumbs={[
                    {
                        link: '/view',
                        text: 'HOME'
                    },
                ]} 
            />
            <Grid container style={{ width: "100%", height: "100%" }}>
                <Grid item xs='6' style={{ height: '100%' }}>
                    <Link to={`${url}/pre`} className="selection-half-panel centered-column" style={{ backgroundImage: "url(/design_image.svg)" }}>
                        <div
                            className="selection-button centered-column">
                            <h2>
                                DESIGN
                            </h2>
                        </div>
                    </Link>
                </Grid>
                <Grid item xs='6' style={{ height: '100%' }}>
                    <Link to={`${url}/con`} className="selection-half-panel centered-column" style={{ backgroundImage: "url(/con_image.svg)" }}>
                        <div
                            className="selection-button centered-column">
                            <h2>
                                CONSTRUCTION
                            </h2>
                        </div>
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
};

export default SelectType;
// react
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
// external components
import Split from 'react-split';
import { ErrorBoundary } from 'react-error-boundary';
// sub components
import Spinner from 'components/Spinner/Spinner';
import MasterHandler from 'components/errorHandlers/MasterHandler/MasterHandler';
import ControlPanel from './ControlPanel/ControlPanel';
import Viewer3D from './Viewer/Viewer3D';
import ViewerPDF from './Viewer/ViewerPDF';
// styling
import './Viewing.scss';


// We should not store viewers in a state
const viewer = {
    viewer3D: null,
    models3DMap: {},
    modelData3DMap: {},
};

// function ErrorFallback({error, resetErrorBoundary}) {
//     return (
//       <div role="alert">
//         <p>Something went wrong:</p>
//         <pre>{error.message}</pre>
//         <button onClick={resetErrorBoundary}>Try again</button>
//       </div>
//     )
// }
  
const Viewing = (props) => {
    
    /* -------------------------------------------------------------------------
    hooks and props
    ------------------------------------------------------------------------- */ 
    const { type, urn, modelKey, data, projectFiles } = props;
    
    // This is dying for a refactor, but it is tied to most of the codebase...
    const history = useHistory();
    const [currElevation, setCurrElevation] = useState(null);
    const [elevations, setElevations] = useState(null);
    const [document3D, setDocument3D] = useState(null);
    const [modelData3D, setModelData3D] = useState(null);
    const [viewables3D, setViewables3D] = useState(null);
    const [hasDesignOptions, setHasDesignOptions] = useState(null);
    const [currentModels3D, setCurrentModels3D] = useState(null);
    const [canLoadModels3D, setCanLoadModels3D] = useState(true); // false when buttons to load 3D models are disabled
    const [filters, setFilters] = useState(null);
    // UI controls
    const [full3D, setFull3D] = useState(false);
    const [hasPDF, setHasPDF] = useState(null);
    const [PDFsList, setPDFsList] = useState(null);

    const state = {
        // from props
        type, urn, data,
        // internal states
        viewer,
        currElevation,
        elevations,
        document3D,
        modelData3D,
        viewables3D,
        hasDesignOptions,
        currentModels3D,
        canLoadModels3D,
        filters,
    }

    const setState = {
        setCurrElevation,
        setElevations,
        setDocument3D,
        setModelData3D,
        setViewables3D,
        setHasDesignOptions,
        setCurrentModels3D,
        setCanLoadModels3D,
        setFilters
    };


    // filter list of PDF docs whenever projectFiles changes
    useEffect(() => {
        // first, check everything ready
        if (!type || !modelKey || !projectFiles) return;

        let PDFsToDsiplay = null;
        const category = type.toLowerCase() === 'pre' ? 'design' : 'construction';

        // fetch project files
        const PDFFiles = projectFiles['PDFFiles'];
        const commonPDFs = PDFFiles['common']; 
        const categoryFiles = PDFFiles[category];
        // sort categoryFiles alphabetically
        categoryFiles.sort((a,b)=> (a.fileName > b.fileName ? 1 : -1));

        if (category === 'construction') {
                                
            // key is [project].[lot] so let's split that [TODO]
            const relevantPDFs = categoryFiles.filter(item => (
                item['fileName'].toLowerCase().includes(modelKey.toLowerCase())
            ))

            PDFsToDsiplay = relevantPDFs;
            
            // fetch Detail Package & Construction Notes files from common
            const commonFiles = commonPDFs.filter(item => (
                item['fileName'].toLowerCase().includes('detail package') || item['fileName'].toLowerCase().includes('construction notes')
            ));
            PDFsToDsiplay = PDFsToDsiplay.concat(commonFiles);

            // need to get relevant files from Design here too
            const viewSetName = data['elevation'];
            const modelName = data['model'];
            const designDocKey = `${modelName}_${viewSetName}`;
            const designDocs = PDFFiles['design'].filter(item => (
                item['fileName'].toLowerCase().includes(designDocKey.toLowerCase())
            ));
            PDFsToDsiplay = PDFsToDsiplay.concat(designDocs);
            // sort PDF file names alphabetically
            PDFsToDsiplay.sort((a,b)=> (a.fileName > b.fileName ? 1 : -1));
            setPDFsList(PDFsToDsiplay);

        } else if (category === 'design') {            
            
            // key is model name
            const relevantPDFs = categoryFiles.filter(item => (
                item['fileName'].toLowerCase().includes(modelKey.toLowerCase())
            ))
            setPDFsList(relevantPDFs);
            // no filter for now
            setPDFsList(categoryFiles);
        } else {
            console.error(`Error: unrecognized category. Only PRE or CON.`);
        }
    }, [type, modelKey, projectFiles])

    // Cleanup - we should try to keep ALL viewer cleanups here
    useEffect(() => {
        return () => {
            try {
                (async () => {
                    if (viewer.viewer3D.viewer) viewer.viewer3D.viewer.tearDown();
                })();
            } catch (err) {
                console.warn(err);
            }

            viewer.viewer3D = null;
            viewer.models3DMap = {};
            viewer.modelData3DMap = {};
            viewer.PushPinExtensionHandle3D = null;
        }
    }, []);

    // attach full3D event listener to document
    useEffect(() => {
        const handleFull3D = (e) => {
            const { detail } = e;
            try {
                if (detail.status === true) setFull3D(true);
                if (detail.status === false) setFull3D(false);
            } catch (err) { }
        }

        document.addEventListener('full3D', handleFull3D);

        return () => document.removeEventListener('full3D', handleFull3D);
    }, []);

    // resize panels whenever full3D changes
    useEffect(() => {
        if (viewer.viewer3D !== null) viewer.viewer3D.viewer.resize();
    }, [full3D]);

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */ 
    const isAnyPropNull = Object.values(props).some((el) => el === null);
    if (isAnyPropNull) {
        history.push('/view');
        return (
            <div className='page centered-row centered-column'>
                <Spinner />
            </div>
        );
    }

    return (
        <ErrorBoundary
            // FallbackComponent={ErrorFallback}
            fallbackRender={props => {
                return <MasterHandler 
                        {...props} 
                        currElevation={currElevation} 
                        modelKey={modelKey}
                    />
            }}
            onReset={() => {
            // reset the state of your app so the error doesn't happen again
            }}
        >
            {/* main container */}
            <div className='Viewing page centered-row'>
                {/* sliding control panel on the left */}
                <ControlPanel
                    {...state} {...setState} {...props}
                    toiAggStart={data.toiAggStart}
                    setHasPDF={setHasPDF}
                    PDFsList={PDFsList}
                    setPDFsList={setPDFsList}
                    modelKey={modelKey}
                ></ControlPanel>
                
                {/* 
                splitscreen panel 
                expecting an array of children; passing 1 component will 
                result in error
                */}
                <Split
                    className={'split'}
                    sizes={(full3D === true) ? [100, 0] : [80, 20]}
                    minSize={10}
                    onDrag={() => {
                        if (viewer.viewer3D !== null) viewer.viewer3D.viewer.resize();
                    }}
                >
                    <Viewer3D {...state} {...setState} {...props}></Viewer3D>
                    <ViewerPDF hasPDF={hasPDF}></ViewerPDF>
                </Split>
            </div>
        </ErrorBoundary>
    );
};

export default Viewing;
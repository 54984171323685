// react
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment } from 'react';
// redux
import { 
    selectPDFLoading, selectPDFDocURN, 
    updatePDFLoading, updatePDFDocURN,
    updatePDFDocName
} from 'store/slices/selectSlice';
// styling
import './FileExplorer.scss';
// sub components
import ModalLoading from '../../../ModalLoading/ModalLoading';
// external libraries
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp'
import Alert from '@material-ui/lab/Alert';
import { formatViewSetName } from '../../../../utils/formatting';
import 'dotenv/config';


const FileExplorer = ({ setHasPDF, PDFsList, currElevation, modelKey, category }) => {
    
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    /* -------------------------------------------------------------------------
    props & states
    ------------------------------------------------------------------------- */

    const PDFLoading = useSelector(selectPDFLoading);
    const PDFDocURN = useSelector(selectPDFDocURN);

    // states
    const [content, setContent] = useState(null);
    const [filteredPDFs, setFilteredPDFs] = useState(null);

    /* -------------------------------------------------------------------------
    document list parameters
    ------------------------------------------------------------------------- */
    const columns = [
        // ID column
        {
            name: 'document_ID',
            label: 'ID',
            options: {
                display: 'excluded'
            }
        },
        // document name column
        {
            name: 'document_name',
            label: 'All Files',
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: '270px', maxWidth: '270px' } }),
                customBodyRender: (value, tableMeta) => {
                    const className = `document-item ${PDFDocURN === tableMeta.rowData[0] ? 'document-item--visited' : ''}`
                    return (
                        <div>
                            <div 
                                className={className}
                                onClick={() => {
                                    if (PDFDocURN === tableMeta.rowData[0]) return
                                    else {
                                        dispatch(updatePDFLoading(true));
                                        dispatch(updatePDFDocURN(tableMeta.rowData[0]));
                                        dispatch(updatePDFDocName(tableMeta.rowData[1]));
                                    }
                                }}
                            >
                                {value}
                            </div>
                        </div>
                    );
                }
            }
        }
    ];
    
    const options = {
        filter: false,
        search: true,
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 50, 100],
        download: false,
        print: false,
        viewColumns: false,
        customFooter: () => { return null; },
        customToolbarSelect: (selectedRows) => {
            return <Fragment>
                <IconButton style={{ marginRight: 20 }} onClick={() => {
                    for (let selectedRow of selectedRows.data) {
                        /*
                        example object from filteredPDFs:
                        {
                            "id": "https://developer.api.autodesk.com/data/v1/projects/
                                b.0c7c99cd-d276-45ca-b170-caf30f6c3ad2/items/
                                urn:adsk.wipprod:dm.lineage:x9aE-q4QRlebs6E1ZnbpEA",
                            "fileName": "CR - ACO_03 - Ground Floor PPO.pdf"
                        }
                        */ 
                        handleDownload(filteredPDFs[selectedRow.dataIndex]);
                    }
                }}>
                    <DownloadIcon />
                </IconButton>
            </Fragment>
        }
    };

    /* -------------------------------------------------------------------------
    hooks
    ------------------------------------------------------------------------- */
    
    useEffect(() => {
        // PDFFiles not initialized, skip
        if (filteredPDFs === null || filteredPDFs.length === 0) {
            dispatch(updatePDFDocURN(null));
            return;
        };

        dispatch(updatePDFDocURN(filteredPDFs[0]['id']));
        dispatch(updatePDFDocName(filteredPDFs[0]['fileName']));

        const data = filteredPDFs.map((item) => [item.id, item.fileName]);
        // set row content to be displayed
        setContent(data);
    }, [filteredPDFs]);


    useEffect(() => {
        // PDFFiles not initialized, skip
        if (!PDFsList || !modelKey || !category) return;
        if (PDFsList.length === 0) {
            setHasPDF(false);
            return;
        } else {
            setHasPDF(true);
            let filtered = PDFsList;
            // no need to filter construction category
            if (category.toLowerCase() === 'pre' && currElevation) {                
                filtered = PDFsList.filter(item => {
                    // new logic starting with sandbox prject: <model name>_<viewsetname>
                    const keyword = `${modelKey}_${formatViewSetName(currElevation)}`;
                    // console.log(`debug - keyword is ${keyword}`);
                    // console.log(`debug - item['fileName'] is ${item['fileName']}`);
                    // console.log(`debug - does ${item['fileName']} include ${keyword}? ${item['fileName'].includes(keyword)}`);
                    return item['fileName'].toLowerCase().includes(keyword.toLowerCase());
                });
            };
            
            setFilteredPDFs(filtered.length > 0 ? filtered : null);
        }
    }, [PDFsList, modelKey, currElevation, category]);

    /* -------------------------------------------------------------------------
    helper functions
    ------------------------------------------------------------------------- */

    const showAll = () => {
        setFilteredPDFs(PDFsList);
    }

    /**
     * 
     * @param {string} id URL of the item on ACC
     * @param {string} fileName displayed name of the item
     */
    const handleDownload = ({ id, fileName }) => {
        try {
            const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/forge/download?id=${id}`;
            fetch(endpoint, { credentials: 'include'}).then((res) => {
                if (!res.ok) {
                    enqueueSnackbar(`Download Panel: failed to download ${fileName}.`, {
                        variant: 'error',
                    });

                    console.error(`Failed to fetch ${endpoint}, with status ${res.status}`);
                    return;
                }

                return res.blob();
            })
                .then((blob) => {
                    // Download it
                    const url = URL.createObjectURL(blob);
                    // create a temporary anchor to 'click' it
                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = fileName;
                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);

                    URL.revokeObjectURL(url);
                });
        } catch (err) {
            enqueueSnackbar(`Download Panel: failed to fetch top-level folder.`, {
                variant: 'error',
            });
            console.error(err);
        }
    };

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    return (
        <div className='file-list'>
            <ModalLoading show={PDFLoading} message={'Loading PDF document...'} />
            <div className='page-loose centered-row centered-column file-list'>
                {(!filteredPDFs || !content) ? 
                    // no filtered content available
                    <Alert severity='info' style={{ width: '100%' }}>
                        {(!PDFsList || PDFsList.length < 1) ? 'No documents to download' : 
                            <div>
                                No PDF available for this selection, but there are files for others <a className='file-list__show-all' onClick={showAll}>Show All</a>
                            </div>
                        }
                    </Alert> : 
                    // filtered content available
                    (<div>
                        <Grid 
                            container direction='row' justifyContent='center' 
                            alignItems='center' spacing={1}
                        >
                        <MUIDataTable
                            data={content}
                            columns={columns}
                            options={options}
                        />
                        </Grid>
                    </div>)
                }
            </div>
        </div>
    );
};

/* -----------------------------------------------------------------------------
exporting
----------------------------------------------------------------------------- */
export default FileExplorer;
// react
import { useEffect, useState } from 'react'
import { Fragment } from 'react';
// sub components
import Spinner from '../../Spinner/Spinner'
// external components
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';
// external libraries
import { v4 as uuidv4 } from 'uuid';


const Autodesk = window.Autodesk;
const THREE = window.THREE;

const TOMToArr = (TOMstring) => {
    let split = TOMstring.split(' - ')
    return split.map(x => {
        let a = x.split(':');
        let b = (a[1] || '').split('_')
        return {
            title: a[0],
            value: Number(b[0]),
            unit: b[1]
        }
    });
}

/* QTY Tooltip Origin */
const registerQuantitiesTooltip = (viewer, setOpen, setSelectionData, setPosition, setCurDbId) => {
    let handleExit = () => {
        setOpen(false);
        setSelectionData([]);
        setCurDbId(null);
        setPosition({ x: 0, y: 0 });
    }
    /* QTY Tooltip Helper */
    const getSelectionCenterPosition = async (selections) => {
        let bounds = new THREE.Box3();
        for (let selection of selections) {
            const model = selection.model;
            const instanceTree = model.getData().instanceTree;
            const fragList = model.getFragmentList();
            for (let dbId of selection.dbIdArray) {
                await instanceTree.enumNodeFragments(dbId, (fragId) => {
                    let box = new THREE.Box3();
                    fragList.getWorldBounds(fragId, box);
                    bounds.union(box);
                }, true);
            }
        }
        return bounds.center();
    }

    viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, async (ev) => {
        if (ev.selections) {
            let centerPosition = await getSelectionCenterPosition(ev.selections);
            let selectionData = [];
            if (ev.selections.length === 0) {
                handleExit();
                return;
            }
            for (let selection of ev.selections) {
                if (selection.dbIdArray.length > 0) {
                    for (let dbId of selection.dbIdArray) {
                        selectionData.push(new Promise((resolve, reject) => {
                            selection.model.getProperties(dbId, dbIdData => {

                                let dbIdName = dbIdData.name;
                                let TOM = '';
                                let TOI1 = '';
                                let TOI2 = '';
                                let TOI3 = '';
                                let TOI4 = '';
                                let categoryName = '';
                                let ROOMID = '';
                                for (let property of dbIdData.properties) {
                                    let propValue = property.displayValue;
                                    switch (property.displayName) {
                                        case 'TOM':
                                            TOM = propValue;
                                            break;
                                        case 'TOI1':
                                            TOI1 = propValue;
                                            break;
                                        case 'TOI2':
                                            TOI2 = propValue;
                                            break;
                                        case 'TOI3':
                                            TOI3 = propValue;
                                            break;
                                        case 'TOI4':
                                            TOI4 = propValue;
                                            break;
                                        case 'Room ID':
                                            ROOMID = propValue;
                                            break;
                                        case 'Type Name':
                                            categoryName = propValue;
                                        default:
                                            break;
                                    }
                                }
                                // customize data for the tooltip here
                                resolve({
                                    dbId,
                                    TOM,
                                    categoryName,
                                    ROOMID,
                                    name: dbIdName,
                                    TOIArray: [TOI1, TOI2, TOI3, TOI4],
                                })
                            }, err => {
                                console.log(err)
                            })
                        }))
                    }
                } else {
                    handleExit();
                }
            }

            Promise.all(selectionData).then(selectionArray => {
                setOpen(true);
                
                // console.log(`DEBUG - selection data is `);
                // console.log(selectionArray);
                
                setSelectionData(selectionArray);
                setPosition(viewer.worldToClient(centerPosition));
            })

        } else {
            handleExit();
        }
    })
}

const QuantityTooltip = ({ modelData3D, viewer }) => {
    const [TOMlist, setTOMlist] = useState([]);
    const [curDbId, setCurDbId] = useState(null);
    const [curCatName, setCurCatName] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectionData, setSelectionData] = useState([]);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const makeTOMlist = () => {
        let tempTOMlist = [];
        let aggregatedTOMdata = {};

        if (selectionData.length === 1) {
            setCurDbId(selectionData[0]['dbId']);
            setCurCatName(selectionData[0]['categoryName']);
            let TOIarr = selectionData[0].TOIArray;

            tempTOMlist.push(
                <Fragment key={uuidv4()}>
                    {/* title is the TOI at the end of TOI chain */}
                    <Grid item xs={12} title={TOIarr[TOIarr.length - 1]}>
                        {TOIarr[TOIarr.length - 1].substring(0, 60) + (TOIarr[TOIarr.length - 1].length > 60 ? '...' : '')}
                    </Grid>
                    {/* TOI chain breadcrumb */}
                    <Grid item container xs={12}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' onClick={() => { }}>
                                {TOIarr[0]}
                            </Link>
                            <Link color='inherit' onClick={() => { }}>
                                {TOIarr[1]}
                            </Link>
                            <Typography color='textPrimary'>{TOIarr[2]}</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Fragment>

            )
            if (selectionData[0].ROOMID !== '') {
                tempTOMlist.push(
                    <Grid item xs={12} key={uuidv4()}>
                        <Typography color='textSecondary' variant='p'>
                            {'Room ID: ' + selectionData[0].ROOMID}
                        </Typography>
                    </Grid>
                )
            }
        }

        selectionData.forEach(selection => {
            let TOMdata = TOMToArr(selection.TOM);
            TOMdata.forEach(entry => {
                if (aggregatedTOMdata[entry.title]) {
                    aggregatedTOMdata[entry.title].value += entry.value;
                } else {
                    aggregatedTOMdata[entry.title] = entry;
                }
            })
        });

        for (let entryKey in aggregatedTOMdata) {
            let entry = aggregatedTOMdata[entryKey];
            tempTOMlist.push(
                <Grid item container xs={12} alignItems='center' key={uuidv4()}>
                    <Grid item xs={5}>
                        <p className='QTYTooltip-entry-title'>{entry.title}</p>
                    </Grid>
                    <Grid item xs={7}>
                        <p className='QTYTooltip-entry-value'>{`${entry.value.toFixed(2)} ${entry.unit}`}</p>
                    </Grid>
                </Grid>
            );
        }

        setTOMlist(tempTOMlist);
    }

    useEffect(() => {
        if (modelData3D) {
            let validViewer = viewer.viewer3D.viewer;
            registerQuantitiesTooltip(validViewer, setOpen, setSelectionData, setPosition, setCurDbId);
        }
    }, [modelData3D])

    useEffect(() => {
        makeTOMlist();
    }, [selectionData, position]);

    return !open ? null : (
        <Draggable>
            <div className='QTYTooltip' style={{ left: position.x, top: position.y, height: 'auto' }}>
                <div className='QTYTooltip__title-container'>
                    <h3>{`${curCatName ? curCatName : 'Unknown category'} (dbId: ${curDbId ? curDbId : 'N.A.'})`}</h3>
                    <IconButton onClick={() => { setOpen(false) }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Grid container spacing={0}>
                    {selectionData.length > 0 ? TOMlist : <Spinner />}
                </Grid>
            </div>
        </Draggable>
    )
}

export default QuantityTooltip;
import { v4 as uuidv4 } from 'uuid';
import { useState, Fragment, useEffect } from "react"
import { useSnackbar } from 'notistack';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import Spinner from "components/Spinner/Spinner";
import Alert from '@material-ui/lab/Alert';
import 'dotenv/config';
// const Autodesk = window.Autodesk;
const THREE = window.THREE;

/***
 * Specification
 *
 * model.getDocumentNode().guid(): {
 *      fragId: THREE.Material
 * }
 *
 * NOTE
 *
 * When calling NOP_VIEWER.model.getFragmentList().setMaterial,
 * pass in a clone of the material stored in object.
 *
 * To clone,
 * let clone = original.clone();
 * if (clone.map) clone.map = original.map.clone();
 * for (let key of Object.keys(original)) {
 *      if (clone[key] === undefined) clone[key] = original[key];
 * }
 */
const defaultMaterial = {};
const textureObjectCache = {};

const ColourPackagesPanelViewing = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    let className = 'colourpackagespanel' + (props.show ? '' : ' invisible');
    const [activeColourPackage, setActiveColourPackage] = useState("default");

    // Don't allow switching packages when false
    const [processedDefaultColourPackage, setProcessedDefaultColourPackage] = useState(false);
    const [currentlyLoadingColourPackage, setCurrentlyLoadingColourPackage] = useState(false);

    useEffect(() => {
        setActiveColourPackage("default");
        setProcessedDefaultColourPackage(false);
    }, [props.currElevation]);

    useEffect(() => {
        if (!props.modelData3D) return;
        let models = props.viewer.models3DMap;
        try {
            for (let modelKey in models) {
                let model = models[modelKey].model;

                // console.log(`debug - model is `);
                // console.log(model);

                let guid = model.getDocumentNode().guid();
                if (!(guid in defaultMaterial)) defaultMaterial[guid] = {};
                let materialManager = props.viewer.viewer3D.viewer.impl.matman();
                let frags = model.getFragmentList();
                let fragsList = Object.keys(frags.fragments.fragId2dbId).map((el) => Number(el));
                for (let fragId of fragsList) {
                    let ref = frags.getMaterial(fragId);
    
                    if (!ref) continue;
    
                    let defMaterial = ref.clone();
                    if (defMaterial.map) {
                        defMaterial.map = defMaterial.map.clone();
                    }
    
                    for (let key of Object.keys(ref)) {
                        if (defMaterial[key] === undefined) {
                            defMaterial[key] = ref[key];
                        }
                    }
    
                    defaultMaterial[guid][fragId] = defMaterial;
                    materialManager.addMaterial(`default-material-${guid}-${fragId}-${defMaterial.uuid}`, defMaterial, true);
                }
            }
            setProcessedDefaultColourPackage(true);
        } catch(error) {
            console.log(`Error while trying to process colour packages: ${error.message}`)
        }        
    }, [props.modelData3D]);

    const resetColourPackages = () => {
        setActiveColourPackage("default"); // This is called again for the event

        let models = props.viewer.models3DMap;
        if (!props.modelData3D) { return; }

        for (let modelKey in models) {
            if (models[modelKey].loaded === 2) {
                let model = models[modelKey].model;
                let guid = model.getDocumentNode().guid();
                if (!(guid in defaultMaterial)) continue;
                let materialManager = props.viewer.viewer3D.viewer.impl.matman();
                let frags = model.getFragmentList();

                model.unconsolidate();

                for (let fragId of Object.keys(defaultMaterial[guid])) {
                    let defMaterial = defaultMaterial[guid][fragId];
                    let clone = defMaterial.clone();
                    if (clone.map) {
                        clone.map = defMaterial.map.clone();
                    }
                    for (let key of Object.keys(defMaterial)) {
                        if (clone[key] === undefined) clone[key] = defMaterial[key];
                    }

                    materialManager.addMaterial(`default-material-${guid}-${fragId}-${clone.uuid}`, clone, true);
                    frags.setMaterial(fragId, clone);
                }
            }
        }
        props.viewer.viewer3D.viewer.impl.invalidate(true);
    };

    useEffect(() => {
        document.addEventListener('resetColourPackages', resetColourPackages);
        return () => document.removeEventListener('resetColourPackages', resetColourPackages);
    }, [props.modelData3D]);

    const colourViewerUsingColourPackage = async (colourPackage) => {
        let models = props.viewer.models3DMap;
        if (!props.modelData3D) { return; }
        if (!colourPackage) { return; }
        let modelData = props.modelData3D._modelData
        let textures = props.projectTextures
        let textureLoader = new THREE.TextureLoader()

        for (let modelKey in models) {
            if (models[modelKey].loaded === 2) {
                let model = models[modelKey].model;
                let tree = model.getInstanceTree();
                let frags = model.getFragmentList();

                let missingData = {
                    TOI3: [],
                    textures: []
                };

                for (let colourInstance of colourPackage) {
                    if (colourInstance && colourInstance.toi3 !== "") {
                        try {
                            let textureObjectURL;
                            // Check if cached in textureObjectCache
                            let src = textures[colourInstance.value].src;
                            if (src in textureObjectCache) {
                                let blob = textureObjectCache[src];
                                textureObjectURL = URL.createObjectURL(blob);
                            } else {
                                textureObjectURL = await fetch(textures[colourInstance.value].src, { credentials: 'include'}).then(res => res.blob()).then(blob => {
                                    textureObjectCache[src] = blob;
                                    return URL.createObjectURL(blob);
                                });
                            }

                            textureLoader.load(textureObjectURL, (texture) => {
                                const materialManager = props.viewer.viewer3D.viewer.impl.matman();
                                let toi3sets = colourInstance.toi3.split(';');

                                toi3sets = toi3sets.map((str) => str.trim());

                                for (let toi3 of toi3sets) {
                                    try {
                                        let dbIds_to_colour = modelData["TOI3"] ? modelData["TOI3"][toi3] : [];
                                        model.unconsolidate();
                                        for (let dbId of dbIds_to_colour) {
                                            tree.enumNodeFragments(dbId,
                                                (fragId) => {
                                                    let newMaterial = frags.getMaterial(fragId);
                                                    newMaterial.map = texture;
                                                    newMaterial.color = new THREE.Color(0xffffff);
                                                    materialManager.addMaterial(`custom-material-${colourInstance.value}`, newMaterial, true);
                                                    frags.setMaterial(fragId, newMaterial)
                                                },
                                                false
                                            );
                                        }
                                    } catch (err) {
                                        if (!missingData.TOI3.includes(toi3)) {
                                            missingData.TOI3.push(toi3);
                                        }
                                        continue;
                                    }
                                }

                                URL.revokeObjectURL(textureObjectURL)
                            })
                        } catch (err) {
                            enqueueSnackbar(`Missing Texture: ${colourInstance.value}`, {
                                variant: 'warning'
                            })
                        }
                    }
                }

                if (missingData.TOI3.length > 0) {
                    enqueueSnackbar(`Missing TOI3s: ${missingData.TOI3.join(', ')}`, {
                        variant: 'warning'
                    })
                }

                props.viewer.viewer3D.viewer.impl.invalidate(true);
            }
        }
    }
    const handleColourPackageChange = (value) => {
        setActiveColourPackage(value)
        if (value === "default") {
            resetColourPackages();
            return;
        }
        try {
            (async () => {
                setCurrentlyLoadingColourPackage(true);
                await colourViewerUsingColourPackage(props.colourPackagesData[value]);
                setCurrentlyLoadingColourPackage(false);
            })();
        } catch (err) {
            enqueueSnackbar(`Error while switching colour package:  ${err}`, {
                variant: 'error'
            })
        }
    }

    useEffect(() => {
        if (props.type === "CON" && props.data.colourPackage) {
            handleColourPackageChange(props.data.colourPackage)
        }
    }, [props.modelData3D, props.colourPackagesData, props.data.colourPackage])

    return <div className={className}>
        <h2 className="panel-title">Colour Packages</h2>
        <p className="panel-desc">View your model in an assortment of colour packages within the viewer.</p>
        {props.hasError ? 
            // layer 1: if error
            <Alert severity="error">Colour Packages Missing</Alert> : 
            // layer 1: no errors
            // layer 2: CON
            props.type === "CON" ? 
            <Fragment>
                <Alert severity="info">Colour Package Switching Disabled in CON</Alert>
                <p>Colour Package of Lot: <b>{props.data.colourPackage || "Not Specified"}</b></p>
            </Fragment> : Object.keys(props.colourPackagesData).length > 0 ?
            <FormControl component="fieldset">
                <FormLabel component="legend">Elevations</FormLabel>
                <RadioGroup name="elevations" value={props.currElevation} onChange={(_, val) => props.setCurrElevation(val)}>
                    {
                        props.elevations.map((el) => {
                            return <FormControlLabel key={uuidv4()} value={el} control={<Radio color="primary" disabled={currentlyLoadingColourPackage || props.canLoadModels3D === false} />} label={el} />;
                        })
                    }
                </RadioGroup>
                <hr style={{ width: "350px", border: "1px solid #c7c7c7", marginTop: 20 }} />
                <FormLabel component="legend" style={{ marginTop: 20 }}>Available Packages</FormLabel>
                {
                    processedDefaultColourPackage === true ?
                        <RadioGroup name="colourpackage" value={activeColourPackage} onChange={(_, val) => handleColourPackageChange(val)}>
                            <FormControlLabel value={"default"} control={<Radio color="primary" disabled={currentlyLoadingColourPackage || props.canLoadModels3D === false} />} label="DEFAULT" />
                            {
                                Object.keys(props.colourPackagesData).map((colourPackageKey) => {
                                    return <FormControlLabel key={colourPackageKey} value={colourPackageKey} control={<Radio color="primary" disabled={currentlyLoadingColourPackage} />} label={colourPackageKey} />;
                                })
                            }
                        </RadioGroup> :
                        <Spinner />
                }
            </FormControl> : <Spinner />}

    </div>
}

const ColourPackagesPanel = (props) => {
    const [colourPackagesJson, setColourPackagesJson] = useState(null);
    const [colourPackagesData, setColourPackagesData] = useState([]);
    const [projectTextures, setProjectTextures] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        
        if (!props.project || props.project === '') return;

        try {
            (async () => {

                const data = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/data/colourPackageData/${encodeURIComponent(props.project)}`, {
                    credentials: 'include'
                })
                    .then((res) => {
                        return res.json();
                    });

                if (data.error) {
                    setErrorMessage(data.solution);
                }

                setColourPackagesJson(data);
            })();
        } catch (err) {
            setErrorMessage(`Colour Packages Endpoint Error`);
        }
    }, [props.project]);

    useEffect(() => {
        // Ensure that currElevation exists in PRE befoe doing anything
        if (props.type === "PRE" && !props.currElevation) return;

        if (colourPackagesJson !== null && colourPackagesJson.fileMap) {
            let flag = false;
            for (let elevationKey in colourPackagesJson.fileMap) {
                if (props.type === "PRE") {
                    if (props.currElevation) {
                        if (!props.currElevation.includes(elevationKey)) { continue; }
                        setColourPackagesData(colourPackagesJson.fileMap[elevationKey]);
                        setProjectTextures(colourPackagesJson.textures);
                        flag = true;
                    }
                } else {
                    if (props.data.colourPackage) {
                        if (!props.data.colourPackage.includes(elevationKey)) { continue; }
                        setColourPackagesData(colourPackagesJson.fileMap[elevationKey]);
                        setProjectTextures(colourPackagesJson.textures);
                        flag = true;
                    }
                }
            }

            if (flag === false) {
                setErrorMessage(`Colour Packages Missing`);
            }
        }
    }, [props.currElevation, colourPackagesJson]);

    useEffect(() => {
        if (errorMessage === null) return;
        enqueueSnackbar(errorMessage, {
            variant: 'error',
            autoHideDuration: 3000,
        });
    }, [errorMessage]);

    return <ColourPackagesPanelViewing {...props} colourPackagesData={colourPackagesData} projectTextures={projectTextures} hasError={errorMessage !== null} />;
}

export default ColourPackagesPanel
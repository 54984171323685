// react
import React, { Component } from 'react';
// sub components
import Spinner from 'components/Spinner/Spinner.js';
import InputField from 'components/InputField/InputField.js';
// external libraries
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
// import { ResizableBox } from 'react-resizable';
// styling
import './FilterPanel.scss';

/* -----------------------------------------------------------------------------
helper functions and constants
----------------------------------------------------------------------------- */
const delimiter = '@@@';
const initialExpanded = [
    'Architecture',
    'Structure',
    'Systems'
];

/**
 * from the 'filters' object, generate an array containing lists of nodes 
 * (dbids) and the leaves' encoded string.
 * NOTE: this function currently modifies the input parameter (valueToDbId) 
 * directly which is anti-pattern. TODO: refactor
 * @param {object} filters the filters object
 * @param {array} keychain 
 * @param {array} valueToDbId 
 * @returns {array} [nodes, leaves]
 */
const filtersToNodes = (filters, keychain, keychainBranch, valueToDbId) => {
    const leaves = [];
    const branches = [];
    const nodes = [];

    let allFilterKeys = Object.keys(filters);
    for (let i = 0; i < allFilterKeys.length; i++) {
        const el = allFilterKeys[i];
        
        // if key is 'dbIds' its not a sub-category; continue
        if (el === 'dbIds') continue; 
        // otherwise, dig further into the sub-category
        const values = Object.keys(filters[el]);
        
        // we reach the terminal leaf (last nested object) which contains only 
        // 1 key: the list of dbIds at this hierarchy
        if (values.length === 1 && values[0] === 'dbIds') {
            const value = keychain.join(delimiter) + delimiter + el;
            leaves.push(value);
            valueToDbId[value] = filters[el].dbIds;
            nodes.push({value, label: el});
            // process the keychainBranch
            const branchString = keychainBranch.join('||');
            branches.push(branchString);
            // add these dbIds to master list
        } 
        // not terminal leaf, continue to drill down
        else {            
            // TODO: examine what happens if values is []
            const keychainCopy = Array.from(keychain);
            const keychainBranchCopy = Array.from(keychainBranch);
            const value = `${el}${delimiter}${uuidv4()}`;
            // branches.push(value);
            keychainCopy.push(el);
            keychainBranchCopy.push(value);
            
            const [childNodes, childLeaves, childBranches] = 
                filtersToNodes(filters[el], keychainCopy, keychainBranchCopy, valueToDbId);
            
            leaves.push(...childLeaves);
            branches.push(...childBranches);
            
            const result = {
                value,
                label: el,
                children: childNodes
            };

            // also check if there are dbIds at this depth
            if (values.includes('dbIds')) {
                const cousinValue = `${value} - Aliens`;
                result['children'].push({
                    value: cousinValue,
                    label: 'Others',
                })
                leaves.push(cousinValue);
                valueToDbId[cousinValue] = filters[el].dbIds;
            }
            nodes.push(result);
        }
    };

    return [nodes, leaves, branches];
};

/**
     * action handler for the search bar
     * @param {string} searchTerm text value inside the search bar
     * @param {object} data arrays of leaves and branches
     * @param {array} extraFuncs array of functions to run at the end
     */
const handleSearch = (searchTerm, data, extraFuncs) => {
    const { leaves, branches } = data;

    const searchResult = leaves.filter(leaf => {
        if (leaf.toLowerCase().includes(searchTerm.toLowerCase())) return true
        else return false
    });
    // branches to be expanded
    const expanded = branches.filter(branch => {
        if (branch.toLowerCase().includes(searchTerm.toLowerCase())) return true
        else return false
    });

    extraFuncs.map(func => {
        func(searchResult, expanded);
    });
};

/* -----------------------------------------------------------------------------
main component
----------------------------------------------------------------------------- */
class FilterPanel extends Component {
    
    constructor(props) {
        super();

        // temporary (filtered) data
        this.state = {
            filters: {}, // source data
            checked: [], // list of leaves
            expanded: [] // list of branches
        }

        // permanent full data
        this.nodes = [];
        this.leaves = [];
        this.allLeaves = [];
        this.branches = [];
        this.valueToDbId = {};
        this.dbIdToValue = {};
        this.initExpandedBranches = [];
        this.initHiddenDBIDs = [];
        this.initLeaves = [];

        // bind all methods to this
        this.handleToggleFilterEvent = this.handleToggleFilterEvent.bind(this);
        this.handleIsolateFilterEvent = this.handleIsolateFilterEvent.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleExpanded = this.handleExpanded.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.hideEverything = this.hideEverything.bind(this);
        this.showEverything = this.showEverything.bind(this);
    }

    /* -------------------------------------------------------------------------
    utilities functions
    ------------------------------------------------------------------------- */
    
    /**
     * create the filter panel
     */
    createFilterPanel() {
        const {
            viewer,
            filters,
            currentModels3D,
        } = this.props;

        this.valueToDbId = {};
        this.dbIdToValue = {};
    
        const [nodes, leaves, branches] = filtersToNodes(
            filters, [], [], this.valueToDbId);

        // Populate dbIdToValue
        for (const value of Object.keys(this.valueToDbId)) {
            for (const dbId of this.valueToDbId[value]) {
                this.dbIdToValue[dbId] = value;
            }
        }
        
        this.nodes = nodes;
        this.leaves = leaves;
        this.allLeaves = Array.from(leaves); // contains every leaf including misc
        this.branches = [...new Set(branches)]; // only keep unique values

        const misc = [];

        // By default, uncheck miscellaneous for the leaves list
        for (const key of Object.keys(this.valueToDbId)) {
            if (key.includes('Miscellaneous')) {
                const index = this.leaves.indexOf(key);
                if (index != -1) {
                    misc.push(...this.valueToDbId[this.leaves[index]]);
                    this.leaves.splice(index, 1);
                }
            }
        };

        this.initLeaves = [...this.leaves];
        this.initHiddenDBIDs = [...misc];

        for (const el of currentModels3D) {
            // use this method to hide certain dbIds
            viewer.viewer3D.viewer.hide(misc, el.model);
        }
        
        const expanded = [];
        for (let topLevelObject of this.nodes) {
            if (initialExpanded.includes(topLevelObject.label)) expanded.push(topLevelObject.value);
        }

        this.initExpandedBranches = [...expanded];
        this.setState({ checked: leaves, expanded });
    }

    /* -------------------------------------------------------------------------
    event handlers
    ------------------------------------------------------------------------- */

    //TODO: Allow this to handle a more efficient data structure
    handleToggleFilterEvent(e) {
        const { detail } = e;
        const newChecked = Array.from(this.state.checked);

        // Wildcard works same as wildcard in regex
        // A@@@B@@@*
        // A@@@B@@@C@@@1

        // If we have A@@@B@@@C, treat it as A@@@B@@@C*

        for (let key of Object.keys(detail)) {
            // Support number dbIds as well as string 'A@@@B@@@...' format
            const status = detail[key];

            if (typeof key === 'number') {
                if (this.dbIdToValue[key]) key = this.dbIdToValue[key];
                else continue;
            }

            // Check if there are exactly 2 delimiters
            if (!key.includes('*') && key.split(delimiter).length === 3) {
                key = key + '*';
            }
            if (status) {
                if (key[key.length - 1] === '*') {
                    for (const str of Object.keys(this.valueToDbId)) {
                        if (str.startsWith(key.substr(0, key.length - 1))) {
                            if (newChecked.includes(str)) continue;
                            newChecked.push(str);
                        }
                    }
                    continue;
                }

                if (newChecked.includes(key)) continue;
                newChecked.push(key);
            }
            else {
                if (key[key.length - 1] === '*') {
                    for (const str of Object.keys(this.valueToDbId)) {
                        if (str.startsWith(key.substr(0, key.length - 1))) {
                            if (newChecked.includes(str)) {
                                newChecked.splice(newChecked.indexOf(str), 1);
                            }
                        }
                    }
                    continue;
                }

                if (newChecked.includes(key)) {
                    newChecked.splice(newChecked.indexOf(key), 1);
                }
            }
        }

        this.handleCheck(newChecked);
    }

    // TODO: add support for string values
    handleIsolateFilterEvent(e) {
        const { detail } = e;
        const { arr } = detail;
        const newChecked = [];
        for (const dbId of arr) {
            if (this.dbIdToValue[dbId]) newChecked.push(this.dbIdToValue[dbId]);
        }
        this.handleCheck(newChecked);
    }

    /**
     * 
     * @param {array} _searchResult not needed, just that the higher order 
     * function will pass this argument to us so need a placeholder to catch
     * @param {array} expanded list of branch names to be expanded
     */
    handleExpanded(_searchResult, expanded) {
        // break the branchString into actual branch values
        const toExpand = [];
        for (let branchString of expanded) {
            let branches = branchString.split('||');
            toExpand.push(...branches);
        }
        this.setState({ expanded: toExpand });
    };

    /**
     * action handler for when the search bar is empty
     */
    handleReset() {
        const {
            viewer,
            currentModels3D,
        } = this.props;

        this.setState({ expanded: this.initExpandedBranches })
        
        // hide misc
        if (currentModels3D) {
            for (const el of currentModels3D) {
                viewer.viewer3D.viewer.hide(this.initHiddenDBIDs, el.model);
            }
        };
        // now toggle on the initial leaves
        this.handleCheck(this.initLeaves);
    };

    /**
     * show everything
     */
    showEverything() {
        this.setState({ expanded: this.initExpandedBranches })
        // toggle on all leaves
        this.handleCheck(this.allLeaves);
    };

    /**
     * hide all elements in viewer
     */
    hideEverything() {
        const { viewer, currentModels3D } = this.props;

        let hideArr = [];
        this.leaves.forEach((el) => {
            const segment = this.valueToDbId[el];
            if (!!segment) {
                hideArr.push(...segment);
            }
        });

        // hide misc & initial leaves
        if (currentModels3D) {
            for (const el of currentModels3D) {
                // viewer.viewer3D.viewer.hide(this.initHiddenDBIDs, el.model);
                viewer.viewer3D.viewer.hide(hideArr, el.model);
            }
        };

        // uncheck everything from filter tree
        this.handleCheck([]);
    };

    /**
     * 
     * @param {array} checked array of leaves (value, not label) currently 
     * checked from the CheckboxTree element
     */
    handleCheck(checked) {
        const { viewer, currentModels3D } = this.props;
        const prev = this.state.checked;
        const curr = checked;

        // leaves not present in curr will be hidden
        const hide = prev.filter((el) => !curr.includes(el));
        // need to convert those leaves values to dbIds for the model to hide
        let hideArr = [];
        hide.forEach((el) => {
            const segment = this.valueToDbId[el];
            if (!!segment) {
                hideArr.push(...segment);
            }
        });

        // console.log(`debug - hideArr is `);
        // console.log(hideArr);

        // hide dbIds in hideArr array
        if (currentModels3D) {
            for (const el of currentModels3D) {
                viewer.viewer3D.viewer.hide(hideArr, el.model);
            }
        }

        // similar process but for dbIds to show
        const show = curr.filter((el) => !prev.includes(el));
        let showArr = [];
        show.forEach((el) => {
            const segment = this.valueToDbId[el];
            if (!!segment) {
                showArr.push(...segment);
            }
        });

        if (currentModels3D) {
            for (const el of currentModels3D) {
                // https://forge.autodesk.com/en/docs/viewer/v7/reference/Viewing/Viewer3D/#show-node-model
                viewer.viewer3D.viewer.show(showArr, el.model);
            }
        }
        this.setState({ checked });
    }

    /* -------------------------------------------------------------------------
    hooks
    ------------------------------------------------------------------------- */
    componentDidMount() {
        // event listener - for use by other components
        document.addEventListener('toggleFilter', this.handleToggleFilterEvent);
        document.addEventListener('isolateFilter', this.handleIsolateFilterEvent);
    }

    componentWillUnmount() {
        document.removeEventListener('toggleFilter', this.handleToggleFilterEvent);
        document.removeEventListener('isolateFilter', this.handleIsolateFilterEvent);
    }

    componentDidUpdate(prevProps) {
        if (this.props.modelData3D && (!prevProps.modelData3D || false)) {
            this.createFilterPanel();
        }
    }

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    // When linking checkbox to other functions,
    // we just use hash table mapping TOI3 to dbIds
    // or vice versa
    render() {
        let className = 'filter-panel' + (this.props.show ? '' : ' invisible');
        let contentsClassName = this.props.modelData3D ? '' : 'invisible';
        let contentsSpinner = this.props.modelData3D ? <div></div> : <Spinner />;

        let fontSize = this.props.mobileView ? '20px' : '14px';
        return (
            <div className={className}>
                <h2 className='panel-title'>Filtering</h2>
                <p className='panel-desc'>Engage with checkboxes to see/hide parts of the model.</p>
                {contentsSpinner}
                <div className={contentsClassName}>
                    
                    {/* serchbar */}
                    <InputField 
                        actionFunc={handleSearch} 
                        data={{
                            leaves: this.leaves,
                            branches: this.branches
                        }} 
                        resetFunc={this.handleReset}
                        extraFuncs={[this.handleCheck, this.handleExpanded]}
                        />
                    
                    {/* selection options */}
                    <div className='horizontal-link'>
                        <div className='horizontal-link__item' onClick={this.showEverything}>Select all</div>
                        <div className='horizontal-link__item' onClick={this.hideEverything}>Unselect all</div>
                        <div className='horizontal-link__item' onClick={this.handleReset}>Reset</div>
                    </div>

                    {/* filter tree */}
                    <CheckboxTree
                        nodes={this.nodes}
                        checked={this.state.checked}
                        expanded={this.state.expanded}
                        onCheck={this.handleCheck}
                        onExpand={(expanded) => this.setState({ expanded })}
                        icons={{
                            check: <FontAwesomeIcon className={`rct-icon rct-icon-check ${this.props.mobileView ? 'iconMobileWithWhiteBackground' : 'iconWithWhiteBackground'}`} icon='check-square' style={{ fontSize: fontSize, color: '#ff751f' }} />,
                            halfCheck: <FontAwesomeIcon className={`rct-icon rct-icon-check ${this.props.mobileView ? 'iconMobileWithWhiteBackground' : 'iconWithWhiteBackground'}`} icon='minus-square' style={{ fontSize: fontSize, color: '#ff751f' }} />,
                            uncheck: <FontAwesomeIcon className='rct-icon rct-icon-uncheck' icon={['fas', 'square']} style={{ fontSize: fontSize }} />,
                            expandClose: <FontAwesomeIcon className='rct-icon rct-icon-expand-close' icon='plus-square' style={{ fontSize: fontSize }} />,
                            expandOpen: <FontAwesomeIcon className='rct-icon rct-icon-expand-open' icon='minus-square' style={{ fontSize: fontSize }} />,
                            expandAll: <FontAwesomeIcon className='rct-icon rct-icon-expand-all' icon='plus-square' style={{ fontSize: fontSize }} />,
                            collapseAll: <FontAwesomeIcon className='rct-icon rct-icon-collapse-all' icon='minus-square' style={{ fontSize: fontSize }} />,
                            parentClose: <FontAwesomeIcon className='rct-icon rct-icon-parent-close rct-icon-hide' icon='folder' style={{ fontSize: fontSize }} />,
                            parentOpen: <FontAwesomeIcon className='rct-icon rct-icon-parent-open rct-icon-hide' icon='folder-open' style={{ fontSize: fontSize }} />,
                            leaf: <FontAwesomeIcon className='rct-icon rct-icon-leaf-close rct-icon-hide' icon='file' style={{ fontSize: fontSize }} />
                        }}
                    />
                    {/* reset button */}
                    {/* <button onClick={this.handleReset} className='reset-button'>
                        RESET TO DEFAULT
                    </button> */}
                </div>
            </div>
        );
    }
}

export default FilterPanel
import 'components/ModalDataDownload/ModalDataDownload.scss'
import Spinner from 'components/Spinner/Spinner';
import BrandButton from 'components/common/BrandButton/BrandButton';

export default function ModalDataDownload(
  	{ showModal, setShowModal, canLoadModels3D, currElevation, loadElevation }) {
	const showHideClassName = showModal ? 'modal-download modal-download--display-block' : 'modal-download modal-download--display-none';
	const isDownloaded = document.getElementById('is-downloaded');

	const download = () => {
		document.getElementById('download-data-dump').click();
		
		// close modal and load the previous elevation back
		if (isDownloaded && isDownloaded.textContent === 'Download complete') {
			setShowModal(false);
			loadElevation(currElevation);
		} 
		// else {
		// 	alert(`isDownloaded.textContent is ${isDownloaded.textContent}. Equals 'Download complete'? ${isDownloaded.textContent === 'Download complete'}`)
		// }
	}
	
	return (
		<div className={ showHideClassName }>
			<div className='modal-download__main'>
				<div className='modal-download__text'>{'File will be available shortly'}</div>
				{canLoadModels3D ? 
					<div className='modal-download__button-container'>
					<BrandButton
						text='Click to download'
						actionFunction={download}
					/>
					</div> : 
					<Spinner/>
				}
			<div id='is-downloaded'></div>
			</div>
		</div>
	)
}
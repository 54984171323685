// react
import React, { useState } from 'react';
// styling
import './InputField.scss';

export default function InputField({ actionFunc, data, resetFunc, extraFuncs }) {
    
    /* -------------------------------------------------------------------------
    states
    ------------------------------------------------------------------------- */
    const [error, setError] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    /* -------------------------------------------------------------------------
    helper functions
    ------------------------------------------------------------------------- */
    const handleChange = (event) => {
        const text = event.target.value;
        setSearchTerm(text);
        if (text === '') setError(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // if no input, should reset the filter
            if (event.target.value.length === 0) {
                resetFunc();
                setError(false);
            }
            else if (event.target.value.length >= 3) {
                actionFunc(searchTerm, data, extraFuncs);
                setError(false);
            } else {
                setError(true);
            }
        };
    }

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    return (
        <div className='search'>
            <input type='text' 
                className='search__bar' 
                placeholder='search...' 
                onKeyDown={handleKeyDown}
                onChange={handleChange}
            />
            <p className={`search__status ${error ? 'search__status--show' : ''}`}>
                At least 3 letters required
            </p>
        </div>
    )
}

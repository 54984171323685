import 'dotenv/config';

/**
 * Helper function
 * @param {String} id 
 */
const getInfoFromId = (id) => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/forge/datamanagement?` + new URLSearchParams({ id: id }).toString(), {
        credentials: 'include'
    })
        .then((res) => res.json());
}

/**
 * Given id (first call '#'), project, model, return promise with urn.
 * Reject if cannot find in account.
 * @param {String} id 
 * @param {String} project 
 * @param {String} model 
 */
export const gm = async (id, project, model) => {
    var child = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/forge/project/${project}/model/${model}`, { 
        credentials: 'include'})
        .then((res) => res.json());
    if (!child.status) return Promise.reject();
    return await getInfoFromId(child.endpoint).then((res2) => {
        return res2[0].id;
    }).catch(() => {
        return Promise.reject();
    });
}

/**
 * Return an array of [urn, viewableId] given the nodeId.
 * Second index is returned only when there is a viewableId.
 * @param {String} nodeId 
 */
export const nodeIdToUrn = (nodeId) => {
    if (nodeId.indexOf('|') > -1) {
        var urn = nodeId.split('|')[1];
        var viewableId = nodeId.split('|')[2];
        return [urn, viewableId];
    } else {
        return [nodeId];
    }
}
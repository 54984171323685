// react
import { Fragment, useEffect, useState } from 'react';
// sub components
import AggregatedTable from './AggregatedTable';
// external components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// external libraries
import { v4 as uuidv4 } from 'uuid';
// import * as XLSX from 'xlsx';
// internal libraries
import { formatViewName } from 'utils/formatting';
import { downloadWorkbook } from 'utils/excel';
// styling
import './QuantityTable.scss'

/* -----------------------------------------------------------------------------
utility functions
----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
main component
----------------------------------------------------------------------------- */
const QuantityTable = ({
        // from ancestors
        modelKey, viewer, toiAggStart,
        // from parents
        dataDump, dataForExport, categoricalData 
    }) => {
    const defaultWorkbookName = `${modelKey ? modelKey + ' ' : ''}Quantities export`;
    let { dataDumpHeader, dataDumpRows, dataRowCount } = dataDump;
    
    /* -------------------------------------------------------------------------
    states & hooks
    ------------------------------------------------------------------------- */
    const [modelListUI, setModelListUI] = useState(null);
    const [promptOpen, setPromptOpen] = useState(false);
    const [workbookName, setWorkbookName] = useState(defaultWorkbookName);

    useEffect(() => {
        setModelListUI(loadedModelsList())
    }, [viewer.models3DMap])
    
    /* -------------------------------------------------------------------------
    helper functions
    ------------------------------------------------------------------------- */
    const dumpData = () => {
        
        // debug - completeness check
        // alert(`dataDumpRows has ${dataDumpRows.length} and dataRowCount is ${dataRowCount}`);
        const isDownloaded = document.getElementById('is-downloaded');

        if (dataDumpRows.length === dataRowCount) {
            // add 1 header row to dataDumpRows
            dataDumpRows.unshift(dataDumpHeader);
            
            downloadWorkbook(
                {
                    'All Elements': dataDumpRows
                }, 
                'All Elements', 
                workbookName
            );
            // update status so modal know to close
            isDownloaded.textContent = 'Download complete';
        } else {
            alert(`Almost there, please try again after few seconds...`);
        }
    };

    /**
     * Useful SRC: https://redstapler.co/sheetjs-tutorial-create-xlsx/
     */
    const exportDataTable = (dataForExport) => {
        
        // close the rename popup
        setPromptOpen(false);

        const quantityData = dataForExport();
        const dataObject = {};

        // all selected options data
        let data_to_export = quantityData[1];
        data_to_export.unshift(quantityData[0]);
        dataObject['ALL SELECTED OPTIONS'] = data_to_export;

        // woodframing sheet
        let woodFramingTOI2 = ['Wood Framing', 'Beams & Columns']
        let woodFramingData = quantityData[1].filter(row => {
            for(let filterKey of woodFramingTOI2) if(filterKey === row[2]) return true;
            return false;
        });
        woodFramingData.unshift(quantityData[0]);
        dataObject['WOOD FRAMING'] = woodFramingData;

        // each active view
        for (const el of Object.values(viewer.models3DMap)) {
            if (el.loaded === 2) {
                const modelName = el.model.getDocumentNode().name();
                let sheetName = modelName.replace(/[^a-zA-Z0-9 ]/g, '').substring(0, 31)
                let sheetData = quantityData[1].filter(row => row[0] === modelName);
                sheetData.unshift(quantityData[0]);
                dataObject[sheetName] = sheetData;
            }
        }

        downloadWorkbook(dataObject, workbookName, workbookName);
    }

    const loadedModelsList = () => {
        if (viewer.models3DMap) {
            return Object.values(viewer.models3DMap).map(el => {
                if (el.loaded === 2) {
                    return <div key={uuidv4()} className='loadedModelListItem'>
                        {formatViewName(el.model.getDocumentNode().name())}
                    </div>
                } else {
                    return null;
                }
            })
        }
        return null;
    }

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    const DialogTitle = (props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography {...other}>
                <Typography variant='h6' gutterBottom>{children}</Typography>
                {promptOpen ? (
                    <IconButton aria-label='close' onClick={() => setPromptOpen(false)} style={{
                        position: 'absolute',
                        right: 4,
                        top: 4
                    }}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    }

    return <Fragment>
        {/* models list */}
        <h5 className='loadedModelListTitle'>Active Models</h5>
        <div className='loadedModelList'>
            {modelListUI}
        </div>
        {/* aggregate table */}
        <AggregatedTable 
            data={categoricalData}
            toiAggStart={toiAggStart} 
            dataExportFunction={() => setPromptOpen(true)} 
        />
        {/* Excel export popup */}
        <Dialog open={promptOpen} onClose={() => setPromptOpen(false)}>
            <DialogTitle>Quantities Export</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please provide a file name. Special characters will be removed.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin='dense'
                    label='Workbook Name & File Name'
                    placeholder={defaultWorkbookName}
                    fullWidth
                    onChange={(event) => {
                        setWorkbookName(event.target.value);
                    }}
                    onKeyPress= {(e) => {
                        if (e.key === 'Enter') exportDataTable(dataForExport);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setPromptOpen(false)} color='secondary' variant='contained'>
                    Cancel
                </Button>
                <Button onClick={() => exportDataTable(dataForExport)} color='primary' variant='contained'>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
        {/* data dump */}
        <div className='data-dump'>
            Detailed model data also available 
                <a 
                    className='data-dump__link' 
                    onClick={dumpData}
                    id='download-data-dump'
                >
                    Download
                </a>
        </div>
        {/* <div id='data-status' className='data-status'>
            {`${dataRowCount} / ${dataDumpRows.length} elements loaded`}
        </div> */}
    </Fragment>
}

/* -----------------------------------------------------------------------------
exporting
----------------------------------------------------------------------------- */
export default QuantityTable;
import icon from './open_in_full.svg';

const Autodesk = window.Autodesk;

function Full3DExtension(viewer, options) {
    Autodesk.Viewing.Extension.call(this, viewer, options);
}

Full3DExtension.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
Full3DExtension.prototype.constructor = Full3DExtension;

Full3DExtension.prototype.load = function () {
    return true;
};

Full3DExtension.prototype.unload = function () {
    if (this.subToolbar) {
        this.viewer.toolbar.removeControl(this.subToolbar);
        this.subToolbar = null;
    }
};

Full3DExtension.prototype.onToolbarCreated = function (toolbar) {
    // eslint-disable-next-line
    var viewer = this.viewer;
    var status = false;

    // >>
    var button1 = new Autodesk.Viewing.UI.Button('Full3DExtension-button');
    button1.onClick = function (e) {
        status = !status;
        document.dispatchEvent(new CustomEvent('full3D', { detail: { status } }));

        if (status) {
            button1.icon.parentElement.classList.remove('inactive');
            button1.icon.parentElement.classList.add('active');

            button1.icon.style.backgroundColor = '#00bfff';
        } else {
            button1.icon.parentElement.classList.remove('active');
            button1.icon.parentElement.classList.add('inactive');

            button1.icon.style.backgroundColor = '#ffffff';
        }
    };
    button1.onMouseOver = (e) => {
        button1.icon.style.backgroundColor = '#00bfff';
    }

    button1.onMouseOut = (e) => {
        if (!status) button1.icon.style.backgroundColor = '#ffffff';
    }
    button1.addClass('Full3DExtension-button');
    button1.addClass('inactive');
    button1.setToolTip('Full 3D');
    // https://icons8.com/icons/set/double-arrow-right-transparent--white
    button1.icon.style.backgroundColor = '#ffffff';
    button1.icon.style.maskImage = `url('${icon}')`;
    button1.icon.style.webkitMaskImage = `url('${icon}')`;
    button1.icon.style.webkitMaskRepeat = 'no-repeat';
    button1.icon.style.webkitMaskPosition = 'center';

    // SubToolbar
    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('Full3DExtension-subToolbar');
    this.subToolbar.addControl(button1);

    toolbar.addControl(this.subToolbar);
};

Autodesk.Viewing.theExtensionManager.registerExtension('Full3DExtension', Full3DExtension);
// react
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// sub components
import Select from 'components/Selection/Select';
import LoadingStatus from 'components/LoadingStatus/LoadingStatus';
// styling
import 'components/PrivateRoute/PrivateRoute.scss';

/* -----------------------------------------------------------------------------
app logic
----------------------------------------------------------------------------- */

/**
 * load content if user logged in; redirect otherwise
 * @param {boolean} loggedIn whether user is logged in
 * @param {string} redirUrl URL to direct user to after logging in
 * @returns {component} either <Select/> or <Redirect/>
 */
const PrivateRoute = ({ loggedIn, redirUrl }) => {

    const [whatToLoad, setWhatToLoad] = useState(
        <div className='route-container'>
            <LoadingStatus
                message='Cheking user info...'
                isError={false}
            />
        </div>
    );

    useEffect(() => {
        if (loggedIn === false) setWhatToLoad(<Redirect to={redirUrl} />);
        if (loggedIn === true) setWhatToLoad(<Select/>);
    }, [loggedIn]);

    return whatToLoad;
};

PrivateRoute.propTypes = {
    loggedIn: PropTypes.bool,
    redirUrl: PropTypes.string.isRequired,
}

export default PrivateRoute;

// react
import React from 'react';
// sub component
import Spinner from 'components/Spinner/Spinner';
// styling
import 'components/LoadingStatus/LoadingStatus.scss';
import broken from 'assets/icons/broken.svg';

export default function LoadingStatus({ message = 'Loading', isError = false}) {

    return (
        <div className='loading'>
            <div className='loading__icon-container'>
                {isError ? 
                    <img 
                        className='loading__icon' 
                        src={broken} 
                        alt='Something went wrong' /> : 
                    <Spinner />
                }
            </div>
            <div className='loading__text'>{message}</div>
        </div>
    )
}

import config from '../config';

const handleLogout = () => {
    fetch(`${config.REACT_APP_BACKEND_URL}/api/auth/signout`, {
        credentials: 'include'
    })
    .then(res => {
        try {
            localStorage.removeItem('bimHubData');
            localStorage.removeItem('cachedProjectFilesData');
            console.log(`bimHubData cleared from localStorage`);
        } catch(error) {
            console.log(`bimHubData not deleted, probably didn't exist`);
        }
        window.location.href = '/loggedout';
        const newWindow = window.open('https://accounts.autodesk.com/Authentication/LogOut');
        newWindow.close();
        // setTimeout(() => newWindow.close(), 500);
    });
};

export default handleLogout;
// React modules
import React from 'react'
// app styles & assets
import './ModalLoading.scss'
import Spinner from '../Spinner/Spinner'

export default function ModalLoading({ show, message }) {
    const showHideClassName = show ? 'modal-loading modal-loading--display-block' : 'modal-loading modal-loading--display-none';
    return (
        <div className={ showHideClassName }>
            <div className='modal-loading__main'>
                <Spinner/>
                <div className='modal-loading__message'>{ message }</div>
            </div>
        </div>
    )
}

// react
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// store
import { selectPDFDocName, selectPDFDocURN, updatePDFLoading, updatePDFDocURN } from '../../../store/slices/selectSlice';
// external libraries
import WebViewer from '@pdftron/pdfjs-express-viewer';
// internal libraries
import { nthIndex } from '../../../utils/generalJS';
// styling
import './Viewer.scss';
import placeholder from '../../../assets/images/placeholder.svg';


const ViewerPDF = ({hasPDF}) => {

    const dispatch = useDispatch();

    /* -------------------------------------------------------------------------
    props, states and hooks
    ------------------------------------------------------------------------- */
    const PDFDocURN = useSelector(selectPDFDocURN);
    const PDFDocName = useSelector(selectPDFDocName);

    const viewer = useRef(null);
    const instance = useRef(null);
    
    useEffect(() => {
        // if viewer already initialized
        if (instance.current) {
            console.log(`PDF viewer already initialized`);
        } else {
            // else, initialize the viewer and assign to instance.current
            WebViewer(
                {
                  path: '/webviewer/lib',
                  licenseKey: `${process.env.REACT_APP_PDF_KEY}`,
                //   initialDoc: '/placeholder.pdf',
                },
                viewer.current,
              )
              .then((inst) => {
                    instance.current = inst;
                    // load only 1 page at a time
                    instance.current.setLayoutMode(instance.current.LayoutMode.Single);
              });
        }

        // cleanup function
        return () => {
            // viewer.current = null;
            // instance.current = null;
            dispatch(updatePDFDocURN(null));
        }
    }, []);

    // useEffect(() => {
    //     if (PDFDocURN !== null) {
    //         const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/forge/getOSID?id=${PDFDocURN}`;
    //         fetch(endpoint, { credentials: 'include'})
    //         .then(res => res.text())
    //         .then((storageObjectID) => extractFromStorageObjectID(storageObjectID))
    //         .then((keys) => {
    //             // fetch the access token (expires in 60 mins)
    //             fetch(`${process.env.REACT_APP_BACKEND_URL}/api/forge/getAccessToken`, {
    //                 credentials: 'include'
    //             })
    //             .then(res => res.json())
    //             .then(res => res.access_token)
    //             .then((token) => {
    //                 // sign the doc
    //                 fetch(`https://developer.api.autodesk.com/oss/v2/buckets/${keys.bucketKey}/objects/${keys.objectKey}/signeds3download`, {
    //                     method: 'GET',
    //                     headers: {
    //                         'Authorization': `Bearer ${token}`
    //                     },
    //                     credentials: 'include'
    //                 })
    //                 // convert to json
    //                 .then(res => res.json())
    //                 // get the URL property of the response
    //                 .then(res => res.url)
    //                 // fetch the PDF
    //                 .then(url => {
    //                     fetch(url)
    //                     .then(res => res.blob())
    //                     .then(blob => {
    //                         const url = URL.createObjectURL(blob);
    //                         // load the doc into the PDF viewer
    //                         if (instance.current) {
    //                             const { documentViewer } = instance.current.Core;
                                
    //                             instance.current.loadDocument(
    //                                 url, { filename: PDFDocName }
    //                             );
                                
    //                             // more info on customizing UI events (e.g. documentLoaded)
    //                             // pdfjs.express/documentation/ui-customization/customizing-setup
    //                             documentViewer.on('documentLoaded', () => {
    //                                 dispatch(updatePDFLoading(false));
    //                                 // load only 1 page at a time - doesn't work!
    //                                 instance.current.setLayoutMode(instance.current.LayoutMode.Single);
    //                             });
    //                         } else {
    //                             console.log('Error: PDF viewer not initialized');
    //                         }
    //                     })
    //                 })
    //             })
    //         })
    //     } else {

    //     }
    // }, [PDFDocURN, PDFDocName]);

    /* -------------------------------------------------------------------------
    rendering
    example PDF: https://pdftron.s3.amazonaws.com/downloads/pl/webviewer-demo.pdf
    ------------------------------------------------------------------------- */
    return (
        <div>
            
            <div className={`doc-placeholder ${(PDFDocURN && hasPDF) ? `doc-placeholder--hidden` : ``}`}>
                <img className='doc-placeholder__img' src={ placeholder } alt='PDF viewer' />
                <p className='doc-placeholder__text'>{PDFDocURN === null ? `No PDF document found` : `No PDF document found`}</p>
            </div>
            
            <div className='viewer-wrapper'>
                <div className='pdf-navbar'>
                    <div className='pdf-navbar__text'>{PDFDocName}</div>
                </div>
                <div className='viewer'>
                    <div id='viewerPDF' className='webviewer' ref={viewer}></div>
                </div>
            </div>
        </div>
    );
};

/* -----------------------------------------------------------------------------
helper functions
----------------------------------------------------------------------------- */
/**
     * extract bucket key and object key from storage object ID (SOI)
     * example SOI: urn:adsk.objects:os.object:wip.dm.prod/0b14cc757e42.pdf
     * pattern: <urn:adsk.objects:os.object>:<bucket_key>/<object_key>
     * @param {string} storageObjectID storage object ID
     * @returns {object} { bucketKey, objectKey }
     */
 function extractFromStorageObjectID(storageObjectID) {
    // index of '/' character
    let indexSlash = storageObjectID.indexOf('/');
    let objectKey = storageObjectID.substring(indexSlash + 1);
    // index of 3rd ':' character
    let index3rdcolon = nthIndex(storageObjectID, ':', 3);
    let bucketKey = storageObjectID.substring(index3rdcolon + 1, indexSlash)
    return { bucketKey, objectKey }
}

/* -----------------------------------------------------------------------------
exports
----------------------------------------------------------------------------- */
export default ViewerPDF;
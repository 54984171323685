// react
import React, { useState, useEffect } from 'react';
import { useTable, useGroupBy, useExpanded, useSortBy } from 'react-table';
// sub components
import Spinner from 'components/Spinner/Spinner';
// external libraries
import { v4 as uuidv4 } from 'uuid';
// styling
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import RemoveIcon from '@material-ui/icons/Remove';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import SearchBar from 'material-ui-search-bar';
import Button from '@material-ui/core/Button';


function AggregatedTable(props) {

	// data from sharepoint
	const aggregationStart = (props.toiAggStart && props.toiAggStart !== '') ? props.toiAggStart : 'TOI3';
	const aggStartLevel = parseInt(aggregationStart.substring(aggregationStart.indexOf('TOI') + 3));

    /* -------------------------------------------------------------------------
    states and props
    ------------------------------------------------------------------------- */
	const [fullData, setFullData] = useState(null);
	const [currentData, setCurrentData] = useState(null);

    const columns = React.useMemo(() => [
		// header group 1 - the TOI columns
		generateTOIColumns(aggStartLevel),
		// header group 2 - the quantity columns
		{
			Header: ' ',
			columns: [
				{
					Header: 'QTY1',
					accessor: 'TOM1',
					/*
					this function will receive both the leaf-row values and 
					(if the rows have already been aggregated, the previously 
					aggregated values) to be aggregated into a single value
					*/
					aggregate: sumWithUnits,
					Aggregated: ({ value }) => `${value}`
				},
				{
					Header: 'QTY2',
					accessor: 'TOM2',
					aggregate: sumWithUnits,
					Aggregated: ({ value }) => `${value}`
				}
			]
		}
    ], []);

	useEffect(() => {
		// categorical data from QuantityPanel
		const pre_data = props.data();
		// show loading spinner if data not ready
		if (!pre_data) return <Spinner></Spinner>;
		// generate full data
		const rawData = generateTableRows(pre_data);
		setFullData(TOISort(rawData));
		// initial data of currentData is full data
		setCurrentData(fullData);
    }, [props.data]);

    /* -------------------------------------------------------------------------
    utilities functions
    ------------------------------------------------------------------------- */
    /**
	 * flatten categoricalData and turn them into array of table rows
	 * @param {object} catData 
	 * @returns {array} array of table row objects
	 */
	const generateTableRows = (catData) => {
    
		const generateTOIData = (nestedObject, TOIChain) => {
			for (let key of Object.keys(nestedObject)) {
				// construct toi chain as we go down the hierarchy
				TOIChain.push(key);
				// array found: terminal point
				if (nestedObject[key] instanceof Array) {
					const summaryObject = nestedObject[key][0];
					// process summary object
					for (let label of Object.keys(summaryObject)) {
						if (label.toLowerCase().includes('tom')) {
							curRow[label] = summaryObject[label]
						}
					}
					// process toi chain
					const lastTOI = TOIChain.length;
					for (let i = 0; i < TOIChain.length; i++) {
						let toiLabel = `toi${i + 1}`;
	
						curRow[toiLabel] = TOIChain[i];
					}
					// add to data
					const completeRow = Object.assign({}, curRow);
					data.push(completeRow);
	
					// reset
					// go back 1 toi level
					TOIChain.pop();
					// remove the last toi and its toms to make space for next one
					for (let key of Object.keys(curRow)) {
						if (key.toLowerCase().includes('tom') || key.toLowerCase().includes(`toi${lastTOI}`)) {
							delete curRow[key];
						};
					}
	
				} 
				// not yet reach array, continue
				else {
					generateTOIData(nestedObject[key], TOIChain)
				}
			}
			// go back up one toi level
			TOIChain.pop();
		}
	
		const data = [];
		let curRow = {};
	
		// for each model of categorical data
		for (let model of Object.keys(catData)) {
			curRow['model'] = model;
			generateTOIData(catData[model], []);
			// reset after each row is pushed
			curRow = {};
		}
		return data;
	} 

	/**
	 * 
	 * @param {*} rows 
	 * @param {*} level 
	 * @returns 
	 */
    const TOISort = (rows, level = 1) => {
        
		try {
			rows.sort((a, b) => a['toi' + level].localeCompare(b['toi' + level]))
			// why this arbitrary level !== 4?
			if (level !== 4) {
				let partitions = [];
				for (let row of rows) {
					if (partitions.length > 0) {
					if (partitions[partitions.length - 1][0]['toi' + level] === row['toi' + level]) {
						partitions[partitions.length - 1].push(row)
					} else {
						partitions.push([row])
					}
					} else {
					partitions[0] = [row]
					}
				}
				for (let i = 0; i < partitions.length; i++) {
					partitions[i] = TOISort(partitions[i], level + 1)
				}
				return partitions.flat();
			} else {
				return rows;
			}
		} catch(error) {
			console.warn(`couldn't run TOISort, returning as is...`);
			console.error(error);
			return rows;
		}
    }

	/**
	 * 
	 * @param {string} userInput 
	 */
	const handleSearch = (userInput) => {
        if (userInput && userInput.length > 2) {
			const filtered = fullData.filter(item => {
				for (let value of Object.values(item)) {
					if (typeof value !== 'string') return false;
					if (value.toLowerCase().includes(userInput.toLowerCase())) return true;
				}
			});
			setCurrentData(filtered);
        } else {
			setCurrentData(fullData);
		}
    }

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    return (
        <React.Fragment>
        {/* search bar */}
        <SearchBar
            value=''
            onChange={(newValue) => handleSearch(newValue)}
            onRequestSearch={(newValue) => handleSearch(newValue)}
            onCancelSearch={_ => { handleSearch('') }}
        />
        <br />
        {/* main aggregate table data */}
        {(currentData && currentData.length > 0) ? 
            <Table columns={columns} data={currentData} aggStartLevel={aggStartLevel} /> : 
            <p style={{ textAlign: 'center' }}>No results</p>
        }
        <br />
        {/* export button */}
        <Button fullWidth variant='contained' color='primary' onClick={props.dataExportFunction}>
            <GetAppIcon style={{ fontSize: 16 }} />
            <span>&nbsp;</span>
            Export to Excel
        </Button>
        </React.Fragment>
    );
}

/* -----------------------------------------------------------------------------
internal components
----------------------------------------------------------------------------- */

/**
 * construct a React Table
 * docs: https://react-table-v7.tanstack.com
 * @param {array} columns array of columns in React Table's format
 * @param {array} data array of data in React Table's format
 * @returns the table element
 */
function Table({ columns, data, aggStartLevel }) {
    const [activeFilter, setActiveFilter] = useState('');
	const TOIArray = generateTOIArray(aggStartLevel);

    const { 
        getTableProps, // table props from react-table
		getTableBodyProps, // table body props from react-table
		headerGroups, // headerGroups, if your table has groupings
		rows, // rows for the table based on the data passed
        prepareRow, // needs to be called for each row before getting the row props
    } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: TOIArray,
				groupBy: TOIArray,
				aggStartLevel
			},
		},
		useGroupBy, 
		useSortBy, 
		useExpanded,
		(hooks) => {
			// hook to modify state
			hooks.useControlledState.push(useControlledState);
			// add an 'expander' column
			hooks.visibleColumns.push((columns, { instance }) => {
				if (!instance.state.groupBy.length) {
					return columns;
				}
				return [
					{
						id: 'expander',
						Cell: ({ row }) => {
							if (row.canExpand) {
							const groupedCell = row.allCells.find((d) => d.isGrouped);
							// render the last (i.e. deepest) TOI level
							if (groupedCell.column.Header === `TOI${aggStartLevel + 1}`) {
								return (
								<span
									{...row.getRowProps({
									style: {
										paddingLeft: `${row.depth * 1}rem`
									}
									})}
								>
									{groupedCell.render('Cell')}{' '}
								</span>
								);
							}
							// render other levels above
							return (
								<span
								{...row.getToggleRowExpandedProps({
									style: {
									paddingLeft: `${row.depth * 0.8}rem`
									}
								})}
								>
								{row.isExpanded ? 
									<ArrowDropUpIcon fontSize='inherit' /> : 
									<ArrowDropDownIcon fontSize='inherit' />
								} 
								{groupedCell.render('Cell')}{' '}
								</span>
							);
							}
			
							return null;
						}
					},
					...columns
				];
			});
		}
    );
  
    const conditionalColumnHeader = (column) => {
    	// expandable columns have special headers
		if (column.id !== 'expander') {
        return (
			<IconButton>
			{column.isSorted
				? column.isSortedDesc
				? <ArrowDropDownIcon />
				: <ArrowDropUpIcon />
				: <RemoveIcon />}
			</IconButton>
		)
		} else {
			// normal column header is 'CATEGORIES'
			return (
				<React.Fragment>CATEGORIES</React.Fragment>
			)
		}
    }
  
    return (
        <table {...getTableProps()}>
			<thead>
			{headerGroups.map((headerGroup) => {
				// custom rendering for the 'expander' column
				if (headerGroup.headers[0].id === 'expander') {
					return (
						<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th {...column.getHeaderProps(column.getSortByToggleProps())}>
							{column.render('Header')}
							{conditionalColumnHeader(column)}
							</th>
						))}
						</tr>
					)
				}
			})}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row) => {
					prepareRow(row);

					// console.log(`debug - row object is`);
					// console.log(row);

					//extract the rowName to be used on hover (using the HTML title property)
					let rowIDs = row.id.split('>');
					// row.id example: toi1:Architecture>toi2:Casework>toi3:Cabinets>toi4:Cabinets - Walk-In
					let lastRowID = rowIDs[rowIDs.length - 1];
					let rowName = lastRowID ? lastRowID.split(`toi${rowIDs.length}:`)[1] : '';
					
					let toiDepth = 0;
					let filterString = row.id.split('>').map((toi) => {
						toiDepth++;
						return toi.slice(5);
					}).join('@@@');
					if(toiDepth <= aggStartLevel) {
						filterString += '*'; 
					};

					return (
						<tr {...row.getRowProps()} 
							onClick={() => {
								let detail = { '*': false };
								detail[filterString] = true;
								setActiveFilter(filterString);
								document.dispatchEvent(new CustomEvent('toggleFilter', { detail }));
							}} 
							title={rowName} 
							style={{ background: activeFilter === filterString ? '#ed6724' : null }}>
							{row.cells.map((cell) => {
			
								// Don't show TOI1 and TOI2 data
								const cellId = cell.row.id;
								const TOIArray = generateTOIArray(aggStartLevel);
								if (notLastOne(cellId, TOIArray) && cell.column.id !== 'expander') {
									return <td key={uuidv4()}></td>
								}
			
								return (
									<td {...cell.getCellProps()} key={uuidv4()}>
									{
										// If the cell is aggregated, use the Aggregated renderer for cell
										cell.isAggregated ? cell.render('Aggregated')
										// For cells with repeated values, render null
										: cell.isPlaceholder ? null
										// Otherwise, just render the regular cell
										: cell.render('Cell')
									}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
        </table>
    );
}

/* -----------------------------------------------------------------------------
helper functions
----------------------------------------------------------------------------- */
/**
 * docs: https://react-table-v7.tanstack.com/docs/api/useTable#table-options
 * if you need to control part of the table state, this is the place to do it.
 * runs on every single render, just like hooks and allows you to alter the 
 * final state of the table if necessary.
 * you can use hooks inside of this function, but most of the time, we just 
 * suggest using React.useMemo to memoize your state overrides.
 * @param {object} state current state of table
 * @returns {object} modified state
 */
function useControlledState(state) {
	// if state doesn't change then just return the memoized value
	const TOIArray = generateTOIArray(parseInt(state.aggStartLevel) + 1);
 
	return React.useMemo(() => {
		if (state.groupBy.length) {
			return {
			...state,
			hiddenColumns: TOIArray,
			groupBy: TOIArray,
			};
		}
		return state;
	}, [state]);
}

/**
 * format a unit of measurement
 * @param {string} unit the raw string unit
 * @returns {string} formatted (shorten) unit of measurement
 */
const userFriendlyUnitFormat = (unit) => {
  let userFriendlyUnit = unit
  let unitsMap = {
    SQFT: 'SF',
    CF: 'CF'
  }

  if (unitsMap[unit]) {
    userFriendlyUnit = unitsMap[unit]
  }

  return userFriendlyUnit
}

/**
 * custom aggregation function to aggregate quantity
 * @param {array} leafValues array of values with unit (string format)
 * @returns {string} summed value with unit
 */
function sumWithUnits(leafValues) {
	let sum = 0;
	let unit = '';

	// extract the unit of measurement from the first item
	try {
		unit = leafValues[0].split('_')[1];
	} catch (err) {
		unit = '';
	}

	leafValues.forEach((value) => {
		if (value) {
		try {
			let split_value = value.split('_');
			sum += +(split_value[0]);
			if (unit === '') {
			unit = split_value[1]
			}
		} catch (err) {
			console.error('Error while processing leafValues in QuantityTable: ' + err)
		}
		}
	});

	if (unit === '') { return ' ' }
	return sum.toFixed(2) + ' ' + userFriendlyUnitFormat(unit);
}

const generateTOIColumns = (aggStartLevel) => {
	const TOIGroup = {
		'Header': ' ',
		'columns': [
		]
	}

	for (let i = 1; i <= aggStartLevel + 1; i++) {
		const TOIlabel = `TOI${i}`;
		const col = {}
		col['Header'] = TOIlabel;
		col['accessor'] = TOIlabel.toLowerCase();
		col['aggregate'] = 'count';
		TOIGroup['columns'].push(col);
	}
	return TOIGroup;
}

const generateTOIArray = (aggStartLevel) => {
	const TOIArray = [];

	for (let i = 1; i <= aggStartLevel; i++) {
		const TOIlabel = `toi${i}`;
		TOIArray.push(TOIlabel)
	}
	return TOIArray;
}

const notLastOne = (item, sourceToCheck) => {
	let inArray = false;
	// first, check if <item> is one of those preceding the last
	for (let i = 0; i < sourceToCheck.length - 1; i++) {
		if (item.includes(sourceToCheck[i])) {
			inArray = true;
			break;
		}
	};
	if (!inArray) return false;

	// next, check if it doesn't include the last one
	if (!item.includes(sourceToCheck.pop())) return true;
}
/* -----------------------------------------------------------------------------
export
----------------------------------------------------------------------------- */
export default AggregatedTable;
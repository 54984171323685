import React from 'react';
import { useParams } from 'react-router-dom';
// sub components
import Navbar from 'components/Navbar/Navbar';
// internal libraries
import { formatSpace } from 'utils/formatting';
// styling
import 'components/Dashboard/Dashboard.scss';

export default function Dashboard({ projectDisplayName, powerBIURLs }) {

    const { project } = useParams();
    const projectHasDisplayName = (
        projectDisplayName !== 'null' && projectDisplayName !== null && projectDisplayName !== undefined);
    const fullURL = powerBIURLs[project] ? powerBIURLs[project] : null;

    return (
    <div>
        <Navbar 
                crumbs={[
                    {
                        link: '/view',
                        text: 'HOME'
                    },
                    {
                        link: `/view/pre`,
                        text: `DESIGN`
                    },
                    {
                        link: `/view/pre/${project}`,
                        text: `${projectHasDisplayName ? formatSpace(projectDisplayName) : project}`
                    },
                    {
                        link: null,
                        text: `Dashboard`
                    },
                ]}
            />
        <iframe 
            title='Product Takeoff Dashboard' 
            className='dashboard-frame'
            src={fullURL} 
            allowFullScreen={true}>
        </iframe>
    </div>
  )
}

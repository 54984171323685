// model guid -> map (dbId -> props)
export const propsCache = {};

export default class ModelData {

    constructor(model, viewer) {
        this._model = model;
        this._viewer = viewer;
        this.elementsWithChildren = []
        this._modelData = {};
        // this.getAllChildDbIds = this.getAllChildDbIds.bind(this);
        // this.removeElements = this.removeElements.bind(this);
    }

    init(callback) {
        let _this = this;
        let model = this._model;

        _this._modelData['Family Name'] = {};
        _this.getAllLeafComponents(function (dbIds) {

            let count = dbIds.length;
            dbIds.forEach(function (dbId) {

                model.getProperties(dbId, function (props) {

                    const guid = model.getDocumentNode().guid();
                    if (!(guid in propsCache)) propsCache[guid] = {};
                    propsCache[guid][dbId] = props.properties;

                    props.properties.forEach(function (prop) {
                        if (
                            !(
                                prop.displayName.startsWith('TOI') ||
                                prop.displayName.startsWith('TOM') ||
                                prop.displayName === 'Workset' ||
                                prop.displayName === 'Category' ||
                                prop.displayName === 'Type Name' ||
                                prop.displayName === 'System Classification'
                            )
                        ) return;

                        if (!isNaN(prop.displayValue)) {
                            prop.displayValue = prop.displayValue.toString();
                            if (prop.displayValue === '') return;
                        }

                        // remove the "Revit " prefix
                        prop.displayValue = prop.displayValue.replace('Revit ', '');
                        // if (prop.displayValue.indexOf('<') === 0) return; // skip categories that start with <
                        if (prop.displayName === 'viewable_in') return;

                        // ok, now let's organize the data into this hash table
                        if (_this._modelData[prop.displayName] == null) _this._modelData[prop.displayName] = {};
                        if (_this._modelData[prop.displayName][prop.displayValue] == null) _this._modelData[prop.displayName][prop.displayValue] = [];
                        _this._modelData[prop.displayName][prop.displayValue].push(dbId);
                    });

                    model.getObjectTree(function (tree) {
                        // for revit models
                        let typeId = tree.getNodeParentId(props.dbId);
                        let familyId = tree.getNodeParentId(typeId);
                        let familyName = tree.getNodeName(familyId);

                        if (_this._modelData['Family Name'][familyName] == null) _this._modelData['Family Name'][familyName] = [];
                        _this._modelData['Family Name'][familyName].push(props.dbId);
                    });

                    if ((--count) === 0) callback();
                });
            })
        })
    }

    /**
     * get all leaf nodes (single (i.e. not nested) 3D objects) of a model, 
     * then pass that leaves array to the provided callback. exec the callback.
     * @param {function} callback the function to be called with leaves param
     */
     getAllLeafComponents(callback) {

        /**
         * remove elements in <elementsToRemove> from <original>; returns new array.
         * @param {array} original 
         * @param {array} elementsToRemove 
         * @returns new array with the specified elements removed.
         */
        let removeElements = function(original, elementsToRemove) {
            // Use filter to remove elements from original that are not in elementsToRemove
            return original.filter(function(element) {
                // Check if the element is not included in elementsToRemove
                return !elementsToRemove.includes(element);
            });
        }

        // https://learnforge.autodesk.io/#/viewer/extensions/panel?id=enumerate-leaf-nodes
        let model = this._model;
        let _elementsWithChildren = this.elementsWithChildren;
        model.getObjectTree(function (tree) {
            let leaves = [];
            let leavesToRemove = [];
            tree.enumNodeChildren(tree.getRootId(), function (dbId) {

                // DEBUG special exception
                // Oxford Village KTHA exceptions (parent elements): 12798
                // if ([12798].includes(dbId)) {
                //     console.log(`Note - manually adding a parent (${dbId}) to list of leaves...`);
                //     leaves.push(dbId);
                // }
                
                if (tree.getChildCount(dbId) === 0 && !leavesToRemove.includes(dbId)) {
                    leaves.push(dbId);
                }
                // handle case for parent nodes that need to be displayed
                else {
                    _elementsWithChildren.push(dbId);
                    model.getProperties(dbId, 
                        // on success callback
                        function (props) {
                        for (let i = 0; i < props.properties.length; i++) {
                            let prop = props.properties[i];
                            // this one has TOI1
                            if (prop.displayName.startsWith('TOI1') && prop.displayValue !== '') {
                                callback([dbId]);
                                // if a parent element is added this way, all 
                                // its child element should be hidden
                                // add all its children to array for removal
                                tree.enumNodeChildren(dbId, function(childDbId) {
                                    leavesToRemove.push(childDbId);
                                    console.log(`debug - pushing child element ${childDbId} of ${dbId} to leavesToRemove...`);
                                    leaves = removeElements(leaves, leavesToRemove);
                                }, true);
                                break;
                            }
                        }
                    }, 
                    // on error callback
                    function (error) {
                        alert(`error trying to fetch properties of ${dbId}: ${error}`)
                    })
                };
            }, true /* true means search child node recursively*/);
            // remove those in leavesToRemove from leaves first
            console.log(`debug - removing these from leaves: ${leavesToRemove}`);
            leaves = removeElements(leaves, leavesToRemove);
            // execute the callback (to add to filter tree)
            callback(leaves);
        });
    }


    hasProperty(propertyName) {
        return (this._modelData[propertyName] !== undefined);
    }

    getLabels(propertyName) {
        return Object.keys(this._modelData[propertyName]);
    }

    getCountInstances(propertyName) {
        return Object.keys(this._modelData[propertyName]).map(key => this._modelData[propertyName][key].length);
    }

    getIds(propertyName, propertyValue) {
        return this._modelData[propertyName][propertyValue];
    }
}
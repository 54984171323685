import { configureStore } from '@reduxjs/toolkit';

import selectReducer from './slices/selectSlice';
import cacheReducer from './slices/cacheSlice';

export default configureStore({
    reducer: {
        select: selectReducer,
        cache: cacheReducer,
    }
});

import { useState, useEffect, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

const InfoPanelElevation = ({ viewables3D, currViewSet, setCurrViewSet,
    setViewSets, handleSelect }) => {
    /* -------------------------------------------------------------------------    
    states and props
    props origin
    from <Viewing>: viewables3D, currElevation, setElevations, setCurrElevation
    from <InfoPanel>: handleSelect
    ------------------------------------------------------------------------- */
    const [initialViewSet, setInitialViewSet] = useState(null);
    const [viewSetOptions, setViewSetOptions] = useState([]);
    const [viewSetToViewableHashmap, setViewSetToViewableHashmap] = useState({});

    /* -------------------------------------------------------------------------
    hooks
    ------------------------------------------------------------------------- */
    useEffect(() => {
        // react note: don't update the state in a loop. have a temp container 
        // to hold values generated during the loop and update the state once 
        // later
        let _viewSetToViewableHashmap = {};
        let _viewSetOptions = [];

        for (const viewable of viewables3D) {
            // note: not all viewable.data will have a ViewSets property
            let viewSetName = viewable.data.ViewSets; // example: 'Design - 3D - CR'
            try {
                if (!viewSetName) {
                    throw new Error('Error: viewable.data.ViewSets is not truthy');
                } else {
                    // each view corresponds to exactly 1 viewable, unlike 
                    // in DesignOptions where each viewset has multiple viewables
                    _viewSetToViewableHashmap[viewSetName] = viewable;
                    // add this viewset to the list of available viewsets
                    _viewSetOptions.push(viewSetName);
                    // if an initial viewset is not chosen, choose it
                    if (!initialViewSet) {
                        setInitialViewSet(viewSetName);
                        setCurrViewSet(viewSetName);
                    }
                };
            } catch {
                console.log('Skipping this viewable...');
                continue;
            };
        };
        // update local state
        setViewSetToViewableHashmap(_viewSetToViewableHashmap);
        setViewSetOptions(_viewSetOptions);

        // after having gone through all viewables, update the parent
        setViewSets(viewSetOptions);
    }, [viewables3D])

    useEffect(() => {
        if (!currViewSet) return;
        handleSelect(viewSetToViewableHashmap[currViewSet]);
    }, [currViewSet])

    /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
    const buildViewSetsList = () => {
        // note: this function seems to be running more than once...
        const viewSetNames = [];
        const allViewsets = Object.keys(viewSetToViewableHashmap);
        allViewsets.sort();
        
        for (let viewSetName of allViewsets) {
            if (viewSetToViewableHashmap[viewSetName] !== null) {
                viewSetNames.push(
                    <Fragment key={uuidv4()}>
                    <div key={uuidv4()}>
                        <input 
                            onChange={() => setCurrViewSet(viewSetName) } 
                            type='radio' name='elevation' 
                            checked={viewSetName === currViewSet} />
                        <label>{formatViewSetName(viewSetName)}</label>
                    </div>
                    </Fragment>
                );
            }
        }
        return viewSetNames;
    }

    return (
        <div className='info-panel-elevation'>
            <h3>View Sets</h3>
            {buildViewSetsList()}
        </div>
    )
}

/* -----------------------------------------------------------------------------
utilities functions
----------------------------------------------------------------------------- */
const formatViewSetName = (rawViewSetName) => {
    try {
        const firstDashIndex = rawViewSetName.indexOf(' - ');
        return firstDashIndex ? 
            rawViewSetName.substring(firstDashIndex + 1).trim().replace('-', '') : 
            rawViewSetName;
    } catch {
        console.warn(`unable to parse  ${rawViewSetName}, will display in full`)
        return rawViewSetName
    }    
}

export default InfoPanelElevation;
// react
import React from 'react';
// styling
import 'components/common/BrandButton/BrandButton.scss';

/**
 * 
 * @param {string} text label of button
 * @param {function} actionFunction onclick function
 * @param {image} iconBefore the imported icon (usually svg format) before text
 * @param {image} iconAfter the imported icon (usually svg format) after text
 * @returns {component} BrandButton component
 */
export default function BrandButton({ text, actionFunction = null, 
    iconBefore = null, iconAfter = null }) {
    return (
        <button 
            className='brand-button' 
            onClick={actionFunction}
        >
            { iconBefore ? <img className='brand-button__icon' src={iconBefore} alt='icon prior' /> : ''}
            <div className='brand-button__text'>{text}</div>
            { iconAfter ? <img className='brand-button__icon' src={iconAfter} alt='icon after' /> : ''}
        </button>
    )
}

export const formatViewSetName = (rawViewSetName) => {
    try {
        const lastDashIndex = rawViewSetName.lastIndexOf(' - ');
        return lastDashIndex ? 
            rawViewSetName.substring(lastDashIndex + 1).trim().replace('-', '').replace(' ', '') : 
            rawViewSetName;
    } catch {
        console.warn(`unable to parse ${rawViewSetName}, will display in full`)
        return rawViewSetName
    }    
}

export const formatViewName = (viewName) => {
    try {
        const regex = /[/]+([A-Z]|[a-z]|[0-9])+[/]+/;
        const groupNameIndex = viewName.match(regex).index;
        const lastDashIndex = viewName.lastIndexOf(' - ');
        return lastDashIndex ? 
            viewName.substring(lastDashIndex + 1, groupNameIndex - 1).trim().replace('-', '') : 
            viewName;
    } catch {
        console.warn(`unable to parse  ${viewName}, will display in full`)
        return viewName
    }
}

/**
 * replace %20 with normal white space
 * @param {string} raw 
 * @returns {string} string where '%20' converted to normal space
 */
export const formatSpace = (raw) => {
    return (typeof raw === 'string' || raw instanceof String) ? raw.replaceAll('%20', ' ') : null;
}
/* -----------------------------------------------------------------------------
collection of general JS functions
----------------------------------------------------------------------------- */

/**
 * remove items in <main> that exist in <subset>
 * @param {array} main 
 * @param {array} subset 
 * @returns 
 */
export function removeCommonElems(main, subset) {
    const newMain = [...main];
    subset.map(elem => {
        var index = newMain.indexOf(elem);
        if (index !== -1) {
            newMain.splice(index, 1);
        };
    });
    return newMain;
}

/**
 * recursively look for array values in nested object. once found, run 
 * arrayFunc on that array
 * @param {*} nestedObject 
 * @param {*} arrayFunc 
 */
export function editArraysInNestedObject(nestedObject, arrayFunc) {
    
    if (!isObject(nestedObject)) {
        console.warn(`editArraysInNestedObject: passed nestedObject is not actually an object, terminating...`);
    }

    for (let key of Object.keys(nestedObject)) {
        // array found, will apply the supplied arrayFunc on that array
        if (nestedObject[key] instanceof Array) {
            nestedObject[key] = arrayFunc(nestedObject[key])
        } 
        // else continue to drill down till we find key whose value is array
        else {
            editArraysInNestedObject(nestedObject[key], arrayFunc)
        }
    }
}

/**
 * 
 * @param {*} unitA 
 * @param {*} unitB 
 * @param {*} separator 
 * @returns 
 */
export function sumWithUnit(unitA, unitB, separator='_') {
    const partsA = unitA.split(separator);
    const partsB = unitB.split(separator);
    const unit = partsA[1];
    return `${parseFloat(partsA[0]) + parseFloat(partsB[0])}${separator}${unit}`
}

/**
 * simple object check
 * @param item
 * @returns {boolean}
 */
const isObject = (item) => (item && typeof item === 'object' && !Array.isArray(item));

/**
  * /questions/27936772/how-to-deep-merge-instead-of-shallow-merge
  * @param {*} target 
  * @param  {...any} sources 
  * @returns 
  */
export function mergeDeep(target, ...sources) {
    
    // termination condition
    if (!sources.length) return target;
    
    // recursion logic
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            // if we encounter a nested object
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } 
            // not a nested object (terminal condition)
            else {
                // if source's key also exists in target
                if (target[key]) {
                    // if its value is iterable (e.g. array)
                    if (isIterable(target[key])) {
                        Object.assign(target, { [key]: [...target[key], ...source[key]] });
                    } else {
                        Object.assign(target, { [key]: [target[key], ...source[key]] });
                    }
                }
                // source's key not exist in target
                else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }
    }

    return mergeDeep(target, ...sources);
}

/**
 * Construct a nested object where each element in the hierarchyChain array is 
 * a step down the nested structure. The final element in that array (also the 
 * deepest level of the hierachy) will have the finalObject as its value
 * @param {Array} hierarchyChain 
 * @param {Object} finalObject 
 * @returns {Object} the nested object
 */
export function buildNestedObject(hierarchyChain, finalObject) {
    let nestedObject = finalObject;
    for (let i = hierarchyChain.length - 1; i >= 0; i--) {
        const currentLabel = hierarchyChain[i];
        const tempObject = {};
        tempObject[currentLabel] = nestedObject;
        nestedObject = tempObject;
    }
    return nestedObject;
}

/**
 * return the index of the nth occurence of a character in a string
 * from SO/questions/14480345/how-to-get-the-nth-occurrence-in-a-string
 * @param {string} str the main string 
 * @param {string} pat the substring we need to find nth occurence of
 * @param {integer} n the nth occurence
 * @returns 
 */
export function nthIndex(str, pat, n) {
    var L = str.length, i= -1;
    while(n-- && i++<L) {
        i = str.indexOf(pat, i);
        if (i < 0) break;
    }
    return i;
};

/**
 * add a value to the hashmap if key exists; create new key if not
 * hashmap form: { key: [array of values] }
 * @param {string} key key of hashmap
 * @param {string} value value of hashmap
 * @param {object} hashmap the hashmap itself
 */
export function addArrayToHashmap(key, value, hashmap) {
    if (key in hashmap) hashmap[key].push(value)
    else hashmap[key] = [value]
};

/**
 * check if a string can be converted to number
 * SO/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
 * @param {string} str string representation of a possible number
 * @returns boolean if the str can be converted to number
 */
export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    // use type coercion to parse the _entirety_ of the string 
    // (`parseFloat` alone does not do this)...
    return !isNaN(str) && 
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

/**
 * check if an object is iterable
 * questions/18884249/checking-whether-something-is-iterable
 * @param {*} obj 
 * @returns {boolean} whether the object is iterable
 */
function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

/**
 * polyfill of Object.keys(myObject)
 * @param {obj} obj object to get all keys of
 * @returns 
 */
export function getKeys(obj) {
    var keys = [];
    for(var key in obj) {
        keys.push(key);
    }
    return keys;
}

/**
 * convert the number of minutes to a human-readable time-relative text
 * @param {integer} minutes 
 * @param {string} suffix 
 * @returns {string} formatted time text  
 */
 export function prettyPrintMinutes(minutes, suffix = 'ago') {
    
    let mins = null;
    let hours = null;
    let days = null;
    let weeks = null;
    let months = null;

    // within an hour
    if (minutes < 60) return `${minutes} minute${minutes === 1 ? '' : 's'} ${suffix}`
    // less than a day
    else if (minutes < 60*24) {
        hours = Math.floor(minutes / 60);
        mins = minutes % 60;
        let minutePart = ``;
        if (mins === 1) {
            minutePart = `, 1 minute`;
        } else if (mins > 1) {
            minutePart = `, ${mins} minutes`;
        }
        return `${hours} hour${hours === 1 ? '' : 's'}${minutePart} ${suffix}`
    }
    // less than a week
    else if (minutes < 60*24*7) {
        days = Math.round(minutes / (60*24));
        return `around ${days} day${days === 1 ? '' : 's'} ${suffix}`;
    }
    // less than a month (30 days)
    else if (minutes < 60*24*7*30) {
        weeks = Math.round(minutes / (60*24*7));
        return `around ${weeks} week${weeks === 1 ? '' : 's'} ${suffix}`;
    }
    // more than 1 month
    else {
        months = Math.round(minutes / (60*24*7*30));
        return `around ${months} month${months === 1 ? '' : 's'} ${suffix}`;
    }
}
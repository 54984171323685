// react
import React from 'react';
// styling
import 'pages/SandBox/SandBox.scss';
// test components
import LoadingStatus from 'components/LoadingStatus/LoadingStatus';

/**
 * Testing ground for components
 */
export default function SandBox() {
  return (
    <div className='sandbox'>
        <h1 className='sandbox__header'>Testing ground</h1>
        <div className='sandbox__area'>
            <LoadingStatus
                message='Loading stuff'
            />
        </div>
    </div>
  )
}

/**
 * handles project selection (Design or Construction).
 * upon selection, redirect to model selection.
 */
// react
import { Link, Redirect, useParams, useRouteMatch } from 'react-router-dom';
// external libraries
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Button, TableCell, TableRow } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
// internal libraries
import Navbar from '../Navbar/Navbar';
// env vars
import config from 'config';
// styling
import 'components/Selection/SelectProject.scss';

/* -----------------------------------------------------------------------------
helper functions
----------------------------------------------------------------------------- */

// fix for old mui-table version
const oldRender = TableCell.render
TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

// remove cache and reload
const clearCacheReload = () => {
    // clear local storage
    localStorage.removeItem('bimHubData');
    localStorage.removeItem('cachedProjectFilesData');
    console.log(`bimHubData cleared from localStorage`);
    // clear server cache
    fetch(`${config.REACT_APP_BACKEND_URL}/api/auth/flushdb`, {
        credentials: 'include'
    })
    .then(_res => {
        window.location.reload();
    })
    .catch((error) => {
        console.log(`Unable to flush server DB for some reasons:`);
        console.log(error);
    });
};

/* -----------------------------------------------------------------------------
main component
----------------------------------------------------------------------------- */
const SelectProject = ({ projectsData, mobileView }) => {

    let { type } = useParams();
    const { url } = useRouteMatch();
    let category = type.toLowerCase();
    let designOrConProjects = null;
    
    // depends on category (design or construction), fetch the right project list
    if (category === 'pre' || category === 'con') {
        designOrConProjects = projectsData[category];
    }
    // URL is neither 'pre' nor 'con', redirect
    else {
        let redirUrl = url.split('/').slice(0, -1).join('/');
        return <Redirect to={redirUrl} />;
    }

    const [tableColumns, tableData, tableOptions] = configureTable(
        designOrConProjects, url, mobileView);

    return (
        <div className='page centered-column'>
            <Navbar 
                crumbs={[
                    {
                        link: '/view',
                        text: 'HOME'
                    },
                    {
                        link: null,
                        text: `${category === 'pre' ? 'DESIGN' : 'CONSTRUCTION'}`
                    }
                ]}
            />
            <div className='page-loose centered-row table-wrapper'>
                <MUIDataTable
                    title={'Projects'}
                    data={tableData}
                    columns={tableColumns[0]}
                    options={tableOptions}
                />
                <p className='bottom-message'>
                    Data will be cached for 30 minutes. You can also &nbsp;
                        <span 
                            onClick={clearCacheReload}
                            className='bottom-message__link'
                        >
                            force reload
                        </span>
                </p>
            </div>
        </div>
    );
};

/* -----------------------------------------------------------------------------
helper functions
----------------------------------------------------------------------------- */
const configureTable = (designOrConProjects, url, mobileView) => {
    /*
    column set 1 (tableColumns[0]) is human readable column names;
    column set 2 (tableColumns[1]) is keys in the designOrConProjects object;
    to control what info to grab from each of the designOrConProjects, change 
    in column set 2.
    */ 
    var tableColumns = mobileView ? [
        [
            'Name',
            // 'Address',
            // 'City'
        ],
        [
            'projectDisplayName',
            // 'address_line_1',
            // 'city'
        ]
    ] : [
        [
            'Name',
            // 'Address',
            // 'Postal Code',
            // 'City',
            // 'Country'
        ],
        [
            'projectDisplayName',
            // 'address_line_1',
            // 'postal_code',
            // 'city',
            // 'country'
        ]
    ];

    var tableData = [];

    for (let obj of designOrConProjects) {

        let row = [];
        // for...in returns the indices; for...of returns the values
        for (let key of tableColumns[1]) {
            let cellContent;
            if (key === 'projectDisplayName') {
                // get both the display name and the original project name
                cellContent = `${obj[key]}@@@${obj['name']}`
            } else {
                cellContent = obj[key];
            }
            row.push(cellContent);
        }
        tableData.push(row);
    }
    // sort data alphabetically by first column (project name)
    tableData.sort((a, b) => a[0].localeCompare(b[0]));

    const tableOptions = {
        download: false,
        print: false,
        selectableRows: false,
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 50, 100],
        tableBodyHeight: '100%',
        tableBodyMaxHeight: '600px',
        customRowRender: (rowData) => {
            return makeCustomTableCell(rowData, url);
        }
    };

    return [tableColumns, tableData, tableOptions];
};

const makeCustomTableCell = (rowData, url) => {
    // constructing the cells and add to the row
    const tableCells = rowData.map((cellContent, i) => {
        if (i === 0) {
            let bothNames = cellContent.split('@@@');
            let projectDisplayName = bothNames[0];
            let projectTechnicalName = bothNames[1];
            return (
                // highlight the first cell of each row i.e. project name
                <TableCell key={uuidv4()}>
                    <Link to={`${url}/${projectTechnicalName}?project_display_name=${projectDisplayName}`} style={{ textDecoration: "none" }}>
                        <Button color="primary" variant="contained" style={{ width: "100%" }}>
                            {projectDisplayName}
                        </Button>
                    </Link>
                </TableCell>
            );
        }
        return (
            <TableCell key={uuidv4()}>
                {cellContent}
            </TableCell>
        );
    });
    // return the row
    return (
        <TableRow key={uuidv4()}>
            {tableCells}
        </TableRow>
    );
};

SelectProject.propTypes = {
    projectsData: PropTypes.object.isRequired,
}

export default SelectProject;